/**
 * クラス名：親部品見積
 * 説明：親部品タブ纏めJSファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import React, { useImperativeHandle, forwardRef, useEffect, useRef, useState } from 'react';
import { Form, Tabs, Tooltip, Spin } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';

import ParentEstimateDetail from './ParentEstimateDetail';
import ParentKouteiCalculate from './ParentKouteiCalculate';
import ParentKouteiInput from './ParentKouteiInput';
import ParentKouteiSentaku from './ParentKouteiSentaku';
import ParentPurchase from './ParentPurchase';
import KouteiSuryouBetsuBtn from './KouteiSuryouBetsuBtn';
import { confirmSaveContent, confirmModalTitle, commonModal, commonFooter } from '../common/CommonModal';
import { WorkType } from '../common/enums.js';
import { compareParentInfo, updateEstimateInfoByReCal } from '../common/Common';
import { parentPurchaseTitle } from '../common/Constant.js';

const ParentEstimate = forwardRef((props, ref) => {
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [purchasesData, setPurchasesData] = useState([]);
  const [discardModeKouteiCal, setDiscardModeKouteiCal] = useState(false);
  const [createConfirm, setCreateConfirm] = useState(false);

  // 基本情報
  let parentEstimateDetailRef = useRef();

  // 工程選択
  let parentKouteiSentakuRef = useRef();

  // 工程計算
  let parentKouteiCalRef = useRef();

  // 工程入力
  let parentKouteiInputRef = useRef();

  // 購入品
  let parentPurchaseRef = useRef();

  //数量別
  let byQuantitiesRef = useRef();

  useImperativeHandle(ref, () => ({
    getParentEstimateDetailRef: () => {
      return parentEstimateDetailRef;
    },
    getParentEstimateInfo: getParentEstimateInfo,
    discardConfirmOk: discardConfirmOk,
    kihonFormValidation: parentEstimateDetailRef?.current?.formValidation,
    getParentQuantity: parentEstimateDetailRef?.current?.getParentQuantity, // 入力した数量情報を得る
  }));

  // タブ切替イベント
  const onChange = (key) => {
    setActiveTabKey(key);
    props?.updateParentActiveTabKey(key);
    props?.updateCreateNewEvent(false);
    // 親部品情報に変更があった場合、再計算ボタンを赤色にする。
    let differences = false;
    let beforeParentTabObj = props.selectedEstimateDataBeforeParentTab;
    let afterParentTabObj = props.selectedEstimateData;
    if (props?.parentInfoChangeFlg !== undefined && !props?.parentInfoChangeFlg) {
      differences = compareParentInfo(beforeParentTabObj, afterParentTabObj);
      if (differences) {
        props?.updateParentInfoChange(true);
      } else {
        props?.updateParentInfoChange(false);
      }
    }
  };

  // useEffect(() => {
  //   if (selectedTabKey) {
  //     // let estimateInfo = getParentEstimateInfo();
  //     // let calEstimateInfo = updateEstimateInfoByReCal(estimateInfo, props?.isEstimateOpen); // IQX_WEBEST-305 過去データを開いた直後、再計算をしない限りはタブ切替しても計算しないでほしい。
  //     let calEstimateInfo = getParentEstimateInfo();
  //     props.updateEstimateInfo(calEstimateInfo);
  //     props.selectedEstimateData.estimateProducts = calEstimateInfo.estimateProducts;
  //     props.selectedEstimateData.esIq3Info = calEstimateInfo.esIq3Info;
  //     setSelectedTabKey(false);
  //   }
  // }, [activeTabKey]);

  // useEffect(() => {
  //   if (props.clickMenu !== '') {
  //     let estimateInfo = getParentEstimateInfo();
  //     let calEstimateInfo = updateEstimateInfoByReCal(estimateInfo, props?.isEstimateOpen); // IQX_WEBEST-305 過去データを開いた直後、再計算をしない限りはタブ切替しても計算しないでほしい。
  //     props.updateEstimateInfo(calEstimateInfo);
  //     props.selectedEstimateData.estimateProducts = calEstimateInfo.estimateProducts;
  //     props.selectedEstimateData.esIq3Info = calEstimateInfo.esIq3Info;
  //     props.updateClickMenu();
  //   }
  //   setSelectedTabKey(false);
  // }, [props.activePartMenu, props.clickMenu]);

  // useEffect(() => {
  //   if (props?.parentActiveTabKey) {
  //     setActiveTabKey(props?.parentActiveTabKey);
  //   }
  // }, [props?.parentActiveTabKey]);

  // useEffect(() => {
  //   if (
  //     props?.selectedEstimateData?.esIq3Info &&
  //     props?.selectedEstimateData?.esIq3Info?.length > 0 &&
  //     !props?.createNewEvent // 新規の初期状態のみに計算しないように条件を追加
  //   ) {
  //     let estimateInfo = getParentEstimateInfo();
  //     let calEstimateInfo = updateEstimateInfoByReCal(estimateInfo, props?.isEstimateOpen); // IQX_WEBEST-305 過去データを開いた直後、再計算をしない限りはタブ切替しても計算しないでほしい。
  //     props.updateEstimateInfo(calEstimateInfo);
  //     props.selectedEstimateData.estimateProducts = calEstimateInfo.estimateProducts;
  //     props.selectedEstimateData.esIq3Info = calEstimateInfo.esIq3Info;
  //     props.updateClickMenu();
  //   }
  // }, [props?.selectedEstimateData?.esIq3Info]);

  const discardConfirmOk = () => {
    setDiscardModeKouteiCal(false);
    props.updateMainEstimateEditMode(false);
  };

  const getParentEstimateInfo = () => {
    let estimateData = props.selectedEstimateData;

    //工程パターンでチェック外した工程を工程積算から削除
    let orgAdditions = estimateData?.estimateProducts?.additions;
    let updatedAdditions = [];
    for (let i = 0; i < estimateData?.estimateProducts?.processSelect?.details?.length; i++) {
      let targetAddition = orgAdditions?.filter(
        (item) =>
          item.processId === estimateData?.estimateProducts?.processSelect?.details[i].processId &&
          estimateData?.estimateProducts?.processSelect?.details[i].isUsedForCalc
      );

      if (targetAddition?.length > 0) {
        //複数処理（追加工程）の場合、工程IDで判断できないため、工程詳細IDで判断して対象データを取得
        if (targetAddition?.length > 1) {
          for (let o = 0; o < orgAdditions?.length; o++) {
            let customProc = estimateData?.estimateProducts?.calcParameters?.parameters?.process?.filter(
              (item) => item.id === orgAdditions[o]?.processId
            );
            let customType = customProc ? customProc[0]?.workType : 0;
            if (
              estimateData?.estimateProducts?.processSelect?.details[i]?.multiprocesses?.[0]?.processDetailTypeId ===
                orgAdditions[o]?.dataItems?.[0]?.processDetailTypeNo &&
              estimateData?.estimateProducts?.processSelect?.details[i]?.processId === orgAdditions[o]?.processId &&
              customType === WorkType.PaCustom
            ) {
              updatedAdditions?.push(orgAdditions?.[o]);
              break;
            }
          }
        } else {
          updatedAdditions?.push(targetAddition?.[0]);
        }
      }
    }
    if (estimateData?.estimateProducts?.processSelect?.details?.length > 0) {
      //工程選択から外した場合、見積データに外した情報を更新するため、updatedAdditionsのNullチェックを削除
      props.selectedEstimateData.estimateProducts.additions = updatedAdditions;
    }
    return estimateData;
  };

  const createCancel = () => {
    setCreateConfirm(false);
  };

  const updateEstimateInfo = (updateEstimateData) => {
    props.updateEstimateInfo(updateEstimateData);
  };

  const onChangePurchase = (purchases) => {
    setPurchasesData(purchases);
  };

  const onChangeQuantity = (flg) => {
    props?.updateParentQuantityChange(flg);
  };

  const tabItems = [
    {
      key: '1',
      label: `基本情報`,
      children: '',
    },

    {
      key: '2',
      label: `工程選択`,
      children: '',
    },
    {
      key: '3',
      label: `工程入力`,
      children: '',
    },
    {
      key: '4',
      label: `工程積算`,
      children: '',
    },
    {
      key: '5',
      label: (
        <Tooltip title={parentPurchaseTitle} placement="top" overlayClassName="tooltip-text purchaseTooltip">
          購入品
        </Tooltip>
      ),
      children: '',
    },
    // {
    //   key: '6',
    //   label: `添付ファイル`,
    //   children: (
    //     <div style={{ marginTop: '-15px', marginLeft: '-34px', width: '100%' }}>
    //       <Row>
    //         <Col style={{ width: '90%' }}>{tempFileComponent}</Col>
    //         <Col style={{ width: '10%', marginTop: '20px' }}>
    //           <Button key="tempFileAdd" type="primary" className="editAllButton" style={{ marginLeft: 0, width: 120 }}>
    //             添付ファイルを追加
    //           </Button>
    //         </Col>
    //       </Row>
    //     </div>
    //   ),
    // },
    // {
    //   key: '7',
    //   label: `フリーボード`,
    //   children: (
    //     <div style={{ marginTop: '5px', marginLeft: '0px', width: '100%' }}>{selectedEstimateData?.comment}</div>
    //   ),
    // },
  ];

  return (
    <>
      <Spin spinning={props?.activePartMenu === '1' ? false : true} tip="" size="large">
        {/* {props?.activePartMenu === '1' ? (//見積保存の時、板金の基本情報をRefをアクセスできないため、この条件を解除して子JSごとに条件をいれる。 */}
        <>
          <Form>
            {/* 部品プロパティアリア */}
            <div
              style={{
                // display: 'flex',
                height: '85.9vh',
                marginLeft: '6px',
                marginRight: '6px',
              }}
            >
              <Tabs
                className="iq3-detail-tab"
                type="card"
                defaultActiveKey="1"
                activeKey={activeTabKey}
                items={tabItems}
                onChange={onChange}
              />
              <div style={{ display: activeTabKey == '1' ? 'block' : 'none', marginTop: 7 }}>
                <ParentEstimateDetail
                  ref={parentEstimateDetailRef}
                  selectedDataDetail={props.selectedEstimateData}
                  editMode={true}
                  activeTabKey={activeTabKey}
                  activePartMenu={props.activePartMenu}
                  isReCal={props.isReCal}
                  newMode={props.newMode}
                  updateEstimateInfo={updateEstimateInfo}
                  parentModified={props.selectedEstimateData?.estimateProducts?.modified}
                  parentEstimateProductsId={props.selectedEstimateData?.estimateProducts?.id}
                  onChangeQuantity={onChangeQuantity}
                />
              </div>
              <div style={{ display: activeTabKey == '2' ? 'block' : 'none', marginTop: 7 }}>
                <ParentKouteiSentaku
                  type={'list'}
                  ref={parentKouteiSentakuRef}
                  editMode={true}
                  selectedDataDetail={props.selectedEstimateData}
                  activeTabKey={activeTabKey}
                  isReCal={props.isReCal}
                  newMode={props.newMode}
                  updateEstimateInfo={updateEstimateInfo}
                />
              </div>
              <div style={{ display: activeTabKey == '3' ? 'block' : 'none', marginTop: 7 }}>
                <ParentKouteiInput
                  ref={parentKouteiInputRef}
                  selectedDataDetail={props.selectedEstimateData}
                  editMode={true}
                  activeTabKey={activeTabKey}
                  isReCal={props.isReCal}
                  newMode={props.newMode}
                  updateEstimateInfo={updateEstimateInfo}
                />
              </div>
              <div style={{ display: activeTabKey == '4' ? 'block' : 'none', marginTop: 7 }}>
                <ParentKouteiCalculate
                  ref={parentKouteiCalRef}
                  selectedDataDetail={props.selectedEstimateData}
                  editMode={true}
                  pmxMode={false}
                  discardModeKouteiCal={discardModeKouteiCal}
                  isReCal={props.isReCal}
                  activeTabKey={activeTabKey}
                  newMode={props.newMode}
                  updateEstimateInfo={updateEstimateInfo}
                  purchasesData={purchasesData}
                  activePartMenu={props.activePartMenu}
                />
              </div>
              <div style={{ display: activeTabKey == '5' ? 'block' : 'none', marginTop: 7 }}>
                <ParentPurchase
                  ref={parentPurchaseRef}
                  selectedDataDetail={props.selectedEstimateData}
                  editMode={true}
                  isChildPart={false}
                  activeTabKey={activeTabKey}
                  newMode={props.newMode}
                  updateEstimateInfo={updateEstimateInfo}
                  onChangePurchase={onChangePurchase}
                  activePartMenu={props.activePartMenu}
                />
              </div>
            </div>
            {/** 数量別見積確認 */}
            <div style={{ marginLeft: '-620px', display: 'flex' }}>
              <div
                style={{
                  display: 'flex',
                  marginTop: '-7px',
                  position: 'absolute',
                  top: '-36px',
                  right: 0,
                }}
              >
                <KouteiSuryouBetsuBtn
                  ref={byQuantitiesRef}
                  selectedEstimateData={props.selectedEstimateData}
                  getParentQuantity={props?.getParentQuantity}
                  isReCal={props.isReCal}
                  loading={props?.loading}
                  isPMXEvent={props?.isPMXEvent}
                />
              </div>
            </div>
          </Form>
          {/* 登録確認 */}
          {createConfirm
            ? commonModal(
                createConfirm,
                confirmModalTitle,
                commonFooter(createOk, createCancel),
                null,
                400,
                createOk,
                createCancel,
                confirmSaveContent,
                null,
                null,
                null,
                false
              )
            : ''}
        </>
        {/* ) : (
          ''
        )} */}
      </Spin>
    </>
  );
});

export default ParentEstimate;
