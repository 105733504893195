/**
 * クラス名：システム全体の共通ファイル
 * 説明：システム全体に共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import { useEffect, useState, Input, Row, Table } from 'react';
import dayjs from 'dayjs';
import { Button, Input as antdInput, Checkbox } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { StatusCodes } from 'http-status-codes';
import { Resizable } from 'react-resizable';
import * as XLSX from 'xlsx';

import {
  amtEmptyStr,
  processInputKensaData,
  processInputKonpouData,
  processInputKumitateData,
  timeHmsStr,
} from '../common/Constant';
import '../../assets/styles/common.css';
import { commonModal, error, sameDrawingNoContent } from '../common/CommonModal';
import { ErrorMessage } from '../common/Message';
import {
  ClientType,
  MaterialCategory,
  MaterialSurfaceBillingUnit,
  WorkType,
  ServiceClass,
  PrepCoeffType,
  ProcessDetailGroup,
  ProcessType,
  EstimateCreationType,
  DataType,
  PatternType,
  WorkTypeGroup,
} from './enums';
import { element } from 'prop-types';
import {
  getMaterialTypeIQ3Info,
  getMaterialTypeInfo,
  getClientInfo,
  getCompanyInfo,
  getStaffInfo,
  getS3FileContent,
  getEstimateStatusData,
} from './CommonAPI';

export const secondsToHms = (totalIncSec) => {
  if (totalIncSec > 0) {
    var h = Math.floor(totalIncSec / 3600).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    var m = Math.floor((totalIncSec % 3600) / 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    var s = Math.round((totalIncSec % 3600) % 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    return h + ':' + m + ':' + s;
  } else {
    return '00:00:00';
  }
};

export const setTimetoSec = (timeStr) => {
  if (timeStr == undefined || timeStr == '') return 0;
  var splitTime = timeStr.split(':');
  var seconds = +splitTime[0] * 60 * 60 + +splitTime[1] * 60 + +splitTime[2];
  return seconds;
};

export const handelDecreaseTime = (time, timeType, idLbl) => {
  let totalSec = time;
  let totalIncSec;
  if (timeType == '10min' && totalSec >= 600) {
    totalIncSec = totalSec - 600;
  } else if (timeType == '10min' && totalSec < 600) {
    totalIncSec = totalSec;
  } else if (timeType == 'min' && totalSec >= 60) {
    totalIncSec = totalSec - 60;
  } /* else if (timeType == 'min' && totalSec < 60) {
    totalIncSec = totalSec;
  }  */ else if (timeType == 'sec') {
    totalIncSec = totalSec - 10;
  }
  // let timeStr = secondsToHms(totalIncSec);
  // checkInput(idLbl, timeStr);
  totalIncSec = totalIncSec <= 0 ? 0 : totalIncSec;
  return totalIncSec;
};

export const handelIncreaseTime = (time, timeType, idLbl) => {
  let totalSec = time;
  let totalIncSec;
  if (timeType == '10min') {
    totalIncSec = totalSec + 600;
  } else if (timeType == 'min') {
    totalIncSec = totalSec + 60;
  } else if (timeType == 'sec') {
    totalIncSec = totalSec + 10;
  }
  return totalIncSec;
};

export const checkInput = (id, input) => {
  let originalText = document.getElementById(id).textContent;
  var regexp = /^¥\d+(?:\.\d+)?$/;

  if (originalText !== input) {
    document.getElementById(id + 'In').style.color = 'red';
  } else {
    document.getElementById(id + 'In').style.color = '#000000';
  }
};

function getTextBeforeAmount(inputString) {
  const regex = /(.*?)(?:Amount|Amt)/i;
  const matches = inputString.match(regex);
  if (matches && matches?.length > 1) {
    return matches[1];
  }
  return '';
}

export const handleShowHide = (val1, val2, showHideId) => {
  if (val1 === val2 && document.getElementById(showHideId) !== null) {
    document.getElementById(showHideId).style.display = 'none';
  }
  return 'black';
};

export const checkInputWithColor = (kumitateflg, idI, id, input, color) => {
  let originalText = document.getElementById(id).textContent;
  let originalTextNumber = 0;
  if (input === undefined) {
    input = 0;
  }
  if (typeof input === 'string' && !input.startsWith(':', 2)) {
    if (input.startsWith('¥', 0)) {
      input = input.replace('¥', '');
    }
    if (input.indexOf(',') > 0) {
      input = input.replace(',', '');
    }
    input = Number(input);
  }
  if (originalText.startsWith('¥', 0)) {
    originalText = originalText.replace('¥', '');
    if (originalText.indexOf(',') > 0) {
      originalText = originalText.replace(',', '');
    }
    originalTextNumber = Number(originalText);
  } else if (originalText.startsWith(':', 2)) {
    originalTextNumber = originalText;
  } else {
    originalTextNumber = Number(originalText);
  }
  var regexp = /^¥\d+(?:\.\d+)?$/;
  if (originalTextNumber != input && color === 'red') {
    document.getElementById(id + 'In').style.color = '#F4031E';
  } else if (originalTextNumber != input && color === 'green') {
    document.getElementById(id + 'In').style.color = '#4cc12f';
  } else if (originalTextNumber != input && color === 'amt') {
    document.getElementById(id + 'In').style.color = '#F4031E';
    let siblingEle = document.getElementById(id + 'In').nextSibling;
    if (siblingEle !== null) {
      //  siblingEle.firstChild.classList?.remove('reset');
      document.getElementById(id + 'Cu').style.display = 'block';
    }
  } else if (originalText != input && color === 'time') {
    document.getElementById(id + 'In').style.color = '#4cc12f';
  } else {
    let idColor = 'black';
    document.getElementById(id + 'In').style.color = idColor;
    let beforeAmt = getTextBeforeAmount(id);
    if (beforeAmt !== '') {
      let timeId = beforeAmt + 'TimeIn';
      if (kumitateflg) {
        let indexAmt = id.indexOf('Amt');
        timeId = indexAmt !== -1 && id.replace('Amt', 'Time') + id[0] + 'In';
        timeId = timeId.replace(id[0], idI);
      }
      if (
        idColor === 'black' &&
        (document.getElementById(timeId).style.color === 'black' || document.getElementById(timeId).style.color === '')
      ) {
        if (document.getElementById(id + 'Cu') !== null) {
          document.getElementById(id + 'Cu').style.display = 'none';
        }
      }
    }
  }
};

export const checkInputDDL = (id, input, color) => {
  let originalText = document.getElementById(id).textContent;
  if (originalText === undefined) {
    originalText = '';
  }
  if (input === undefined) {
    input = '';
  }
  let parent = document.getElementById(id + 'In').parentElement;
  if (originalText != input && color === 'red') {
    parent.nextSibling.style.color = '#F4031E';
    document.getElementById(id + 'In').style.color = '#F4031E';
  } else {
    parent.nextSibling.style.color = '#000000';
    document.getElementById(id + 'In').style.color = '#F4031E';
  }
};

export const checkDDLLbl = (id, input) => {
  let originalText;
  if (document.getElementById(id) != null) {
    originalText = document.getElementById(id).innerText;
  }

  if (originalText === undefined) {
    originalText = '';
  }
  if (input === undefined) {
    input = '';
  }
  let test;
  if (originalText != input) {
    test = true;
  } else {
    test = false;
  }
  return test;
};
export const totalEditData = (arr) => {
  var sum = 0;
  for (var i = 0; i < arr?.length; i++) {
    sum += isNaN(parseFloat(arr[i])) ? 0 : parseFloat(arr[i]);
  }
  return sum;
};

export const formatCurrency = (input, blur) => {
  // appends $ to value, validates decimal side
  // and puts cursor back in right position.

  // get input value
  var input_val = input;

  // don't validate empty input
  if (input_val === '') {
    return;
  }

  // original length
  var original_len = input_val?.length;

  // initial caret position
  //var caret_pos = input.prop('selectionStart');

  // check for decimal
  if (input_val.indexOf('.') >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    //var decimal_pos = input_val.indexOf('.');

    // split number by decimal point
    var left_side = input_val.substring(0, decimal_pos);
    var right_side = input_val.substring(decimal_pos);

    // add commas to left side of number
    left_side = formatNumber(left_side);

    // validate right side
    right_side = formatNumber(right_side);

    // On blur make sure 2 numbers after decimal
    if (blur === 'blur') {
      right_side += '00';
    }

    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, 2);

    // join number by .
    input_val = '$' + left_side + '.' + right_side;
  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    input_val = formatNumber(input_val);
    input_val = '$' + input_val;

    // final formatting
    if (blur === 'blur') {
      input_val += '.00';
    }
  }

  // send updated string to input
  input.val(input_val);

  // put caret back in the right position
  var updated_len = input_val?.length;
  caret_pos = updated_len - original_len + caret_pos;
  input[0]?.setSelectionRange(caret_pos, caret_pos);
};

export const formatNumber = (n) => {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getInitDataForProcess = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 1, no2: 2, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 1, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 1 }],
      faceProcess: [{ no: 1, checked: 1 }],
      shearing: [{ no: 1, checked: 1 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 0, process1: 1, process2: 1, process3: 1, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 2, type: 1 }],
          process2: [{ no: 3, type: 3 }],
          process3: [{ no: 5, type: 3 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 1 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: null, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 1 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    })?.map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess1 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 0 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    })?.map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};

export const getInitDataForProcess2 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    })?.map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess3 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    })?.map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess4 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    })?.map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess5 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    })?.map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};

export const getInitDataForProcess_PMX_1 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 0 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 1, no2: 2, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 1, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 1 }],
      faceProcess: [{ no: 1, checked: 1 }],
      shearing: [{ no: 1, checked: 1 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 0, process1: 1, process2: 1, process3: 1, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 2, type: 1 }],
          process2: [{ no: 5, type: 3 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 1 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 0, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: null, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    })?.map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess_PMX_1_1 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    })?.map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess_PMX_2 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 1, no2: 2, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 1, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 1 }],
      faceProcess: [{ no: 1, checked: 1 }],
      shearing: [{ no: 1, checked: 1 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 1, process2: 1, process3: 1, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 0,
          process1: [{ no: 2, type: 1 }],
          process2: [{ no: 5, type: 3 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 1 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 0, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: null, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 1 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    })?.map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess_PMX_2_1 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    })?.map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};

export const patternPage = [
  { mode: '1', page: '親部品' },
  { mode: '2', page: '板金' },
  { mode: '3', page: 'パラメータ' },
];

export const isNumber = (char) => {
  return /^\d$/.test(char);
};

export const checkShowHideIcon = (id) => {
  let showHideCu = 'none';
  if (document.getElementById(id) !== null) {
    showHideCu = document.getElementById(id).style.display;
  }
  return showHideCu;
};

export const JPYs = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'JPY',
});

export const Percent = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 1,
});

export const kouteiCalculateSelectedArr = [
  { key: 0, value: 'データ' },
  { key: 1, value: '編集データ' },
];
export const dataStateList = [
  { value: 0, label: '全て' },
  { value: 1, label: '表示' },
  { value: 2, label: '非表示' },
];
export const flgStateList = [
  { value: 0, label: '全て' },
  { value: 1, label: 'オン' },
  { value: 2, label: 'オフ' },
];
export const parameterCostLst = [
  {
    key: 1,
    title: '諸経費',
    data: 0,
    dataIndex: 'costLst1',
    isUsedTanka: true,
  },
  {
    key: 2,
    title: '特別費',
    data: 0,
    dataIndex: 'costLst2',
    isUsedTanka: true,
  },
  {
    key: 3,
    title: '調査費​',
    data: 0,
    dataIndex: 'costLst3',
    isUsedTanka: true,
  },
  {
    key: 4,
    title: 'カスタマイズ費用',
    data: 0,
    dataIndex: 'costLst4',
    isUsedTanka: true,
  },
];
export const parameterDateLst = [
  {
    key: 1,
    title: '社内納期',
    data: dayjs().format('YYYY-MM-DD'),
    dataIndex: 'dateLst1',
  },
  {
    key: 2,
    title: '回答日',
    data: dayjs().format('YYYY-MM-DD'),
    dataIndex: 'dateLst2',
  },
  {
    key: 3,
    title: 'カスタマイズ日付',
    data: dayjs().format('YYYY-MM-DD'),
    dataIndex: 'dateLst3',
  },
];
export const parameterLabelLst = [
  {
    key: 1,
    title: '製造部管理ラベル',
    data: '',
    dataIndex: 'labelLst1',
  },
  {
    key: 2,
    title: '製造部管理ラベル',
    data: '',
    dataIndex: 'labelLst2',
  },
  {
    key: 3,
    title: 'カスタマイズラベル',
    data: '',
    dataIndex: 'labelLst3',
  },
];
export const parameterMemoLst = [
  {
    key: 1,
    title: '注意事項',
    data: '',
    dataIndex: 'memoLst1',
  },
  {
    key: 2,
    title: '見積備考',
    data: '',
    dataIndex: 'memoLst2',
  },
  {
    key: 3,
    title: 'コメント',
    data: '',
    dataIndex: 'memoLst3',
  },
  {
    key: 4,
    title: 'カスタマイズメモ',
    data: '',
    dataIndex: 'memoLst4',
  },
];

// 現在の日付「YYYY/MM/DD」

export const today = new Date().toLocaleString('ja-JP', {
  timeZone: 'Asia/Tokyo',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});

// 現在の日付「YYYY/MM/DD hhmmss」
export const todayTime = () => {
  return new Date().toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    format: 'YYYYMMDD hhmmss',
  });
};

// YYYY/MM/DDへ変換
export function formatDate(date) {
  return new Date(date).toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

// 現在のタイム「時分秒」
export const currentTime = () => {
  return new Date().toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    format: 'hhmmss',
  });
};

// 現在の日付「年月日」
export const currentDate = () => {
  return new Date().toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    format: 'YYYYMMDD',
  });
};

export function formatDateString(date, sep = '') {
  let dateString = date.replace(/[/:]+/g, '');
  dateString = dateString ? dateString : '';
  return dateString;
}

export const getZaishittsuNameDDL = () => {
  let materialClassNameStorage = getMaterialClassNameStorage();
  const zaishittsuNameDDL = [];
  let count = 0;
  materialClassNameStorage?.forEach((item) => {
    if (item.displayFlag) {
      zaishittsuNameDDL.push({ value: count++, label: item.materialName });
    }
  });
  return zaishittsuNameDDL;
};

// 板金子部品材質マスタを選択ボックス情報にする
export const getMaterialTypeIQ3List = async () => {
  let materialTypesIq3 = [];
  let materialTypesIq3Info = await getMaterialTypeIQ3Info(0, 0, 'ASC', '', {});
  if (materialTypesIq3Info) {
    materialTypesIq3 = materialTypesIq3Info ? materialTypesIq3Info?.data : [];
  }
  const materialTypesIq3List = [];
  materialTypesIq3?.forEach((item) => {
    if (item.info.isUsed) {
      materialTypesIq3List.push({
        value: item.id,
        label: item.name,
        gravity: item?.info?.gravity,
        materialTypeId: item.materialTypeId,
      });
    }
  });
  return materialTypesIq3List;
};

export const MaterialCategories = [
  { value: MaterialCategory.Iron, label: '鉄' },
  { value: MaterialCategory.Stainless, label: 'ステンレス' },
  { value: MaterialCategory.Aluminum, label: 'アルミ' },
  { value: MaterialCategory.Copper, label: '銅' },
  { value: MaterialCategory.Zinc, label: '亜鉛' },
  { value: MaterialCategory.Other, label: 'その他' },
];

export const unitTypes = [
  { value: MaterialSurfaceBillingUnit.Weight, label: '重量' },
  { value: MaterialSurfaceBillingUnit.SquareMeter, label: '平米' },
];

export const ServiceClasses = [
  { value: ServiceClass.Parent, label: '親部品' },
  { value: ServiceClass.SheetMetal, label: '板金子部品' },
  { value: ServiceClass.Beams, label: '形鋼' },
  { value: ServiceClass.Machining, label: '機械加工' },
];

export const WorkTypes = [
  { value: WorkType.PaWelding, label: '溶接' },
  { value: WorkType.PaWeldFinish, label: '溶接仕上' },
  { value: WorkType.PaSurface, label: '表面処理' },
  { value: WorkType.PaAssenble, label: '組立' },
  { value: WorkType.PaInspection, label: '検査' },
  { value: WorkType.PaPacking, label: '梱包' },
  { value: WorkType.PaCustom, label: '追加工程（カスタム）' },
  { value: WorkType.SmProgram, label: 'プログラム' },
  { value: WorkType.SmBlankLaser, label: 'ブランク（レーザー）' },
  { value: WorkType.SmBlankPunch, label: 'ブランク（パンチ）' },
  { value: WorkType.SmBlankCombi, label: 'ブランク（複合機）' },
  { value: WorkType.SmSecondaryWork, label: '2次加工' },
  { value: WorkType.SmBending, label: 'ベンディング' },
  { value: WorkType.SmDeburringAuto, label: 'バリ取（自動）' },
  { value: WorkType.SmDeburringManual, label: 'バリ取（手動）' },
  { value: WorkType.SmWelding, label: '溶接' },
  { value: WorkType.SmWeldFinish, label: '溶接仕上' },
  { value: WorkType.SmSurface, label: '表面処理' },
  { value: WorkType.SmShearing, label: 'シャーリング' },
  { value: WorkType.SmInspection, label: '検査' },
  { value: WorkType.SmCustom, label: '追加工程（カスタム）' },
];

export const getKubunNameDDL = () => {
  let materialClassStorageData = getMaterialClassDataSample();
  const kubunNameDDL = [];
  let count = 0;
  materialClassStorageData?.forEach((item) => {
    if (item.displayFlag) {
      kubunNameDDL.push({ value: count++, label: item.classificationName });
    }
  });
  return kubunNameDDL;
};

// 材質区分名を選択ボックス情報にする
export const getMaterialTypeList = async () => {
  let materialTypes = await getMaterialTypeInfo();
  const materialTypesList = [];
  let count = 0;
  materialTypes?.forEach((item) => {
    if (item.info.isUsed) {
      materialTypesList.push({ value: item.id, label: item.name });
    }
  });
  return materialTypesList;
};

// 工程パターン画面のCarousel操作
const iconClassName_inactive = 'carousel-circleOutlined-inactive';
const iconClassName_active = 'carousel-circleOutlined-active';
let iconClassName_pre = iconClassName_active;
let iconClassName_next = iconClassName_active;
const slickActive = 'slick-active';

export const CarouselPrevArrow = (props) => {
  const { className, style, onClick, targetDivCnt, name } = props;

  const onChangePrevArrow = () => {
    let activeDiv = [];
    let activeDivIndex = 0;
    activeDiv = document.getElementById(name).getElementsByClassName(slickActive);
    if (activeDiv?.length > 1) activeDivIndex = activeDiv[activeDiv?.length - 1]?.innerText;
    let lastDivIndex = parseInt(activeDivIndex) - 1;
    if (activeDivIndex == 1) {
      // iconClassName_pre = iconClassName_inactive;
    } else if (activeDivIndex <= targetDivCnt) {
      // iconClassName_next = iconClassName_active;
      onClick();
      // if (lastDivIndex === 1) {
      //   iconClassName_pre = iconClassName_inactive;
      // } else {
      //   iconClassName_pre = iconClassName_active;
      // }
    }
  };

  return (
    <Button
      className={className}
      style={{ ...style }}
      onClick={onChangePrevArrow}
      icon={<LeftCircleOutlined id="leftCarouselCircle" className={iconClassName_pre} />}
    />
  );
};
export const CarouselNextArrow = (props) => {
  const { className, style, onClick, targetDivCnt, name } = props;

  const onChangeNextArrow = () => {
    let activeDiv = [];
    let activeDivIndex = 0;
    activeDiv = document.getElementById(name).getElementsByClassName(slickActive);
    if (activeDiv?.length > 1) activeDivIndex = activeDiv[activeDiv?.length - 1]?.innerText;
    let lastDivIndex = parseInt(activeDivIndex) + 1;

    if (activeDivIndex < targetDivCnt) {
      // iconClassName_pre = iconClassName_active;
      onClick();
      // if (lastDivIndex === targetDivCnt) {
      //   iconClassName_next = iconClassName_inactive;
      // } else {
      //   iconClassName_next = iconClassName_active;
      // }
    } else {
      // iconClassName_next = iconClassName_inactive;
    }
  };

  return (
    <Button
      className={className}
      style={{ ...style }}
      onClick={onChangeNextArrow}
      icon={<RightCircleOutlined id="rightCarouselCircle" className={iconClassName_next} />}
    />
  );
};

// export const initialCarouselBtn = () => {
//   let leftCircleList = document.getElementsByClassName('anticon-left-circle');
//   Object.keys(leftCircleList)?.map((anticonLeftCircle, j) => {
//     leftCircleList[anticonLeftCircle]?.classList?.remove(iconClassName_active);
//     leftCircleList[anticonLeftCircle]?.classList.add(iconClassName_inactive);
//   });
//   let rightCircleList = document.getElementsByClassName('anticon-right-circle');
//   Object.keys(rightCircleList)?.map((anticonRightCircle, j) => {
//     rightCircleList[anticonRightCircle]?.classList?.remove(iconClassName_inactive);
//     rightCircleList[anticonRightCircle]?.classList.add(iconClassName_active);
//   });
// };

export function groupBy(items) {
  return items?.reduce((acc, curr) => {
    if (curr.detailGroup) {
      const { detailGroup } = curr;
      const currentItems = acc[detailGroup];

      return {
        ...acc,
        [detailGroup]: currentItems ? [...currentItems, curr] : [curr],
      };
    }
    return acc;
  }, {});
}

// 装置の段取時間更新
export const souchiHandleIncreaseTime = (time, timeType) => {
  let totalSec = Number(setTimetoSec(time));
  let totalIncSec;
  if (timeType == '10min') {
    totalIncSec = totalSec + 600;
  } else if (timeType == 'min') {
    totalIncSec = totalSec + 60;
  } else if (timeType == 'sec') {
    totalIncSec = totalSec + 10;
  }
  let timeStr = secondsToHms(totalIncSec);

  return timeStr;
};
export const souchiHandleDecreaseTime = (time, timeType) => {
  let totalSec = Number(setTimetoSec(time));
  let totalIncSec;
  if (timeType == '10min' && totalSec >= 600) {
    totalIncSec = totalSec - 600;
  } else if (timeType == '10min' && totalSec < 600) {
    totalIncSec = totalSec;
  } else if (timeType == 'min' && totalSec >= 60) {
    totalIncSec = totalSec - 60;
  } /* else if (timeType == 'min' && totalSec < 60) {
    totalIncSec = totalSec;
  }  */ else if (timeType == 'sec') {
    totalIncSec = totalSec - 10;
  }
  let timeStr = secondsToHms(totalIncSec);

  return timeStr;
};

export const actionArr = [
  {
    key: 0,
    methodName: 'rowSelect',
  },
  {
    key: 1,
    methodName: 'searchByKeyword',
  },
  {
    key: 2,
    methodName: 'handleGpEdit',
  },
  {
    key: 3,
    methodName: 'handleAddFormSubmit',
  },
  {
    key: 4,
    methodName: 'searchByDataState',
  },
  {
    key: 5,
    methodName: 'searchByShowFlg',
  },
  {
    key: 6,
    methodName: 'csvImport',
  },
  {
    key: 7,
    methodName: 'csvExport',
  },
];

export const removeYen = (value) => {
  let noYen = String(value);
  if (value === '' || value === undefined) {
    noYen = '0';
  }
  if (value.startsWith('¥', 0)) {
    noYen = noYen.replace('¥', '');
  }
  noYen = noYen.replace(/,/g, '');
  return noYen;
};

export const { TextArea } = antdInput;

// メールフォーマットのチェック
export function isEmail(val) {
  // ####@####の形式
  let regEmail = /^[^\s]+@[^\s]+$/;
  return regEmail.test(val);
}

// 数字のチェック
export function isDigit(val) {
  // 整数値のみ
  let regDigit = /^[0-9]+$/;
  return regDigit.test(val);
}

// Tel又Faxのチェック
export function isTelAndFax(val) {
  // プラス、ハイフン、整数値のみ
  let regStr = /^[0-9+-]+$/;
  return regStr.test(val);
}

// 小数点のチェック
export function isDecimal(val) {
  // 小数点
  let regStr = /^\d*\.?\d*$/;
  return regStr.test(val);
}

// 入力項目のチェックを行う処理タイプ
export const formValidatorMode = {
  Create: 1, // 新規作成
  Edit: 2, // 編集
  BulkEdit: 3, // 一括編集
};

// セクションにトークン情報を更新する
export const updateAccessToken = (token) => {
  localStorage.setItem('iQxWeb_AccessToken', token);
};

const releaseDate = '2023-10-01';
export const getMaterialClassDataSample = () => {
  let count = 0;
  const materialClassDataSample = [
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'SPHC',
      materialRefPrice: '鉄',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'SPCC',
      materialRefPrice: '鉄',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'SECC',
      materialRefPrice: '鉄',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'SUS',
      materialRefPrice: 'ステンレス',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'AL',
      materialRefPrice: 'アルミ',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'CU',
      materialRefPrice: '銅',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: false,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'その他',
      materialRefPrice: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: false,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'その他',
      materialRefPrice: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: false,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'その他',
      materialRefPrice: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: false,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'その他',
      materialRefPrice: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
  ];
  if (
    localStorage.getItem('materialClassStorageData') === null ||
    localStorage.getItem('materialClassStorageData')?.length <= 0
  ) {
    localStorage.setItem('materialClassStorageData', JSON.stringify(materialClassDataSample));
  }
  return JSON.parse(localStorage.getItem('materialClassStorageData'));
};

export const getMaterialClassNameStorage = () => {
  let count = 0;

  const materialClassNameDataSample = [
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SPCC',
      materialClassName: 'SPCC',
      gravity: 7.85,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '冷延鋼材',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SPHC（黒皮）',
      materialClassName: 'SPHC',
      gravity: 7.85,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '熱延鋼材：黒皮',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SPHC（酸洗）',
      materialClassName: 'SPHC',
      gravity: 7.85,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '熱延鋼材：酸洗',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SECC（ボンデ）',
      materialClassName: 'SECC',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ボンデ鋼材',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SS400',
      materialClassName: 'SPHC',
      gravity: 7.85,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '一般構造用圧延鋼材',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'ZAM',
      materialClassName: 'SECC',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/2B',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/NO1',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/#400',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/両面#400',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/HL',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/両面HL',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/BA',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS316',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS316L',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS430/2B',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS430/BA',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS430/#400',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS430/HL',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'A1050',
      materialClassName: 'AL',
      gravity: 2.7,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'A1100',
      materialClassName: 'AL',
      gravity: 2.7,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'A5052',
      materialClassName: 'AL',
      gravity: 2.7,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'C1100',
      materialClassName: 'CU',
      gravity: 8.0,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
  ];
  if (
    localStorage.getItem('materialClassNameStorage') === null ||
    localStorage.getItem('materialClassNameStorage')?.length <= 0
  ) {
    localStorage.setItem('materialClassNameStorage', JSON.stringify(materialClassNameDataSample));
  }

  return JSON.parse(localStorage.getItem('materialClassNameStorage'));
};

export const getMaterialNameStorage = () => {
  let count = 0;

  const materialNameDataSample = [
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 0.4',
      materialName: 'SPCC',
      thickness: 0.4,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [{ updId: 0, updateDate: releaseDate, updPrice: 120 }],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC-  0.51',
      materialName: 'SPCC',
      thickness: 0.5,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 0.6',
      materialName: 'SPCC',
      thickness: 0.6,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 0.7',
      materialName: 'SPCC',
      thickness: 0.7,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 0.8',
      materialName: 'SPCC',
      thickness: 0.8,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 0.9',
      materialName: 'SPCC',
      thickness: 0.9,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 1.0',
      materialName: 'SPCC',
      thickness: 1,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 1.2',
      materialName: 'SPCC',
      thickness: 1.2,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 1.4',
      materialName: 'SPCC',
      thickness: 1.4,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 1.6',
      materialName: 'SPCC',
      thickness: 1.6,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 1.8',
      materialName: 'SPCC',
      thickness: 1.8,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 2.0',
      materialName: 'SPCC',
      thickness: 2,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 2.3',
      materialName: 'SPCC',
      thickness: 2.3,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 2.5',
      materialName: 'SPCC',
      thickness: 2.5,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 2.6',
      materialName: 'SPCC',
      thickness: 2.6,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 2.8',
      materialName: 'SPCC',
      thickness: 2.8,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 2.9',
      materialName: 'SPCC',
      thickness: 2.9,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
  ];
  if (
    localStorage.getItem('materialNameStorage') === null ||
    localStorage.getItem('materialNameStorage')?.length <= 0
  ) {
    localStorage.setItem('materialNameStorage', JSON.stringify(materialNameDataSample));
  }

  return JSON.parse(localStorage.getItem('materialNameStorage'));
};

export const getMaterialSizeStorage = () => {
  let count = 0;

  const materialSizeDataSample = [
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: 'Other',
      sizeX: 0,
      sizeY: 0,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: "3'x6'",
      sizeX: 1829,
      sizeY: 914,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: "4'x8'",
      sizeX: 2438,
      sizeY: 1219,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: "5'x10'",
      sizeX: 3048,
      sizeY: 1524,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: '1x2m',
      sizeX: 2000,
      sizeY: 1000,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: '2x4m',
      sizeX: 4000,
      sizeY: 2000,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: '1.25x2.5m',
      sizeX: 2500,
      sizeY: 1250,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: '小板',
      sizeX: 1000,
      sizeY: 365,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: 'free',
      sizeX: 0,
      sizeY: 0,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
  ];
  if (
    localStorage.getItem('materialSizeStorage') === null ||
    localStorage.getItem('materialSizeStorage')?.length <= 0
  ) {
    localStorage.setItem('materialSizeStorage', JSON.stringify(materialSizeDataSample));
  }

  return JSON.parse(localStorage.getItem('materialSizeStorage'));
};

export const getMaterialSurfaceStorage = () => {
  let count = 0;

  const materialSurfaceDataSample = [
    {
      key: 3,
      no: 4,
      displayFlag: true,
      materialName: '',
      surfaceName: 'なし',
      attributeType: '',
      additionalMethod: '',
      doubleLaser: true,
      processSpeed: '',
      additionalAmount: '',
      remark: '',
      created: '',
      modified: '',
    },
    {
      key: 0,
      no: 1,
      displayFlag: true,
      materialName: 'SPCC',
      surfaceName: '片面ビニール',
      attributeType: 'ビニール保護',
      additionalMethod: '重量',
      doubleLaser: true,
      processSpeed: 4000,
      additionalAmount: '',
      created: '2023-10-01',
      modified: '2023-07-10',
    },
    {
      key: 1,
      no: 2,
      displayFlag: true,
      materialName: 'SUS304/2B',
      surfaceName: '片面ビニール',
      attributeType: 'ビニール保護',
      additionalMethod: '重量',
      doubleLaser: true,
      processSpeed: 4000,
      additionalAmount: '',
      remark: '',
      created: '2023-07-10',
      modified: '2023-07-10',
    },
    {
      key: 2,
      no: 3,
      displayFlag: true,
      materialName: 'SUS304/2B',
      surfaceName: '両面ビニール',
      attributeType: 'ビニール保護',
      additionalMethod: '重量',
      doubleLaser: true,
      processSpeed: '2000',
      additionalAmount: '',
      remark: '',
      created: '2023-07-10',
      modified: '2023-07-10',
    },
  ];
  if (
    localStorage.getItem('materialSurfaceStorage') === null ||
    localStorage.getItem('materialSurfaceStorage')?.length <= 0
  ) {
    localStorage.setItem('materialSurfaceStorage', JSON.stringify(materialSurfaceDataSample));
  }

  return JSON.parse(localStorage.getItem('materialSurfaceStorage'));
};

// 小数点１へ変換する
export const toDecimal = (value, places = 1) => {
  if (!isDecimal(value)) return value;
  if (value?.length <= 0) value = 0;
  value = value ? Math.round(value * 100) / 100 : 0;
  value = value?.toFixed(places);
  return value;
};

// テーブル列の幅Resizable
export const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

// 破壊ポップアップを表示させる処理タイプ
export const discardChange = [
  { no: 1, state: 'rowSelect' },
  { no: 2, state: 'search' },
  { no: 3, state: 'bulkEdit' },
  { no: 4, state: 'createNew' },
  { no: 5, state: 'csvImport' },
  { no: 6, state: 'csvExport' },
];

//　係数値下げ、上げ計算
export const increaseDecreaseKeisu = (value, increaseFlag) => {
  let targetValue = parseFloat(value);
  if (increaseFlag) {
    targetValue = targetValue + 0.01;
  } else {
    targetValue = targetValue - 0.01;
  }
  return targetValue.toFixed(2);
};

export const isCustomer = (value) => {
  if ((value & ClientType.Customer) === ClientType.Customer) return true;
  return false;
};

export const isSupplier = (value) => {
  if ((value & ClientType.Supplier) === ClientType.Supplier) return true;
  return false;
};

export const isContractor = (value) => {
  if ((value & ClientType.Contractor) === ClientType.Contractor) return true;
  return false;
};
// 装置設定
export const deleteListColData = (list, deletedId, isObjType = false, columnName = '') => {
  let deleteData;
  if (isObjType) {
    deleteData = Object.entries(list)?.map(([key, value]) => value?.filter((item) => !deletedId?.includes(item.no)));
  } else if (columnName !== '') {
    deleteData = list?.filter((item) => !deletedId?.includes(item[columnName]));
  } else {
    deleteData = list?.filter((item) => !deletedId?.includes(item.no));
  }
  return deleteData;
};

export const updateListColData = (list, newId, isObjType = false, customItem = {}, columnName = '') => {
  // const updatedData = list?.map((item) => {
  //   newId?.forEach((key) => {
  //     // Add new objects to lstData
  //     item.lstData.push({
  //       key: key,
  //       value: '',
  //     });
  //   });
  //   return item;
  // });
  // return updatedData;

  newId?.forEach((no) => {
    // Add new objects to lstData
    if (isObjType) {
      Object.entries(list)?.map(([key, value]) =>
        value.push({
          no: no,
          value: 1,
          isOutOfRange: false,
        })
      );
    } else if (Object.keys(customItem)?.length > 0) {
      if (columnName !== '') {
        customItem[columnName] = no;
      }
      list.push(customItem);
    } else {
      list.push({
        no: no,
        value: 1,
        isOutOfRange: false,
      });
    }
  });
  return list;
};

export const updateJikanListColData = (list, newId) => {
  const updatedData = list?.map((item) => {
    newId?.forEach((key) => {
      // Add new objects to lstData
      item.lstData.push({
        key: key,
        value: '00:00:00',
      });
    });

    return item;
  });
  return updatedData;
};

export const sortListData = (list, masterData, isObjType = false, columnName = '') => {
  // const sortedData = list?.map((item) => {
  //   const sortedLstData = [...item.lstData];
  //   sortedLstData.sort((a, b) => {
  //     const aIndex = masterData?.findIndex((obj) => obj.id === a.key);
  //     const bIndex = masterData?.findIndex((obj) => obj.id === b.key);
  //     return aIndex - bIndex;
  //   });

  //   return {
  //     key: item.key,
  //     materialType: item.materialType,
  //     lstData: sortedLstData,
  //   };
  // });

  // return sortedData;
  if (isObjType) {
    Object.entries(list)?.map(([key, value]) =>
      value.sort((a, b) => {
        const aIndex = masterData?.findIndex((obj) => obj.no === a.no);
        const bIndex = masterData?.findIndex((obj) => obj.no === b.no);
        return aIndex - bIndex;
      })
    );
  } else if (columnName !== '') {
    list.sort((a, b) => {
      const aIndex = masterData?.findIndex((obj) => obj.no === a[columnName]);
      const bIndex = masterData?.findIndex((obj) => obj.no === b[columnName]);
      return aIndex - bIndex;
    });
  } else {
    list.sort((a, b) => {
      const aIndex = masterData?.findIndex((obj) => obj.no === a.no);
      const bIndex = masterData?.findIndex((obj) => obj.no === b.no);
      return aIndex - bIndex;
    });
  }
  return list;
};

export const sortSizeListData = (list, masterData) => {
  const sortedList = list?.map((item) => {
    const sortedLstData = [...item.lstData];
    sortedLstData.sort((a, b) => {
      const aIndex = masterData?.findIndex((obj) => obj.id === a.key);
      const bIndex = masterData?.findIndex((obj) => obj.id === b.key);
      return aIndex - bIndex;
    });

    return {
      key: item.key,
      max: item.max,
      displayFlag: item.displayFlag,
      lstData: sortedLstData,
    };
  });
  return sortedList;
};

export const updateWeightListData = (listData, masterData) => {
  const result = listData?.map((item) => {
    const list = masterData?.find((listItem) => listItem.id === item.key);

    return {
      ...item,
      max: list ? list.max : item.max,
      lstData: item.lstData?.map((lstItem) => {
        const newListData = {
          key: lstItem.key,
          value: lstItem.value,
        };

        return newListData;
      }),
    };
  });
  return result;
};
export const sortWeightListData = (list, masterData) => {
  const result = list.sort((a, b) => {
    const aIndex = masterData?.findIndex((obj) => obj.id === a.key);
    const bIndex = masterData?.findIndex((obj) => obj.id === b.key);
    return aIndex - bIndex;
  });
  return result;
};
// 装置設定
export const createSizeListData = (weight, area) => {
  const result = weight?.map((weightItem) => {
    const lstData = area
      ?.filter((subitem) => subitem.displayFlag === true)
      ?.map((item) => {
        return {
          key: item.id,
          value: 0,
        };
      });

    return {
      key: weightItem.id,
      max: weightItem.max,
      displayFlag: weightItem.displayFlag,
      lstData: lstData,
    };
  });
  return result;
};
export const createListData = (type, masterData) => {
  const result = type?.map((materialType, key) => {
    const filteredData2 = masterData
      ?.filter((subitem) => subitem.displayFlag === true)
      ?.map((item) => {
        return {
          key: item.id,
          value: '0',
        };
      });

    return {
      key: key + 1,
      materialType,
      lstData: filteredData2,
      isUsed: false,
    };
  });
  return result;
};
export const createGasListData = (type, masterData) => {
  const result = type?.map((materialType, key) => {
    const filteredData2 = masterData
      ?.filter((subitem) => subitem.displayFlag === true)
      ?.map((item) => {
        return {
          key: item.id,
          value: '',
        };
      });

    return {
      key: key + 1,
      materialType,
      lstData: filteredData2,
      isUsed: false,
    };
  });
  return result;
};
export const createKeisuuListData = (type, masterData) => {
  const result = type?.map((materialType, key) => {
    const filteredData2 = masterData
      ?.filter((subitem) => subitem.displayFlag === true)
      ?.map((item) => {
        return {
          key: item.id,
          value: '1.00',
        };
      });

    return {
      key: key + 1,
      materialType,
      lstData: filteredData2,
      isUsed: false,
    };
  });
  return result;
};
export const createJikanListData = (type, masterData) => {
  const result = type?.map((materialType, key) => {
    const filteredData2 = masterData
      ?.filter((subitem) => subitem.displayFlag === true)
      ?.map((item) => {
        return {
          key: item.id,
          value: '00:00:00',
        };
      });

    return {
      key: key + 1,
      materialType,
      lstData: filteredData2,
      isUsed: false,
    };
  });
  return result;
};
export const updateJikanList = (masterData, updatedMasterData, listData) => {
  // 削除したid取得
  const deletedId = [];
  masterData?.forEach((item1, index) => {
    const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
    if (found) {
      deletedId.push(item1.id);
    }
  });
  // 新規追加したid取得
  const newId = updatedMasterData
    ?.filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
    ?.map((item) => item.id);
  // リスト更新
  const deleteLstData = listData?.map((item) => {
    return {
      ...item,
      lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
    };
  });
  const updatedData = deleteLstData?.map((item) => {
    newId?.forEach((key) => {
      // Add new objects to lstData
      item.lstData.push({
        key: key,
        value: '00:00:00',
      });
    });

    return item;
  });
  const sortedList = updatedData?.map((item) => {
    const sortedLstData = [...item.lstData];
    sortedLstData.sort((a, b) => {
      const aIndex = updatedMasterData?.findIndex((obj) => obj.id === a.key);
      const bIndex = updatedMasterData?.findIndex((obj) => obj.id === b.key);
      return aIndex - bIndex;
    });

    return {
      key: item.key,
      materialType: item.materialType,
      lstData: sortedLstData,
      isUsed: item.isUsed,
    };
  });
  return sortedList;
};
export const createJikanSizeListData = (weight, area) => {
  const result = weight?.map((weightItem) => {
    const lstData = area
      ?.filter((subitem) => subitem.displayFlag === true)
      ?.map((item) => {
        return {
          key: item.id,
          value: '00:00:00',
        };
      });

    return {
      key: weightItem.id,
      max: weightItem.max,
      displayFlag: weightItem.displayFlag,
      lstData: lstData,
    };
  });
  return result;
};
export const updateJikanSizeListData = (masterData, updatedMasterData, listData) => {
  // 削除したid取得
  const deletedId = [];
  masterData?.forEach((item1, index) => {
    const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
    if (found) {
      deletedId.push(item1.id);
    }
  });
  // 新規追加したid取得
  const newId = updatedMasterData
    ?.filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
    ?.map((item) => item.id);
  // リスト更新
  const deleteLstData = listData?.map((item) => {
    return {
      ...item,
      lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
    };
  });
  const updatedData = deleteLstData?.map((item) => {
    newId?.forEach((key) => {
      // Add new objects to lstData
      item.lstData.push({
        key: key,
        value: '00:00:00',
      });
    });

    return item;
  });
  const sortedList = updatedData?.map((item) => {
    const sortedLstData = [...item.lstData];
    sortedLstData.sort((a, b) => {
      const aIndex = updatedMasterData?.findIndex((obj) => obj.id === a.key);
      const bIndex = updatedMasterData?.findIndex((obj) => obj.id === b.key);
      return aIndex - bIndex;
    });

    return {
      key: item.key,
      materialType: item.materialType,
      lstData: sortedLstData,
      isUsed: item.isUsed,
    };
  });
  return sortedList;
};
export const updateProductSizeJikanList = (masterData, updatedMasterData, listData) => {
  const deletedId = [];

  masterData?.forEach((item1, index) => {
    const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
    if (found) {
      deletedId.push(item1.id);
    }
  });

  const newId = updatedMasterData
    ?.filter((item) => item.displayFlag && !masterData?.some((data2Item) => data2Item.id === item.id))
    ?.map((item) => item.id);

  const deleteData = listData?.filter((it) => !deletedId?.includes(it.key));

  const result = deleteData?.map((item) => {
    const list = updatedMasterData?.find((listItem) => listItem.id === item.key);

    return {
      ...item,
      max: list ? list.max : item.max,
      lstData: item.lstData?.map((lstItem) => {
        const newListData = {
          key: lstItem.key,
          value: lstItem.value,
        };

        return newListData;
      }),
    };
  });

  newId?.forEach((id) => {
    const list = updatedMasterData?.find((item) => item.id === id);

    if (list) {
      const newObj = {
        key: id,
        max: list.max,
        displayFlag: true,
        lstData: result[0]?.lstData?.map((lstItem) => {
          return {
            key: lstItem.key,
            value: '00:00:00',
          };
        }),
      };

      result?.push(newObj);
    }
  });
  result?.sort((a, b) => {
    const aIndex = updatedMasterData?.findIndex((obj) => obj.id === a.key);
    const bIndex = updatedMasterData?.findIndex((obj) => obj.id === b.key);
    return aIndex - bIndex;
  });
  return result;
};
export const createShiageFlagData = (type, masterData) => {
  const result = type?.map((materialType, key) => {
    const filteredData2 = masterData?.map((item) => {
      return {
        name: item,
        flag: false,
      };
    });

    return {
      key: key + 1,
      materialType,
      lstData: filteredData2,
    };
  });
  return result;
};
export const createKakouSokudoListData = (type, masterData) => {
  const list = [
    { key: 1, name: '丸穴径', svalue: '', mvalue: '', lvalue: '' },
    { key: 2, name: '加工周長', svalue: '', mvalue: '', lvalue: '' },
    { key: 3, name: '速度', svalue: '', mvalue: '', lvalue: '' },
  ];
  const result = type?.map((materialType, key) => {
    const filteredData2 = masterData
      ?.filter((subitem) => subitem.displayFlag === true)
      ?.map((item) => {
        return {
          key: item.id,
          value: 0,
          list: list,
        };
      });

    return {
      key: key + 1,
      materialType,
      lstData: filteredData2,
    };
  });
  return result;
};

// リスト更新
export const updateList = (originalList, deleteNos, addNos, inputVal = {}) => {
  const updatedList = {};

  // Loop through the keys (ids) in the original list
  Object.keys(originalList)?.forEach((id) => {
    // 削除
    const updatedItems = originalList[id]?.filter((item) => !deleteNos?.includes(item.no));

    // 追加
    if (Object.keys(inputVal)?.length > 0) {
      addNos?.forEach((no) => {
        updatedItems.push({
          no,
          value: 0,
          isOutOfRange: false,
          [Object.keys(inputVal)[0]]: inputVal[Object.keys(inputVal)[0]],
        });
      });
    } else {
      addNos?.forEach((no) => {
        updatedItems.push({
          no,
          value: 0,
          isOutOfRange: false,
        });
      });
    }

    // Assign the updated items to the corresponding id
    updatedList[id] = updatedItems;
  });

  return updatedList;
};
export const sortListByMasterData = (originalList, masterData) => {
  const noToIndexMap = {};
  masterData?.forEach((item, index) => {
    noToIndexMap[item.no] = index;
  });

  const updatedList = {};

  Object.keys(originalList)?.forEach((id) => {
    // noのindexによって項目をソートする
    const sortedItems = originalList[id]?.sort((a, b) => {
      const indexA = noToIndexMap[a.no];
      const indexB = noToIndexMap[b.no];
      return indexA - indexB;
    });

    // ソートされたアイテムを対応する ID に割り当てる
    updatedList[id] = sortedItems;
  });

  return updatedList;
};
export const updateSizeList = (list, deletedNo, addedNo) => {
  const newList = { ...list };
  delete newList[deletedNo];
  addedNo?.forEach((key, index) => {
    newList[key] = list['1']?.map((item) => ({
      no: item.no,
      value: 0,
      isOutOfRange: false,
    }));
  });
  return newList;
};

export const getInputBorderStyle = (index, masterData) => {
  const item = masterData?.filter((item) => !item.isOutOfRange)[index];

  if (item.value === 0 && item.isOutOfRange === false) {
    return { border: '1px solid red' };
  } else {
    return { border: '' };
  }
};
// 装置設定_end
export const getProcessDetailNameList = (type) => {
  let kouteiMaster =
    window.localStorage.getItem('kouteiMasterData') != undefined
      ? JSON.parse(window.localStorage.getItem('kouteiMasterData'))
      : [];
  let processDetail = [];
  kouteiMaster?.forEach((element) => {
    if (element.type === type) {
      processDetail = element.processDetail;
    }
  });
  let detailTypes = [];
  processDetail?.forEach((element) => {
    let processDetailValue = element.processDetailValue;
    processDetailValue?.forEach((e) => {
      detailTypes.push(e.detailType);
    });
  });
  return detailTypes;
};

// 最新のセクションにトークン情報を取得する
export const getAccessToken = () => {
  return localStorage?.getItem('iQxWeb_AccessToken');
};

export const defaultValue = {
  materialType: '材質区分名を選択してください。',
  materialTypeIq3: '材質名称を選択してください。',
  unitType: '加算方法を選択してください。',
  service: '種別を選択してください。',
  process: '工程を選択してください。',
  processType: '加工を選択してください。',
};

export const materialTypeDefaultValue = '材質区分名を選択でください。';

// IDで材料名称を取得
export function getMaterialNameById(id, Lists) {
  let retValue = '';
  if (id && Lists) {
    let info = Lists?.filter((info) => info.id === id);
    if (info?.length > 0) {
      retValue = info[0]?.name;
    }
  }
  return retValue;
}

// IDで板厚を取得
export function getMaterialThickById(id, Lists) {
  let retValue = '';
  if (id && Lists) {
    let info = Lists?.filter((info) => info.id === id);
    if (info?.length > 0) {
      retValue = info[0]?.info.thick;
    }
  }
  return retValue;
}

// IDで材質名称を取得
export function getMaterialTypeById(id, Lists) {
  let retValue = '';
  if (id && Lists) {
    let info = Lists?.filter((info) => info.id === id && info.info.isUsed);
    if (info?.length > 0) {
      retValue = info[0]?.name;
    }
  }
  return retValue;
}

// IDで表面保護を取得
export function getMaterialSurfaceById(id, Lists) {
  let retValue = '';
  if (id && Lists) {
    let info = Lists?.filter((info) => info.id === id);
    if (info?.length > 0) {
      retValue = info[0]?.name;
    }
  }
  return retValue;
}

export const processTypes = {
  standard: '標準',
  additional: '追加',
};

// 工程種別の詳細情報を得る
export const getProcessDetailTypesInfo = (detailsData, processMstRecord) => {
  // 装置の詳細情報で、工程種別の詳細情報を得る
  let displayList = Object.keys(detailsData)?.map(
    (key) =>
      processMstRecord?.details?.processDetailTypes?.filter(
        (subitem) => subitem.id === parseInt(key) && subitem.isUsed === true
      )?.[0]
  );
  // 工程グループより、行分けて表示するようにする
  const groupDisplayList = displayList?.reduce((list, item) => {
    let groupId = item?.group;
    if (groupId !== undefined) {
      if (!list[groupId]) list[groupId] = [];
      list[groupId]?.push(item);
    }
    return list;
  }, {});
  return groupDisplayList;
};

// 工程種別の詳細情報名を得る
export const getProcessDetailTypeName = (detailTypeId, processMstRecord) => {
  // 装置の詳細情報で、工程種別Idより名称を得る
  let name = processMstRecord?.details?.processDetailTypes?.filter(
    (subitem) => subitem.id === detailTypeId && subitem.isUsed
  )?.[0]?.name;
  return name;
};

/* export const handleKeyPress = (e, isNumber) => {
  const keyCode = e.which || e.keyCode;
  let isValidKey;
  if (isNumber) { */

export const formatCurrencyInput = (value) => {
  let formattedValue = String(value);
  if (value === '' || value === undefined) {
    formattedValue = 0;
  }
  if (value.startsWith('¥', 0)) {
    formattedValue = formattedValue.replace('¥', '');
  }
  if (/,/.test(formattedValue)) {
    formattedValue = formattedValue?.replace(/,/g, '');
  }
  return formattedValue;
};

export const handleKeyPress = (e, isNumeric) => {
  // const targetName = e.target.name;
  const keyCode = e.which || e.keyCode;
  let isValidKey;
  if (isNumeric) {
    isValidKey = keyCode >= 48 && keyCode <= 57;
  } else {
    //decimal入力チャック
    isValidKey = (keyCode >= 48 && keyCode <= 57) || keyCode === 46;
  }

  if (!isValidKey) {
    e.preventDefault();
  }
};

export const handleCollpse = (id) => {
  if (document.getElementById('div' + id) !== null) {
    let showHide = document.getElementById('div' + id)?.style.display;
    if (showHide == 'block') {
      document.getElementById('div' + id).style.display = 'none';
      document.getElementById('arrowUp' + id).style.display = 'none';
      document.getElementById('arrowDown' + id).style.display = 'block';
    } else {
      document.getElementById('div' + id).style.display = 'block';
      document.getElementById('arrowUp' + id).style.display = 'block';
      document.getElementById('arrowDown' + id).style.display = 'none';
    }
  }
};

// 溶接->段取設定の段取係数1の種類名
export const getPrepCoeffTypeName = (typeNo) => {
  let coeffTypeName = '';
  switch (typeNo) {
    case PrepCoeffType.ChildQty:
      coeffTypeName = '子部品点数';
      break;
    case PrepCoeffType.ChildTypeQty:
      coeffTypeName = '子部品種類数';
      break;
    default:
      break;
  }
  return coeffTypeName;
};

// 個数マスタのデフォルト値
export const defaultQuantiyData = [
  {
    no: 1,
    value: 0,
    isOutOfRange: false,
  },
  {
    no: 2,
    value: 0,
    isOutOfRange: false,
  },
];

// 重量マスタのデフォルト値
export const defaultWeightData = [
  {
    no: 1,
    value: 0,
    isOutOfRange: false,
  },
  {
    no: 2,
    value: 999,
    isOutOfRange: false,
  },
];

// 工程種別グループ名
export const getProcessDetailGroupName = (typeNo) => {
  let groupName = '';
  switch (typeNo) {
    case ProcessDetailGroup.General:
      groupName = '処理全般';
      break;
    case ProcessDetailGroup.Plating:
      groupName = 'メッキ';
      break;
    case ProcessDetailGroup.OneSide:
      groupName = '片面塗装';
      break;
    case ProcessDetailGroup.DoubleSide:
      groupName = '両面塗装';
      break;
    default:
      groupName = '無し';
      break;
  }
  return groupName;
};

// 工程種別グループ名
export const ProcessDetailGroups = [
  { value: ProcessDetailGroup.None, label: '無し' },
  { value: ProcessDetailGroup.General, label: '処理全般' },
  { value: ProcessDetailGroup.Plating, label: 'メッキ' },
  { value: ProcessDetailGroup.OneSide, label: '片面塗装' },
  { value: ProcessDetailGroup.DoubleSide, label: '両面塗装' },
];

// コードで名称を取得
export function getNameById(id, Lists) {
  let retValue = '';
  if (id) {
    let info = Lists?.filter((info) => info.id === id);
    if (info?.length > 0) {
      retValue = info[0]?.label;
    }
  }
  return retValue;
}

export function getNameByValue(value, Lists) {
  let retValue = '';
  if (value) {
    let info = Lists?.filter((info) => info.value === value);
    if (info?.length > 0) {
      retValue = info[0]?.label;
    }
  }
  return retValue;
}

// コードで名称を取得
export function getNameByRecordId(id, Lists) {
  let retValue = '';
  if (id) {
    let info = Lists?.filter((info) => info.id === id);
    if (info?.length > 0) {
      retValue = info[0]?.name;
    }
  }
  return retValue;
}

// 工程種別worktypeと名称リスト
export const kouteiMasterTypes = [
  {
    id: 0,
    workType: 100,
    typeName: '溶接',
  },
  {
    id: 1,
    workType: 200,
    typeName: '溶接仕上',
  },
  {
    id: 2,
    workType: 300,
    typeName: '表面処理',
  },
  {
    id: 3,
    workType: 400,
    typeName: '組立',
  },
  {
    id: 4,
    workType: 500,
    typeName: '検査',
  },
  {
    id: 5,
    workType: 600,
    typeName: '梱包',
  },
  {
    id: 6,
    workType: 700,
    typeName: 'カスタム',
  },
  {
    id: 7,
    workType: 10100,
    typeName: 'プログラム',
  },
  {
    id: 8,
    workType: 10210,
    typeName: 'ブランク',
  },
  {
    id: 9,
    workType: 10220,
    typeName: 'ブランク',
  },
  {
    id: 10,
    workType: 10230,
    typeName: 'ブランク',
  },
  {
    id: 11,
    workType: 10300,
    typeName: '2次加工',
  },
  {
    id: 12,
    workType: 10400,
    typeName: 'ベンディング',
  },
  {
    id: 13,
    workType: 10510,
    typeName: 'バリ取',
  },
  {
    id: 14,
    workType: 10520,
    typeName: 'バリ取',
  },
  {
    id: 15,
    workType: 10600,
    typeName: '溶接',
  },
  {
    id: 16,
    workType: 10700,
    typeName: '溶接仕上',
  },
  {
    id: 17,
    workType: 10800,
    typeName: '表面処理',
  },
  {
    id: 18,
    workType: 10900,
    typeName: 'シャーリング',
  },
  {
    id: 19,
    workType: 11000,
    typeName: '検査',
  },
  {
    id: 20,
    workType: 11100,
    typeName: 'カスタム',
  },
];

// 工程種別グループ名称とdefault row取得
export function getTitleForKey(key, workTypeObj) {
  const titleMap = {
    110: { detailName: '溶接種類' },
    120: { detailName: '取付種類（板厚判断）' },
    130: { detailName: '取付種類（サイズ判断）' },
    310: { detailName: '処理全般種類', defaultRow: 1 },
    320: { detailName: 'メッキ種類', defaultRow: 8 },
    330: { detailName: '塗装種類', defaultRow: 8 },
    10510: { detailName: '処理全般種類', defaultRow: 1 },
    10520: { detailName: 'メッキ種類', defaultRow: 8 },
    10530: { detailName: '塗装種類', defaultRow: 8 },
    10710: { detailName: '溶接種類' },
    10720: { detailName: '取付種類（板厚判断）', defaultRow: 5 },
    10730: { detailName: '取付種類（サイズ判断）', defaultRow: 5 },
    10310: { detailName: '2次加工種類（サイズ判断）', defaultRow: 6 },
    10320: { detailName: '2次加工種類（ミリメートル判断）', defaultRow: 2 },
  };
  let detailType = titleMap[key];

  if (!detailType) {
    //工程種別グループ 0の場合、
    if (key === '0') {
      switch (workTypeObj.workType) {
        case 400:
        case 500:
        case 600:
        case 11000:
          detailType = { detailName: '計算方法' };
          break;
        case 700:
        case 11100:
          detailType = { detailName: '追加工程種類' };
          break;
        case 10400:
          detailType = { detailName: '曲げ種類' };
          break;
        case 10700:
          detailType = { detailName: '溶接仕上種類', defaultRow: 3 };
          break;
        default:
          detailType = { detailName: workTypeObj.typeName + '種類' };
      }
    }
  }
  return detailType;
}
// 加工速度係数の加工種別
export const getProcessTypeName = (typeNo) => {
  let processTypeName = '';
  switch (typeNo) {
    case ProcessType.InternalWork:
      processTypeName = '内加工';
      break;
    case ProcessType.ExternalWork:
      processTypeName = '外加工';
      break;
    default:
      break;
  }
  return processTypeName;
};
// 指定データより、リスト情報を作成
export const getListItemByCustomData = (data) => {
  const list = data?.map((item) => ({ no: item.no, value: 1, isOutOfRange: false }));
  return list;
};

// 板金部品見積の初期値
export const iq3EstimateInitInfo = [
  {
    id: 0,
    created: dayjs().format(),
    modified: dayjs().format(),
    modifierId: 0,
    modifier: '',
    estimateProductId: 0,
    no: 0,
    creationType: EstimateCreationType.Auto,
    imageUrl: '',
    imageId: '',
    drawingNo: '',
    name: '',
    partCount: 1,
    surfaceProtection: 0,
    weight: 0,
    totalSurfaceArea: 0,
    partSizeX: 0,
    partSizeY: 0,
    materialIq3Id: 0,
    materialName: '',
    materialTypeIq3Id: 0,
    materialTypeName: '',
    materialSurfaceIq3Id: 0,
    materialSurfaceName: '',
    thickness: 0,
    grabbingX: 0,
    grabbingY: 0,
    pierWidthX: 0,
    pierWidthY: 0,
    worlRangeX: 0,
    worlRangeY: 0,
    yield: 0,
    materialQuantity: 0,
    weightUnitPrice: 0,
    surfaceAddPrice: 0,
    weightAddPrice: 0,
    scrapPrice: 0,
    costUnitPrice: 0,
    costTotalPrice: 0,
    materialCostUnitPrice: 0,
    materialCostTotalRate: 0,
    materialCostTotalPrice: 0,
    workCostUnitPrice: 0,
    workCostTotalPrice: 0,
    purchaseUnitPrice: 0,
    purchaseTotalPrice: 0,
    surfaceUnitPrice: 0,
    surfaceTotalPrice: 0,
    createrId: 0,
    creater: '',
    processSelect: {
      id: 0,
      name: '',
      details: [],
      modified: dayjs().format(),
    },
    processInput: [],
    additions: [],
    purchases: [],
    materialDetailPatterns: [],
    materialDetailSizes: [],
    calcParameters: {
      settings: {},
      parameters: {},
    },
    info: {
      isUsed: true,
      remarks: '',
      sortNum: 0,
    },
  },
];

// 親部品見積の初期値
export const estimateInitInfo = {
  estimateProducts: {
    id: 0,
    created: dayjs(today).format('YYYY-MM-DD'),
    modified: dayjs(today).format('YYYY-MM-DD'),
    modifierId: 0,
    modifier: '',
    drawingNo: '',
    name: '',
    clientId: 0,
    clientName: '',
    quantity: 1,
    coeffOrdersId: 0,
    coeffOrdersName: '',
    coeffDeliveryDatesId: 0,
    coeffDeliveryDatesName: '',
    coeffPurchasesId: 0,
    coeffPurchasesName: '',
    coeffManagementsId: 0,
    coeffManagementsName: '',
    estimateStatusId: 0,
    estimateStatus: '',
    productType: '',
    totalWeight: 0.0,
    totalSurfaceArea: 0.0,
    createrId: 0,
    creater: '',
    sizeX: 0,
    sizeY: 0,
    sizeZ: 0,
    estimateNo: '',
    comment: '',
    capacity: 0,
    totalWeightParts: 0,
    totalWeightSm: 0,
    totalWeightBm: 0,
    totalWeightMa: 0,
    partTypesParts: 0,
    partTypesSm: 0,
    partTypesBm: 0,
    partTypesMa: 0,
    totalQuantityParts: 0,
    totalQuantitySm: 0,
    totalQuantityBm: 0,
    totalQuantityMa: 0,
    costUnitPrice: 0,
    costTotalPrice: 0,
    estimateUnitPrice: 0,
    estimateTotalPrice: 0,
    grossUnitPrice: 0,
    grossTotalPrice: 0,
    grossRate: 0,
    materialCostUnitPrice: 0,
    materialCostTotalPrice: 0,
    workCostUnitPrice: 0,
    workCostTotalPrice: 0,
    purchaseUnitPrice: 0,
    purchaseTotalPrice: 0,
    manufacturingCostTotalPrice: 0,
    setupCostTotalPrice: 0,
    managementFeeTotalPrice: 0,
    otherProduct: {
      adjustment: 0,
      coeffClient: 0,
      coeffOrders: 0,
      coeffDeliveryDatesId: 0,
      coeffPurchases: 0,
      coeffManagements: 0,
    },
    processSelect: {
      id: 0,
      name: '',
      details: [],
      modified: dayjs().format(),
    },
    processInput: {
      // surfaceProcessInputInfo: processInputSurfaceData,
      surfaceProcessInputInfo: [],
      assenbleProcessInputInfo: processInputKumitateData,
      inspectionProcessInputInfo: processInputKensaData,
      packingProcessInputInfo: processInputKonpouData,
    },
    additions: [],
    purchases: [],
    attachments: {
      id: 0,
      name: '',
      extention: '',
      byte: 0,
      created: '2023-08-31T02:36:11.4145609Z',
      modified: '2023-08-31T02:36:11.4145609Z',
    },
    memo: '',
    byQuantities: [],
    calcParameters: {
      settings: {},
      parameters: {},
    },
    info: {
      isUsed: true,
      remarks: '',
      sortNum: 0,
    },
  },
  esIq3Info: [],
  epCustomCosts: [],
  epCustomDates: [],
  epCustomLabels: [],
  epCustomMemos: [],
};

// 担当者情報を選択ボックス情報にする
export const getStaffList = async () => {
  let staffs = await getStaffInfo();
  const staffLists = [];
  staffs?.forEach((item) => {
    if (item.info.isUsed) {
      staffLists.push({ value: item.id, label: item.name });
    }
  });
  return staffLists;
};

// 表示設定、表示件数リスト
export const pageSizeOptions = [
  { label: 100, value: 1 },
  { label: 200, value: 2 },
  { label: 300, value: 3 },
  { label: 500, value: 4 },
];

// 追加項目・費用の初期値オブジェクト
export const initAdditionalItemCost = (id, code, name) => {
  return {
    additionalItemId: id,
    additionalItemCode: code,
    additionalItemName: name,
    unitPrice: 0,
    isUseUnit: true,
    totalPrice: 0,
  };
};

// 追加項目・ラベルの初期値オブジェクト
export const initAdditionalItemLabel = (id, code, name) => {
  return {
    additionalItemId: id,
    additionalItemCode: code,
    additionalItemName: name,
    label: '',
  };
};

// 追加項目・メモの初期値オブジェクト
export const initAdditionalItemMemo = (id, code, name) => {
  return {
    additionalItemId: id,
    additionalItemCode: code,
    additionalItemName: name,
    memo: '',
  };
};

// 追加項目・日付の初期値オブジェクト
export const initAdditionalItemDate = (id, code, name) => {
  return {
    additionalItemId: id,
    additionalItemCode: code,
    additionalItemName: name,
    date: dayjs(today).format('YYYY-MM-DD'),
  };
};

// 見積もり用、追加項目・費用の初期値オブジェクト
export const initEpCustomCost = (id, code, name) => {
  return {
    id: 0,
    created: dayjs(today).format('YYYY-MM-DD'),
    modified: dayjs(today).format('YYYY-MM-DD'),
    modifier: '',
    isDeleted: false,
    companyId: '',
    estimateProductId: '',
    additionalItemId: id,
    additionalItemCode: code,
    additionalItemName: name,
    unitPrice: 0,
    isUseUnit: false,
    totalPrice: 0,
    info: { isUsed: true, remarks: '', sortNum: 0 },
  };
};

// 見積もり用、追加項目・ラベルの初期値オブジェクト
export const initEpCustomLabel = (id, code, name) => {
  return {
    id: 0,
    created: dayjs(today).format('YYYY-MM-DD'),
    modified: dayjs(today).format('YYYY-MM-DD'),
    modifier: '',
    isDeleted: false,
    companyId: '',
    estimateProductId: '',
    additionalItemId: id,
    additionalItemCode: code,
    additionalItemName: name,
    label: '',
    info: { isUsed: true, remarks: '', sortNum: 0 },
  };
};

// 見積もり用、追加項目・メモの初期値オブジェクト
export const initEpCustomMemo = (id, code, name) => {
  return {
    id: 0,
    created: dayjs(today).format('YYYY-MM-DD'),
    modified: dayjs(today).format('YYYY-MM-DD'),
    modifier: '',
    isDeleted: false,
    companyId: '',
    estimateProductId: '',
    additionalItemId: id,
    additionalItemCode: code,
    additionalItemName: name,
    memo: '',
    info: { isUsed: true, remarks: '', sortNum: 0 },
  };
};

// 見積もり用、追加項目・日付の初期値オブジェクト
export const initEpCustomDate = (id, code, name) => {
  return {
    id: 0,
    created: dayjs(today).format('YYYY-MM-DD'),
    modified: dayjs(today).format('YYYY-MM-DD'),
    modifier: '',
    isDeleted: false,
    companyId: '',
    estimateProductId: '',
    additionalItemId: id,
    additionalItemCode: code,
    additionalItemName: name,
    date: dayjs(today).format('YYYY-MM-DD'),
    info: { isUsed: true, remarks: '', sortNum: 0 },
  };
};

// 見積もり用、工程選択の初期値オブジェクト
export const initProcessSelect = (item) => {
  return {
    id: item.id,
    name: item.name,
    details: item.details?.map((detail) => {
      return {
        processId: detail?.processId,
        deviceId: detail?.deviceId,
        isUsedForCalc: detail?.isUsedForCalc,
        multiprocesses: detail?.multiprocesses?.map((process) => {
          return {
            processDetailGroup: process?.processDetailGroup,
            processDetailTypeId: process?.processDetailTypeId,
            processDetailTypeName: '',
            processNo: process?.processNo,
          };
        }),
      };
    }),
    modified: item.modified,
  };
};

export const productionManageCSVFileHeaders = [
  {
    label: '見積No',
    key: 'estimateNo',
  },
  {
    label: '見積日',
    key: 'created',
  },
  {
    label: '得意先コード',
    key: 'clientId',
  },
  {
    label: '得意先枝番',
    key: 'customerBranchNo',
  },
  {
    label: '得意先',
    key: 'clientName',
  },
  {
    label: '納期コード',
    key: 'deliveryCode', // 納期係数のコード
  },
  {
    label: '希望納期',
    key: 'deliveryName', // 納期係数の名称
  },
  {
    label: '自社担当者ｺｰﾄﾞ',
    key: 'creater',
  },
  {
    label: '自社担当者',
    key: 'modifier',
  },
  {
    label: '見積合計金額',
    key: 'estimateTotalPrice',
  },
  {
    label: '合計原価',
    key: 'costTotalPrice',
  },
  {
    label: '合計粗利',
    key: 'grossTotalPrice',
  },
  {
    label: '合計購入品費',
    key: 'purchaseTotalPrice',
  },
  {
    label: '単品フラグ',
    key: 'singleItemFlag',
  },
  {
    label: '枝番',
    key: 'branchNo',
  },
  {
    label: '品番',
    key: 'drawingNo',
  },
  {
    label: '品名',
    key: 'name',
  },
  {
    label: '形式',
    key: 'format',
  },
  {
    label: '材質コード',
    key: 'productCode',
  },
  {
    label: '材質',
    key: 'productName',
  },
  {
    label: '表面保護',
    key: 'surfaceProtectionName',
  },
  {
    label: '板厚',
    key: 'thickness',
  },
  {
    label: '材料名',
    key: 'materialName',
  },
  {
    label: '合計数量',
    key: 'quantity',
  },
  {
    label: '材料単価',
    key: 'materialCostUnitPrice',
  },
  {
    label: '工順',
    key: 'route',
  },
  {
    label: '工程ｺｰﾄﾞ',
    key: 'processCode',
  },
  {
    label: '工程名称',
    key: 'processName',
  },
  {
    label: '加工種類コード',
    key: 'workTypeGroupCode',
  },
  {
    label: '加工種類',
    key: 'workTypeGroupName',
  },
  {
    label: '単段取工数',
    key: 'singlePrepTime',
  },
  {
    label: '段取工数',
    key: 'prepTime',
  },
  {
    label: '単加工時間',
    key: 'singleWorkTime',
  },
  {
    label: '加工時間',
    key: 'workTime',
  },
  {
    label: '加工工数',
    key: 'totalWorkTime',
  },
  {
    label: '合計金額',
    key: 'totalCost',
  },
  {
    label: 'カスタム区分',
    key: 'ep_custom',
  },
  {
    label: 'カスタムコード',
    key: 'customCode',
  },
  {
    label: 'カスタム名称',
    key: 'customName',
  },
  {
    label: 'カスタムテキスト',
    key: 'customText',
  },
  {
    label: 'カスタム日付',
    key: 'customDate',
  },
  {
    label: '合計費用',
    key: 'totalCostAmount',
  },
];

// 先頭に０を埋める ####0
export function padWithLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

// 再計算
export function updateEstimateInfoByReCal(selectedDataDetail, isEstimateOpen) {
  let updateKouteiCalInfo = [];
  let estimateProducts = selectedDataDetail.estimateProducts;

  if (
    estimateProducts === undefined ||
    estimateProducts?.calcParameters === undefined ||
    isEstimateOpen === undefined || // IQX_WEBEST-305 過去データを開いた直後、再計算をしない限りはタブ切替しても計算しないでほしい。
    isEstimateOpen === true
  ) {
    return selectedDataDetail;
  }

  //パラメータ情報再設定
  let orderCoeffs = [];
  let deliDateCoeffs = [];
  let purCoeffs = [];
  let mngCoeffs = [];
  let costLst = [];
  let labelLst = [];
  let memoLst = [];
  let dateLst = [];
  let purchasesData = [];
  let parameters = estimateProducts?.calcParameters?.parameters;
  let settings = estimateProducts?.calcParameters?.settings;
  if (parameters) {
    let client =
      parameters.client?.length > 0 ? parameters.client?.filter((item) => item.id == estimateProducts.clientId) : [];
    let companyInfo = parameters.company;
    let processInfo = parameters.process;
    let deviceMasterInfo = parameters.device;
    let processPattern = parameters.processPattern;
    let estimateStatus =
      parameters.estimateStatus?.length > 0
        ? parameters?.estimateStatus?.filter((item) => item.id == estimateProducts?.estimateStatusId)
        : [];
    let purchaseAllocation = parameters?.purchaseAllocation;
    let purchaseCategoryInfo = parameters?.purchaseCategory;
    purchasesData = parameters?.purchase;
    let staffList = parameters?.staff;
    let createrInfo = parameters?.staff?.filter((i) => i.id === estimateProducts.createrId);
    let modifierInfo = parameters?.staff?.filter((i) => i.id === estimateProducts.modifierId);
    let userSettingData = settings?.userSetting;
    let companySettingData = settings?.companySetting;

    if (companyInfo?.length > 0) {
      orderCoeffs = companyInfo?.[0]?.coeffOrders?.filter((i) => i.id === estimateProducts.coeffOrdersId);

      deliDateCoeffs = companyInfo?.[0]?.coeffDeliveryDates?.filter(
        (i) => i.id === estimateProducts.coeffDeliveryDatesId
      );
      purCoeffs = companyInfo?.[0]?.coeffPurchases?.filter((i) => i.id === estimateProducts.coeffPurchasesId);
      mngCoeffs = companyInfo?.[0]?.coeffManagements?.filter((i) => i.id === estimateProducts.coeffManagementsId);

      costLst = companyInfo[0]?.additionalItemCosts
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          let initItem = initEpCustomCost(item.id, item.code, item.name);
          initItem.companyId = estimateProducts.companyId;
          initItem.estimateProductId = estimateProducts.id;
          initItem.modified = item.modified;
          initItem.info = item.info;
          return initItem;
        });

      labelLst = companyInfo[0]?.additionalItemLabels
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          let initItem = initEpCustomLabel(item.id, item.code, item.name);
          initItem.companyId = estimateProducts.companyId;
          initItem.estimateProductId = estimateProducts.id;
          initItem.modified = item.modified;
          initItem.info = item.info;
          return initItem;
        });

      memoLst = companyInfo[0]?.additionalItemMemos
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          let initItem = initEpCustomMemo(item.id, item.code, item.name);
          initItem.companyId = estimateProducts.companyId;
          initItem.estimateProductId = estimateProducts.id;
          initItem.modified = item.modified;
          initItem.info = item.info;
          return initItem;
        });

      dateLst = companyInfo[0]?.additionalItemDates
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          let initItem = initEpCustomDate(item.id, item.code, item.name);
          initItem.companyId = estimateProducts.companyId;
          initItem.estimateProductId = estimateProducts.id;
          initItem.modified = item.modified;
          initItem.info = item.info;
          return initItem;
        });
    }
    estimateProducts.createrId = createrInfo?.length > 0 ? createrInfo?.[0]?.id : staffList?.[0]?.id;
    estimateProducts.creater = createrInfo?.length > 0 ? createrInfo?.[0]?.name : staffList?.[0]?.name;
    estimateProducts.modifierId = modifierInfo?.length > 0 ? modifierInfo?.[0]?.id : staffList?.[0]?.id;
    // userSettingData?.length > 0
    //   ? userSettingData?.[0]?.users?.staffId
    //   : staffList?.length > 0
    //   ? staffList?.[0]?.id
    //   : '';
    estimateProducts.modifier = modifierInfo?.length > 0 ? modifierInfo?.[0]?.name : staffList?.[0]?.name;
    // userSettingData?.length > 0
    //   ? userSettingData?.[0]?.users?.nickName
    //   : staffList?.length > 0
    //   ? staffList?.[0]?.name
    //   : '';
    estimateProducts.clientId = client ? client?.[0]?.id : estimateProducts.clientId;
    estimateProducts.clientName = client ? client?.[0]?.name : estimateProducts.clientName;
    estimateProducts.clientCode = client ? client?.[0]?.code : estimateProducts.clientCode;
    estimateProducts.coeffOrdersId = orderCoeffs?.length > 0 ? orderCoeffs?.[0]?.id : undefined;
    // estimateProducts.coeffOrdersId = orderCoeffs ? orderCoeffs.id :
    estimateProducts.coeffOrdersName = orderCoeffs?.length > 0 ? orderCoeffs?.[0]?.name : '';
    estimateProducts.coeffDeliveryDatesId = deliDateCoeffs?.length > 0 ? deliDateCoeffs?.[0]?.id : undefined;
    estimateProducts.coeffDeliveryDatesName = deliDateCoeffs?.length > 0 ? deliDateCoeffs?.[0]?.name : '';
    estimateProducts.coeffPurchasesId = purCoeffs?.length > 0 ? purCoeffs?.[0]?.id : undefined;
    estimateProducts.coeffPurchasesName = purCoeffs?.length > 0 ? purCoeffs?.[0]?.name : '';
    estimateProducts.coeffManagementsId = mngCoeffs?.length > 0 ? mngCoeffs?.[0]?.id : undefined;
    estimateProducts.coeffManagementsName = mngCoeffs?.length > 0 ? mngCoeffs?.[0]?.name : '';
    // estimateProducts.createrId =
    //   userSettingData?.length > 0
    //     ? userSettingData?.[0]?.users?.staffId
    //     : staffList?.length > 0
    //     ? staffList?.[0]?.id
    //     : '';
    // estimateProducts.creater =
    //   userSettingData?.length > 0
    //     ? userSettingData?.[0]?.users?.nickName
    //     : staffList?.length > 0
    //     ? staffList?.[0]?.name
    //     : '';
    estimateProducts.estimateStatusId = estimateStatus?.length > 0 ? estimateStatus?.[0]?.id : '';
    estimateProducts.estimateStatus = estimateStatus?.length > 0 ? estimateStatus?.[0]?.name : '';
    estimateProducts.otherProduct.coeffClient = client
      ? toDecimal(client?.[0]?.coeffClients?.find((item) => item.clientType === ClientType.Customer)?.coeff, 2)
      : toDecimal(0, 2);
    estimateProducts.otherProduct.coeffOrders =
      orderCoeffs?.length > 0 ? toDecimal(orderCoeffs?.[0]?.coeff, 2) : toDecimal(0, 2);
    estimateProducts.otherProduct.coeffDeliveryDates =
      deliDateCoeffs?.length > 0 ? toDecimal(deliDateCoeffs?.[0]?.coeff, 2) : toDecimal(0, 2);
    estimateProducts.otherProduct.coeffPurchases =
      purCoeffs?.length > 0 ? toDecimal(purCoeffs?.[0]?.coeff, 2) : toDecimal(0, 2);
    estimateProducts.otherProduct.coeffManagements =
      mngCoeffs?.length > 0 ? toDecimal(mngCoeffs?.[0]?.coeff, 2) : toDecimal(0, 2);

    //追加項目情報再設定
    let updateCosts = [];
    for (let cost = 0; cost < costLst?.length; cost++) {
      let updateCostFilter = selectedDataDetail?.epCustomCosts?.filter(
        (item) => item.additionalItemId == costLst[cost]?.additionalItemId
      );
      let updateCost = costLst[cost];
      if (updateCostFilter?.length > 0) {
        updateCostFilter[0].additionalItemCode = updateCost.additionalItemCode;
        updateCostFilter[0].additionalItemName = updateCost.additionalItemName;
        updateCostFilter[0].modified = updateCost.modified;
        updateCostFilter[0].info = updateCost.info;
        updateCost = updateCostFilter[0];
      }
      updateCosts.push(updateCost);
    }
    selectedDataDetail.epCustomCosts = updateCosts;

    let updateDates = [];
    for (let date = 0; date < dateLst?.length; date++) {
      let updateDateFilter = selectedDataDetail?.epCustomDates?.filter(
        (item) => item.additionalItemId == dateLst[date]?.additionalItemId
      );
      let updateDate = dateLst[date];
      if (updateDateFilter?.length > 0) {
        updateDateFilter[0].additionalItemCode = updateDate.additionalItemCode;
        updateDateFilter[0].additionalItemName = updateDate.additionalItemName;
        updateDateFilter[0].modified = updateDate.modified;
        updateDateFilter[0].info = updateDate.info;
        updateDate = updateDateFilter[0];
      }
      updateDates.push(updateDate);
    }
    selectedDataDetail.epCustomDates = updateDates;

    let updateLabels = [];
    for (let label = 0; label < labelLst?.length; label++) {
      let updateLabelFilter = selectedDataDetail?.epCustomLabels?.filter(
        (item) => item.additionalItemId == labelLst[label]?.additionalItemId
      );
      let updateLabel = labelLst[label];
      if (updateLabelFilter?.length > 0) {
        updateLabelFilter[0].additionalItemCode = updateLabel.additionalItemCode;
        updateLabelFilter[0].additionalItemName = updateLabel.additionalItemName;
        updateLabelFilter[0].modified = updateLabel.modified;
        updateLabelFilter[0].info = updateLabel.info;
        updateLabel = updateLabelFilter[0];
      }
      updateLabels.push(updateLabel);
    }
    selectedDataDetail.epCustomLabels = updateLabels;

    let updateMemos = [];
    for (let memo = 0; memo < memoLst?.length; memo++) {
      let updateMemoFilter = selectedDataDetail?.epCustomMemos?.filter(
        (item) => item.additionalItemId == memoLst[memo]?.additionalItemId
      );
      let updateMemo = memoLst[memo];
      if (updateMemoFilter?.length > 0) {
        updateMemoFilter[0].additionalItemCode = updateMemo.additionalItemCode;
        updateMemoFilter[0].additionalItemName = updateMemo.additionalItemName;
        updateMemoFilter[0].modified = updateMemo.modified;
        updateMemoFilter[0].info = updateMemo.info;
        updateMemo = updateMemoFilter[0];
      }
      updateMemos.push(updateMemo);
    }
    selectedDataDetail.epCustomMemos = updateMemos;
  }
  //再計算
  // 数量
  let quantity = selectedDataDetail?.estimateProducts?.quantity
    ? Number(selectedDataDetail?.estimateProducts?.quantity)
    : 1;
  let device = estimateProducts?.calcParameters?.parameters?.device;
  //板金子部品の工程積算情報
  let esIq3Infos = selectedDataDetail?.esIq3Info;
  let iq3Weight = 0;
  let iq3Area = 0;
  let setupCostUnitPrice = 0;
  estimateProducts.workCostUnitPrice = 0;
  estimateProducts.setupCostTotalPrice = 0;
  estimateProducts.workCostTotalPrice = 0;
  estimateProducts.materialCostUnitPrice = 0;
  estimateProducts.materialCostTotalPrice = 0;
  estimateProducts.purchaseUnitPrice = 0;
  estimateProducts.purchaseTotalPrice = 0;
  let iq3PurUnitPrice = 0;
  let iq3PurTotalPrice = 0;
  let iq3ManufacturingCostTotalPrice = 0;
  let iq3EstimateMaterialTotalPrices = 0;
  let iq3SetupCostPrice_total = 0;
  let iq3WorkCostPrice_total = 0;
  for (let s = 0; s < esIq3Infos?.length; s++) {
    // 材料、材質、材料表面保護、材料サイズパラメータ情報
    let materialIq3 = estimateProducts?.calcParameters?.parameters?.materialIq3;
    let materialTypeIq3 = estimateProducts?.calcParameters?.parameters?.materialTypeIq3;
    let materialSizeIq3 = estimateProducts?.calcParameters?.parameters?.materialSizeIq3;
    let materialSurfaceIq3 = estimateProducts?.calcParameters?.parameters?.materialSurfaceIq3;

    // 材料情報を再設定
    let materialIq3Info = materialIq3?.find((item) => item.id === esIq3Infos[s]?.materialIq3Id && item?.info?.isUsed);
    esIq3Infos[s].materialIq3Id = materialIq3Info ? esIq3Infos[s]?.materialIq3Id : materialIq3?.[0]?.id;
    esIq3Infos[s].materialName = materialIq3Info ? esIq3Infos[s]?.materialName : materialIq3?.[0]?.name;

    materialIq3Info = materialIq3?.find((item) => item.id === esIq3Infos[s]?.materialIq3Id);

    // 材質情報を再設定
    let materialTypeIq3Info = materialTypeIq3?.find(
      (item) =>
        item.id === esIq3Infos[s]?.materialTypeIq3Id &&
        item.id === materialIq3Info?.materialTypeIq3Id &&
        item?.info?.isUsed
    );
    let initMaterialTypeIq3Info = materialTypeIq3?.find(
      (item) => item.id === materialIq3Info?.materialTypeIq3Id && item?.info?.isUsed
    );
    esIq3Infos[s].materialTypeIq3Id = materialTypeIq3Info
      ? esIq3Infos[s]?.materialTypeIq3Id
      : initMaterialTypeIq3Info?.id;
    esIq3Infos[s].materialTypeName = materialTypeIq3Info
      ? esIq3Infos[s]?.materialTypeName
      : initMaterialTypeIq3Info?.name;

    // 材料表面保護情報を再設定
    let materialSurfaceIq3Info = materialSurfaceIq3?.find(
      (item) =>
        item.id === esIq3Infos[s]?.materialSurfaceIq3Id &&
        item.materialTypeIq3Id === esIq3Infos[s].materialTypeIq3Id &&
        item?.info?.isUsed
    );
    let initMaterialSurfaceIq3Info = materialSurfaceIq3?.find(
      (item) => item.materialTypeIq3Id === esIq3Infos[s].materialTypeIq3Id && item?.info?.isUsed
    );

    esIq3Infos[s].materialSurfaceIq3Id = materialSurfaceIq3Info
      ? esIq3Infos[s]?.materialSurfaceIq3Id
      : initMaterialSurfaceIq3Info?.id;
    esIq3Infos[s].materialSurfaceName = materialSurfaceIq3Info
      ? esIq3Infos[s]?.materialSurfaceName
      : initMaterialSurfaceIq3Info?.name;

    // 板厚情報を再設定
    esIq3Infos[s].thickness = esIq3Infos[s].materialIq3Id;

    // 材料サイズを再設定
    esIq3Infos[s].materialDetailSizes?.map((item) => {
      if (item.sizeName !== 'Free' && item.sizeName !== 'Other') {
        let materialSizeInfo = materialSizeIq3?.find((mst) => mst.id === item?.materialSizeIq3Id && mst.info.isUsed);
        if (materialSizeInfo) {
          // マスタの情報を材料詳細のサイズに更新
          item.sizeName = materialSizeInfo.name;
          esIq3Infos[s].materialDetailPatterns
            ?.filter((pattern) => pattern?.materialSizeIq3Id === materialSizeInfo?.id)
            ?.map((pattern) => {
              pattern.sizeName = materialSizeInfo.name;
              pattern.sizeX = materialSizeInfo.info.sizeX;
              pattern.sizeY = materialSizeInfo.info.sizeY;
            });
        }
      }
    });

    // マスタに存在するサイズ情報のみを利用
    esIq3Infos[s].materialDetailSizes = esIq3Infos[s].materialDetailSizes?.filter(
      (item) =>
        item.sizeName === 'Free' ||
        materialSizeIq3.findIndex((mst) => mst.id == item.materialSizeIq3Id && mst.info.isUsed) > -1
    );

    // マスタに追加した情報を追加
    materialSizeIq3
      ?.filter(
        (mst) =>
          mst.name !== 'Free' &&
          mst.info.isUsed &&
          mst?.materialTypeIq3Id?.findIndex((id) => id === esIq3Infos[s]?.materialTypeIq3Id) > -1 &&
          esIq3Infos[s].materialDetailSizes.findIndex((item) => mst.id == item.materialSizeIq3Id) === -1
      )
      ?.map((item, index) => {
        let data = getInitMaterialSizeInfo(index, item);
        esIq3Infos[s].materialDetailSizes.push(data);
        esIq3Infos[s].materialDetailPatterns = getInitMaterialPatternsInfo(esIq3Infos[s].materialDetailPatterns, item);
      });

    //工程積算計算
    let iq3SetupCostTotalPrice = 0;
    let iq3WorkCostTotalPrice = 0;
    let iq3PurchaseUnitPrice = 0;
    let iq3PurchaseTotalPrice = 0;
    // let iq3ProductionQuantityCoeff = 1; // IQX_WEBEST-278
    // let iq3SetupCoeff = 1; // IQX_WEBEST-278
    selectedDataDetail.esIq3Info[s].surfaceUnitPrice = 0;
    selectedDataDetail.esIq3Info[s].surfaceTotalPrice = 0;
    let materialCoeff = 0;

    let partCnt = Number(esIq3Infos[s]?.partCount); // 員数
    let manufacturingCnt = quantity * partCnt; // 製造数 * 員数
    manufacturingCnt = manufacturingCnt ? manufacturingCnt : 1;

    // IQX_WEBEST-315 板厚を変更しても重量が更新されない
    const thickId = esIq3Infos[s].materialIq3Id;
    const thicknessInfo = materialIq3?.find((item) => item.id === thickId);
    let thicknessVal = thicknessInfo?.info?.thick;
    let gravityVal = thicknessInfo?.info?.gravity;
    let xsize = esIq3Infos[s]?.partSizeX ? toDecimal(esIq3Infos[s]?.partSizeX, 2) : 0;
    let ysize = esIq3Infos[s]?.partSizeY ? toDecimal(esIq3Infos[s]?.partSizeY, 2) : 0;
    let weightVal = ((thicknessVal * parseInt(xsize) * parseInt(ysize)) / 1000000) * gravityVal;
    let areaVal = (toDecimal(xsize, 2) * toDecimal(ysize, 2)) / 100;
    esIq3Infos[s].weight = weightVal ? weightVal : esIq3Infos[s].weight;
    esIq3Infos[s].totalSurfaceArea = areaVal ? areaVal : esIq3Infos[s].totalSurfaceArea;

    iq3Weight += Number(esIq3Infos[s]?.weight) * partCnt; // IQX_WEBEST-308 親工程 －表面処置、工程入力画面の「面積/重量」の値変更
    iq3Area += Number(esIq3Infos[s]?.totalSurfaceArea) * partCnt; // IQX_WEBEST-308 親工程 －表面処置、工程入力画面の「面積/重量」の値変更

    let processPatterns = estimateProducts?.calcParameters?.parameters?.processPattern;
    let processSelect = processPatterns?.filter((item) => item.id === esIq3Infos[s]?.processSelect?.id);
    if (processSelect?.length > 0) esIq3Infos[s].processSelect = initProcessSelect(processSelect[0]);

    let iq3ProcessPattern = esIq3Infos[s]?.processSelect;
    //各子部品の工程積算合計計算
    let iq3UpdateKouteiCalInfo = esIq3Infos[s]?.additions;
    if (iq3UpdateKouteiCalInfo) {
      // 計算した工程データの足し算
      iq3UpdateKouteiCalInfo?.forEach((iq3UpdatedKouteiContent) => {
        let deviceMstFilter = [];
        //利用する工程パターンを取得
        let patternInfoFilter = [];
        let curPatternInfoFilter = iq3ProcessPattern
          ? iq3ProcessPattern?.details?.filter(
              (item) => item.processId == iq3UpdatedKouteiContent?.processId && item.isUsedForCalc
            )
          : [];
        if (curPatternInfoFilter && curPatternInfoFilter?.length > 0) {
          patternInfoFilter = curPatternInfoFilter[0];
          //複数処理（追加工程）の場合、工程IDで判断できないため、工程詳細IDで判断して対象データを取得
          if (curPatternInfoFilter?.length > 1) {
            let patternInfoFilterByTypeId = curPatternInfoFilter?.filter(
              (item) =>
                item.multiprocesses?.[0]?.processDetailTypeId ===
                iq3UpdatedKouteiContent?.dataItems?.[0]?.processDetailTypeNo
            );
            if (patternInfoFilterByTypeId && patternInfoFilterByTypeId?.length > 0) {
              patternInfoFilter = patternInfoFilterByTypeId[0];
            }
          }
          deviceMstFilter = device
            ? device?.filter(
                (item) => patternInfoFilter?.processId == item.processId && patternInfoFilter?.deviceId == item.id
              )
            : [];
          //　現在の工程タイプ取得
          let curWorkType = parameters?.process?.filter((item) => item.id == iq3UpdatedKouteiContent?.processId)?.[0]
            ?.workType;
          //　段取金額
          let iq3SetupCostPrice =
            iq3UpdatedKouteiContent.dataType == DataType.Data
              ? iq3UpdatedKouteiContent.totalDandori.dataItemsAmt
              : iq3UpdatedKouteiContent.totalDandori.editItemsAmt;
          iq3SetupCostPrice = iq3SetupCostPrice ? iq3SetupCostPrice : 0; // NaNチェック
          iq3SetupCostTotalPrice += iq3SetupCostPrice;

          //　作業/加工金額
          let iq3WorkCostPrice =
            iq3UpdatedKouteiContent.dataType == DataType.Data
              ? iq3UpdatedKouteiContent.totalSagyo.dataItemsAmt
              : iq3UpdatedKouteiContent.totalSagyo.editItemsAmt;
          // 作業/加工金額
          iq3WorkCostPrice = iq3WorkCostPrice ? iq3WorkCostPrice : 0; // NaNチェック

          // プログラム工程の場合、プログラム工程の原価は子部品の員数、全体の数量に影響されないため、分けて計算
          if (curWorkType == WorkType.SmProgram) {
            //　プログラム工程の合計の場合、そのまま、製造数と掛けない
            iq3WorkCostTotalPrice += iq3WorkCostPrice;
          } else if (curWorkType == WorkType.SmShearing) {
            // シャーリングOne by one項目チェックを取得
            let oneByoneCheck = deviceMstFilter[0]?.details?.isOneCut;
            if (oneByoneCheck === false) {
              //　One by one項目チェックがOFFの場合、そのまま、製造数と掛けない
              iq3WorkCostTotalPrice += iq3WorkCostPrice;
            } else {
              //　One by one項目チェックがONの場合、製造数と掛ける
              iq3WorkCostTotalPrice += iq3WorkCostPrice * manufacturingCnt;
            }
          } else {
            // 加工時間×員数
            iq3WorkCostTotalPrice += iq3WorkCostPrice * manufacturingCnt;
          }
          //板金の基本情報に表面処理費表示のため設定
          if (curWorkType == WorkType.SmSurface) {
            if (iq3SetupCostPrice !== undefined && iq3WorkCostPrice !== undefined) {
              // (段取時間＋(加工時間×製造数))
              selectedDataDetail.esIq3Info[s].surfaceTotalPrice =
                iq3SetupCostPrice + iq3WorkCostPrice * manufacturingCnt;
              // (合計÷製造数)
              selectedDataDetail.esIq3Info[s].surfaceUnitPrice = Number.isFinite(
                selectedDataDetail.esIq3Info[s].surfaceTotalPrice / manufacturingCnt
              )
                ? selectedDataDetail.esIq3Info[s].surfaceTotalPrice / manufacturingCnt
                : 0;
            }
          }
        }
      });
      //板金原価
      if (iq3SetupCostTotalPrice !== undefined && iq3WorkCostTotalPrice !== undefined) {
        //　合計原価（段取金額＋（加工金額＊員数））
        selectedDataDetail.esIq3Info[s].workCostTotalPrice = iq3SetupCostTotalPrice + iq3WorkCostTotalPrice;
        iq3SetupCostPrice_total += iq3SetupCostTotalPrice;
        iq3WorkCostPrice_total += iq3WorkCostTotalPrice;
        //　単品原価（合計原価 ÷ 製造数　)
        selectedDataDetail.esIq3Info[s].workCostUnitPrice = Number.isFinite(
          selectedDataDetail.esIq3Info[s].workCostTotalPrice / manufacturingCnt
        )
          ? selectedDataDetail.esIq3Info[s].workCostTotalPrice / manufacturingCnt
          : 0;
      }
    }

    // 親部品材料原価更新
    estimateProducts.materialCostUnitPrice += selectedDataDetail?.esIq3Info[s]?.materialCostUnitPrice;
    estimateProducts.materialCostTotalPrice += selectedDataDetail?.esIq3Info[s]?.materialCostTotalPrice;
    // 材料粗利係数
    let curMaterialTypeIq3 = parameters?.materialTypeIq3?.filter(
      (item) => item.id === esIq3Infos[s]?.materialTypeIq3Id
    );
    if (curMaterialTypeIq3?.length > 0) {
      materialCoeff = curMaterialTypeIq3?.[0]?.info.lossCoeff;
      materialCoeff = materialCoeff ? materialCoeff : 1;
    }
    // 各板金の材料原価更新
    let iq3EstimateMaterialTotalPrice = selectedDataDetail?.esIq3Info[s]?.materialCostTotalPrice * materialCoeff;
    iq3EstimateMaterialTotalPrices += iq3EstimateMaterialTotalPrice;

    // 板金の購入品合計
    let iq3Purchase = esIq3Infos[s]?.purchases;
    for (let p = 0; p < iq3Purchase?.length; p++) {
      if (iq3Purchase[p]?.isDeleted === false) {
        // IQX_WEBEST-388 親部品リスト、一括編集ボタンからのcurt.パラ更新で、購入品の情報が最新情報とならない
        const filteredData = purchasesData?.filter(
          (masterItem) => masterItem.id === selectedDataDetail?.esIq3Info[s]?.purchases[p]?.purchaseMasterId
        );
        let getPriceLots = [];
        let unitPriceByQty = 0;
        if (filteredData?.length > 0) {
          getPriceLots = filteredData[0].priceLots;
          unitPriceByQty = getPriceForUpperThVal(
            selectedDataDetail?.esIq3Info[s]?.purchases[p].required * quantity,
            getPriceLots
          );
          selectedDataDetail.esIq3Info[s].purchases[p].name = filteredData[0]?.name;
          selectedDataDetail.esIq3Info[s].purchases[p].model = filteredData[0]?.info?.model;
          selectedDataDetail.esIq3Info[s].purchases[p].purchaseCategoryId = filteredData[0]?.purchaseCategoryId;
          selectedDataDetail.esIq3Info[s].purchases[p].processTime = filteredData[0]?.info?.processTime;
          selectedDataDetail.esIq3Info[s].purchases[p].supplierCode = filteredData[0]?.info?.supplierCode;
          selectedDataDetail.esIq3Info[s].purchases[p].supplierName = filteredData[0]?.info?.supplierName;
          selectedDataDetail.esIq3Info[s].purchases[p].makerName = filteredData[0]?.info?.makerName;
          selectedDataDetail.esIq3Info[s].purchases[p].remarks = filteredData[0]?.info?.remarks;
          selectedDataDetail.esIq3Info[s].purchases[p].totalProcessTime = filteredData[0]?.info?.processTime * quantity;
        } else {
          unitPriceByQty = 0;
        }
        selectedDataDetail.esIq3Info[s].purchases[p].unitPriceByQuantity = unitPriceByQty;
        selectedDataDetail.esIq3Info[s].purchases[p].price =
          unitPriceByQty > 0
            ? selectedDataDetail?.esIq3Info[s]?.purchases[p].isKeepPrice
              ? unitPriceByQty * selectedDataDetail?.esIq3Info[s]?.purchases[p].required
              : unitPriceByQty
            : selectedDataDetail?.esIq3Info[s]?.purchases[p].isKeepPrice
            ? selectedDataDetail?.esIq3Info[s]?.purchases[p].unitPrice *
              selectedDataDetail?.esIq3Info[s]?.purchases[p].required
            : selectedDataDetail?.esIq3Info[s]?.purchases[p].unitPrice; // IQX_WEBEST-290 単価保持のチェックがオフの場合、金額＝単価,
        selectedDataDetail.esIq3Info[s].purchases[p].totalPrice =
          unitPriceByQty > 0
            ? selectedDataDetail?.esIq3Info[s]?.purchases[p].isKeepPrice
              ? unitPriceByQty * selectedDataDetail?.esIq3Info[s]?.purchases[p].required * partCnt * quantity
              : unitPriceByQty
            : selectedDataDetail?.esIq3Info[s]?.purchases[p].isKeepPrice
            ? selectedDataDetail?.esIq3Info[s]?.purchases[p].unitPrice *
              selectedDataDetail?.esIq3Info[s]?.purchases[p].required *
              partCnt *
              quantity
            : selectedDataDetail?.esIq3Info[s]?.purchases[p].unitPrice; // IQX_WEBEST-231 単価保持のチェックがオフの場合、合計金額＝単価

        iq3PurUnitPrice += selectedDataDetail?.esIq3Info[s]?.purchases[p]?.price;
        iq3PurTotalPrice += selectedDataDetail?.esIq3Info[s]?.purchases[p]?.totalPrice; //単価保持
        // iq3PurTotalPrice += iq3Purchase[p]?.isKeepPrice === true ? iq3Purchase[p]?.price : iq3Purchase[p]?.totalPrice;
        // 各板金の購入品合計
        // iq3PurchaseUnitPrice += iq3Purchase[p]?.price;
        iq3PurchaseUnitPrice += selectedDataDetail?.esIq3Info[s]?.purchases[p]?.price;
        // iq3Purchase[p].totalPrice = iq3Purchase[p]?.price * partCnt;
        // iq3PurchaseTotalPrice += iq3Purchase[p]?.totalPrice;
        iq3PurchaseTotalPrice += selectedDataDetail?.esIq3Info[s]?.purchases[p]?.totalPrice;
      }
    }
    iq3PurUnitPrice = iq3PurUnitPrice ? iq3PurUnitPrice * partCnt : 0;
    // 各板金の購入品費
    selectedDataDetail.esIq3Info[s].purchaseUnitPrice = iq3PurchaseUnitPrice ? iq3PurchaseUnitPrice : 0;
    selectedDataDetail.esIq3Info[s].purchaseTotalPrice = iq3PurchaseTotalPrice ? iq3PurchaseTotalPrice : 0;

    //板金の合計原価計算(材料原価＋製造原価＋購入品費)
    if (
      selectedDataDetail.esIq3Info[s]?.materialCostTotalPrice !== undefined &&
      selectedDataDetail.esIq3Info[s]?.workCostTotalPrice !== undefined &&
      selectedDataDetail.esIq3Info[s]?.purchaseTotalPrice !== undefined
    ) {
      selectedDataDetail.esIq3Info[s].costTotalPrice =
        selectedDataDetail.esIq3Info[s]?.materialCostTotalPrice +
        selectedDataDetail.esIq3Info[s]?.workCostTotalPrice +
        selectedDataDetail.esIq3Info[s]?.purchaseTotalPrice;
      // 各板金の合計原価
      iq3ManufacturingCostTotalPrice += selectedDataDetail.esIq3Info[s]?.workCostTotalPrice;
      // 板金金額NaNチェック(プログラム工程とシャーリング工程以外)
      iq3ManufacturingCostTotalPrice = iq3ManufacturingCostTotalPrice ? iq3ManufacturingCostTotalPrice : 0;
    }

    //板金の単品原価計算(板金の合計原価 ÷ 製造数)
    if (selectedDataDetail.esIq3Info[s].costTotalPrice !== undefined) {
      selectedDataDetail.esIq3Info[s].costUnitPrice = selectedDataDetail.esIq3Info[s].costTotalPrice / manufacturingCnt;
    }
  }

  //親部品の工程積算情報
  updateKouteiCalInfo = estimateProducts?.additions;
  if (updateKouteiCalInfo) {
    // 製造原価計算(段取金額＊段取個数係数+作業/加工金額*生産個数係数)
    let processPattern = estimateProducts?.processSelect;
    // 親部品の計算した工程データの足し算
    updateKouteiCalInfo?.forEach((updatedKouteiContent) => {
      let curParPatternInfoFilter = processPattern
        ? processPattern?.details?.filter(
            (item) => item.processId === updatedKouteiContent.processId && item.isUsedForCalc
          )
        : [];
      if (curParPatternInfoFilter && curParPatternInfoFilter?.length > 0) {
        // 段取原価合計金額計算
        // 段取金額（各工程計算で段取個数係数を参照して計算したため、ここに段取係数を再計算しない。）
        let setupCostPrice =
          updatedKouteiContent.dataType == DataType.Data
            ? updatedKouteiContent.totalDandori.dataItemsAmt
            : updatedKouteiContent.totalDandori.editItemsAmt;
        setupCostPrice = setupCostPrice ? setupCostPrice : 0;
        setupCostUnitPrice += setupCostPrice;

        // 加工原価合計金額計算
        // 作業/加工金額*生産個数係数
        let workCostPrice =
          updatedKouteiContent.dataType == DataType.Data
            ? updatedKouteiContent.totalSagyo.dataItemsAmt
            : updatedKouteiContent.totalSagyo.editItemsAmt;
        workCostPrice = workCostPrice ? workCostPrice : 0;
        estimateProducts.workCostUnitPrice += workCostPrice;
      }
    });
  }

  // 親部品の購入品合計
  let parentPurchase = estimateProducts?.purchases;
  let parPurUnitPrice = 0;
  let parPurTotalPrice = 0;
  for (let p = 0; p < parentPurchase?.length; p++) {
    if (parentPurchase[p]?.isDeleted === false) {
      // IQX_WEBEST-388 親部品リスト、一括編集ボタンからのcurt.パラ更新で、購入品の情報が最新情報とならない
      const filteredData = purchasesData?.filter((masterItem) => masterItem.id === parentPurchase[p]?.purchaseMasterId);
      let getPriceLots = [];
      let unitPriceByQty = 0;
      if (filteredData?.length > 0) {
        getPriceLots = filteredData[0].priceLots;
        unitPriceByQty = getPriceForUpperThVal(estimateProducts.purchases[p].required * quantity, getPriceLots);
        estimateProducts.purchases[p].name = filteredData[0]?.name;
        estimateProducts.purchases[p].model = filteredData[0]?.info?.model;
        estimateProducts.purchases[p].purchaseCategoryId = filteredData[0]?.purchaseCategoryId;
        estimateProducts.purchases[p].processTime = filteredData[0]?.info?.processTime;
        estimateProducts.purchases[p].supplierCode = filteredData[0]?.info?.supplierCode;
        estimateProducts.purchases[p].supplierName = filteredData[0]?.info?.supplierName;
        estimateProducts.purchases[p].makerName = filteredData[0]?.info?.makerName;
        estimateProducts.purchases[p].remarks = filteredData[0]?.info?.remarks;
        estimateProducts.purchases[p].totalProcessTime = filteredData[0]?.info?.processTime * quantity;
      } else {
        unitPriceByQty = 0;
      }
      estimateProducts.purchases[p].unitPriceByQuantity = unitPriceByQty;
      estimateProducts.purchases[p].price =
        unitPriceByQty > 0
          ? estimateProducts.purchases[p].isKeepPrice
            ? unitPriceByQty * estimateProducts.purchases[p].required
            : unitPriceByQty
          : estimateProducts.purchases[p].isKeepPrice
          ? estimateProducts.purchases[p].unitPrice * estimateProducts.purchases[p].required
          : estimateProducts.purchases[p].unitPrice; // IQX_WEBEST-290 単価保持のチェックがオフの場合、金額＝単価,
      estimateProducts.purchases[p].totalPrice =
        unitPriceByQty > 0
          ? estimateProducts.purchases[p].isKeepPrice
            ? unitPriceByQty * estimateProducts.purchases[p].required * quantity
            : unitPriceByQty
          : estimateProducts.purchases[p].isKeepPrice
          ? estimateProducts.purchases[p].unitPrice * estimateProducts.purchases[p].required * quantity
          : estimateProducts.purchases[p].unitPrice; // IQX_WEBEST-231 単価保持のチェックがオフの場合、合計金額＝単価

      parPurUnitPrice += estimateProducts.purchases[p]?.price;
      // ファストリリースに単価保持を一応解除(2023/11/10)
      // parPurTotalPrice += parentPurchase[p]?.isKeepPrice ? parentPurchase[p]?.price : parentPurchase[p]?.totalPrice; // * quantity
      parPurTotalPrice += estimateProducts.purchases[p]?.totalPrice;
    }
  }

  //板金子部品より重量と面積データ更新
  estimateProducts.totalWeight = iq3Weight;
  estimateProducts.totalWeightSm = iq3Weight;
  estimateProducts.totalSurfaceArea = iq3Area;
  let iq3TotalPartCount = esIq3Infos?.reduce((total, item) => {
    return total + Number(item.partCount) * quantity; // 板金子部品製作数（板金子部品の員数＊数量）
  }, 0);
  estimateProducts.total_quantity_sm = iq3TotalPartCount; // 板金子部品製作数（板金子部品の員数＊数量）

  //費用合計
  let costPriceTotal = 0;
  let epCustomCosts = selectedDataDetail.epCustomCosts;
  for (let i = 0; i < epCustomCosts?.length; i++) {
    if (epCustomCosts[i]?.isUseUnit) {
      costPriceTotal += epCustomCosts[i]?.unitPrice * quantity; // IQX_WEBEST-228
    } else {
      costPriceTotal += epCustomCosts[i]?.totalPrice;
    }
  }

  /** 合計原価計算 */
  // 親部品製造合計原価(段取金額+(作業/加工金額*数量))
  let parentManufacturingCostTotalPrice = 0;
  if (setupCostUnitPrice !== undefined && estimateProducts?.workCostUnitPrice !== undefined) {
    parentManufacturingCostTotalPrice = setupCostUnitPrice + estimateProducts?.workCostUnitPrice * quantity;
    estimateProducts.setupCostTotalPrice = setupCostUnitPrice + iq3SetupCostPrice_total;
    estimateProducts.workCostTotalPrice = estimateProducts?.workCostUnitPrice * quantity + iq3WorkCostPrice_total;
    estimateProducts.manufacturingCostTotalPrice = parentManufacturingCostTotalPrice
      ? parentManufacturingCostTotalPrice
      : 0;
  }
  //　製造合計原価（親部品製造合計原価　＋　板金製造合計原価*数量＋プログラム工程合計原価＋シャーリング合計原価）
  estimateProducts.manufacturingCostTotalPrice = parentManufacturingCostTotalPrice + iq3ManufacturingCostTotalPrice;
  // 製造合計原価
  let totalProcessGenka = estimateProducts.manufacturingCostTotalPrice
    ? estimateProducts.manufacturingCostTotalPrice
    : 0;

  // 合計原価（材料原価+段取金額＋(加工金額×数量)+購入品費+追加項目(費用)）
  // 材料原価合計
  let totalMaterialGenka = estimateProducts?.materialCostTotalPrice ? estimateProducts?.materialCostTotalPrice : 0;
  estimateProducts.materialCostTotalPrice = totalMaterialGenka ? totalMaterialGenka : 0;

  // 購入品費合計(購入品金額(数量別金額)*数量　※設定による（単価保持の場合のみ*数量）)
  let iq3TotalPurchaseTotalPrice = selectedDataDetail?.esIq3Info?.reduce((total, purchaseitem) => {
    return total + parseFloat(purchaseitem.purchaseTotalPrice);
  }, 0);
  // let totalPurchaseCost = parPurTotalPrice + iq3PurTotalPrice;
  let totalPurchaseCost = parPurTotalPrice + iq3TotalPurchaseTotalPrice;
  totalPurchaseCost = totalPurchaseCost ? totalPurchaseCost : 0;
  estimateProducts.purchaseTotalPrice = totalPurchaseCost;
  estimateProducts.purchaseUnitPrice = Number.isFinite(totalPurchaseCost / quantity) ? totalPurchaseCost / quantity : 0;

  // 合計原価
  let totalGenka = totalMaterialGenka + totalProcessGenka + totalPurchaseCost + costPriceTotal;
  estimateProducts.costTotalPrice = totalGenka ? totalGenka : 0;

  // 単品原価
  let genka = Number.isFinite(totalGenka / quantity) ? totalGenka / quantity : 0;
  // 原価
  if (genka !== undefined) {
    estimateProducts.costUnitPrice = genka;
  } else {
    estimateProducts.costUnitPrice = 0;
  }

  /** 合計見積計算 */
  // 取引先係数＋納期係数＋受注形態係数
  let coeffsTotal =
    Number(estimateProducts?.otherProduct?.coeffClient) +
    Number(estimateProducts?.otherProduct?.coeffDeliveryDates) +
    Number(estimateProducts?.otherProduct?.coeffOrders);

  // 材料見積合計
  let totalMaterialEstimate = iq3EstimateMaterialTotalPrices ? iq3EstimateMaterialTotalPrices : 0;
  // 加工原価の見積合計((加工原価合計*(取引先係数＋納期係数＋受注形態係数-2))*管理係数)
  let totalProcessEstimate =
    coeffsTotal && estimateProducts?.otherProduct?.coeffManagements
      ? estimateProducts?.manufacturingCostTotalPrice *
        (coeffsTotal - 2) *
        Number(estimateProducts?.otherProduct?.coeffManagements)
      : 0;
  // 購入品費見積(購入品費*"仕入先係数or購入品係数")
  let totalPurchaseEstimate =
    totalPurchaseCost && estimateProducts?.otherProduct?.coeffPurchases
      ? totalPurchaseCost * Number(estimateProducts?.otherProduct?.coeffPurchases)
      : 0;
  // 合計見積(材料見積+加工見積+購入品費+追加項目(費用))
  let totalEstimate = totalMaterialEstimate + totalProcessEstimate + totalPurchaseEstimate + costPriceTotal;
  //　価格調整を見積に加算
  let adjustAmount = estimateProducts?.otherProduct?.adjustment ? estimateProducts?.otherProduct?.adjustment : 0;
  totalEstimate =
    estimateProducts?.otherProduct?.adjustmentMethod === '+'
      ? totalEstimate + adjustAmount
      : totalEstimate - adjustAmount;
  estimateProducts.estimateTotalPrice = totalEstimate ? totalEstimate : 0;

  /** 見積計算 */
  let estimate = Number.isFinite(totalEstimate / quantity) ? totalEstimate / quantity : 0;
  estimateProducts.estimateUnitPrice = estimate ? estimate : 0;

  // 粗利単価
  estimateProducts.grossUnitPrice = estimate !== undefined && genka !== undefined ? estimate - genka : 0;
  // 粗利合計(合計見積-合計原価)
  estimateProducts.grossTotalPrice =
    totalEstimate !== undefined && totalGenka !== undefined ? totalEstimate - totalGenka : 0;

  // 粗利率(粗利/合計見積x100)
  if (estimateProducts.grossTotalPrice !== undefined && totalEstimate !== undefined) {
    estimateProducts.grossRate =
      estimateProducts.grossTotalPrice === 0 || totalEstimate === 0
        ? 0
        : Number.isFinite(estimateProducts.grossTotalPrice / totalEstimate)
        ? (estimateProducts.grossTotalPrice / totalEstimate) * 100
        : 0;
    estimateProducts.grossRate = estimateProducts.grossRate !== undefined ? estimateProducts.grossRate : 0.0;
  }

  // estimateProducts.materialLossCoefficient = estimateProducts.materialLossCoefficient
  //   ? estimateProducts.materialLossCoefficient
  //   : 0;
  // 再計算した親部品情報を更新
  selectedDataDetail.estimateProducts = estimateProducts;
  return selectedDataDetail;
}

//Excel 出力
//Serverからフォーマットファイルにあるcolumnを取得
//param (HTML型フォーマットテーブル)
function extractHeaderFromHtmlTable(tableHtml) {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = tableHtml;

  const table = tempDiv.querySelector('table');
  let tdContentArray;

  if (table) {
    const rows = table.querySelectorAll('tr');
    rows?.forEach((row) => {
      const cells = row.querySelectorAll('td');
      const cellContentArray = [];

      cells?.forEach((cell) => {
        const cellContent = cell.textContent.trim();
        cellContentArray.push(cellContent);
      });

      tdContentArray = cellContentArray;
    });
  } else {
    console.error('Table not found in HTML content.');
  }

  return tdContentArray;
}
//dataIndexによって情報取得
const extractColumnData = (record, column) => {
  const dbNameAttr = column.dbNameAttr;
  const dataIndex = column.dataIndex;
  const nestedDataIndex = dataIndex.split('.');
  let value = record;

  nestedDataIndex?.forEach((index) => {
    if (value) {
      value = value[index];
    }
  });

  if (value === undefined) {
    return 0;
  }

  if (column.render) {
    return column.render(value, record);
    /* return column.render(value); */
  }

  return value;
};

//フォーマットによって画面に表示しいてるdata取得
function extractDataByExcelColumns(data, excelColumnHeaders, columnList) {
  const arrayOfObjects = [];

  let initDisplayColumns = [];
  if (columnList) {
    initDisplayColumns = columnList;
  }
  data?.forEach((record, index) => {
    const rowData = {};
    excelColumnHeaders?.forEach((header) => {
      // defaultColumns?.forEach((column) => {
      const column = initDisplayColumns?.find((col) => col.title === header);
      if (column) {
        const columnNameT = column.id + column.title;
        const columnName = column.title;
        const columnData = extractColumnData(record, column);
        if (typeof columnData === 'object') {
          if (columnData.props?.children?.props !== undefined) {
            rowData[columnName] = columnData.props?.children?.props?.children;
          } else if (Array.isArray(columnData.props?.children)) {
            rowData[columnName] = columnData.props?.children?.[1];
          } else if (columnData.props?.children !== undefined) {
            rowData[columnName] = columnData.props?.children;
          } else if (columnData.props?.checked !== undefined) {
            rowData[columnName] = 'true' /* columnData.props?.checked */;
          }
        } else if (columnName == 'ID') {
          rowData[columnName] = data?.length - index;
        } else {
          rowData[columnName] = columnData;
        }
      } else {
        rowData[header] = null; // Handle columns not found in defaultColumns
      }
    });
    arrayOfObjects.push(rowData);
  });
  return arrayOfObjects;
}

//HTML Table作成
//param (テーブル column, テーブル内容)
function createHtmlTable(header, data) {
  // Create the table element
  let table = document.createElement('table');

  if (header !== '') {
    // Create the table header row
    let headerRow = document.createElement('tr');
    for (let columnHeader of header) {
      let th = document.createElement('th');
      th.textContent = columnHeader == 'No2.' ? 'No.' : columnHeader;
      headerRow.appendChild(th);
    }
    table.appendChild(headerRow);

    // Create table rows for each data entry
    for (let rowData of data) {
      let row = document.createElement('tr');
      for (let columnHeader of header) {
        let td = document.createElement('td');
        td.textContent = rowData[columnHeader];
        row.appendChild(td);
      }
      table.appendChild(row);
    }
  } else {
    for (let rowData of data) {
      let row = document.createElement('tr');
      for (let key in rowData) {
        let td = document.createElement('td');
        if (Object.hasOwnProperty.call(rowData, key)) {
          td.textContent = rowData[key];
        }
        row.appendChild(td);
      }
      table.appendChild(row);
    }
  }

  // Serialize the table to an HTML string
  let htmlString = table.outerHTML;

  return htmlString;
}

export function getNameByIdExcel(id, selectedData) {
  let retValue = '';
  if (id) {
    let info = selectedData.estimateProducts.calcParameters.parameters.purchaseCategory?.filter(
      (info) => info.id === id
    );
    if (info?.length > 0) {
      retValue = info[0].name;
    }
  }
  return retValue;
}

export const createAdditionalItemsTbl = (selectedData) => {
  const customFieldData = {
    '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[費用]': [],
    '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[ラベル]': [],
    '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[日付]': [],
    '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[メモ]': [],
  };

  // Populate the arrays with data
  customFieldData['■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[費用]'] = selectedData.epCustomCosts?.map((item, index) => ({
    id: index + 1,
    additionalItemCode: item?.additionalItemCode,
    additionalItemName: item?.additionalItemName,
    unitPrice: item?.unitPrice,
    quantity: selectedData?.estimateProducts?.quantity,
    totalPrice: item?.totalPrice,
  }));

  customFieldData['■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[ラベル]'] = selectedData.epCustomLabels?.map((item, index) => ({
    id: index + 1,
    additionalItemCode: item?.additionalItemCode,
    additionalItemName: item?.additionalItemName,
    label: item?.label,
  }));

  customFieldData['■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[日付]'] = selectedData.epCustomDates?.map((item, index) => ({
    id: index + 1,
    additionalItemCode: item?.additionalItemCode,
    additionalItemName: item?.additionalItemName,
    date: item?.date,
  }));

  customFieldData['■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[メモ]'] = selectedData.epCustomMemos?.map((item, index) => ({
    id: index + 1,
    additionalItemCode: item?.additionalItemCode,
    additionalItemName: item?.additionalItemName,
    memo: item?.memo,
  }));
  return customFieldData;
};

const createProcessDataTbl = (selectedData) => {
  const customFieldData = {};
  let quantity = selectedData.estimateProducts.quantity;
  quantity = quantity ? Number(quantity) : 0;
  let calculateData = selectedData.estimateProducts.additions;
  let calInputData = selectedData.estimateProducts.processInput;
  let processMasterData = selectedData.estimateProducts.calcParameters.parameters.process;
  let device = selectedData.estimateProducts.calcParameters.parameters.device;
  let processPattern = selectedData.estimateProducts.processPattern;
  let calProcessMstData = [];
  // 取引先係数＋納期係数＋受注形態係数
  let coeffClient = selectedData?.estimateProducts?.otherProduct?.coeffClient
    ? Number(selectedData?.estimateProducts?.otherProduct?.coeffClient)
    : 0;
  let coeffDeliveryDates = selectedData?.estimateProducts?.otherProduct?.coeffDeliveryDates
    ? Number(selectedData?.estimateProducts?.otherProduct?.coeffDeliveryDates)
    : 0;
  let coeffOrders = selectedData?.estimateProducts?.otherProduct?.coeffOrders
    ? Number(selectedData?.estimateProducts?.otherProduct?.coeffOrders)
    : 0;
  let coeffsTotal = coeffClient + coeffDeliveryDates + coeffOrders;
  // 管理係数
  let coeffMng = selectedData?.estimateProducts?.otherProduct?.coeffManagements
    ? Number(selectedData?.estimateProducts?.otherProduct?.coeffManagements)
    : 0;
  calculateData?.forEach((calData, index1) => {
    let calProcess = processMasterData?.filter((record) => record?.id === calData.processId)?.[0];
    calProcessMstData.push(calProcess);
    let curParPatternInfoFilter = processPattern
      ? processPattern?.details?.filter((item) => item.processId === calData.processId && item.isUsedForCalc)
      : [];
    let patternInfoFilter =
      curParPatternInfoFilter && curParPatternInfoFilter?.length > 0 ? curParPatternInfoFilter[0] : [];
    // 生産個数係数取得
    let productionQuantityCoeff = 1;
    let deviceMstFilter = device
      ? device?.filter(
          (item) => patternInfoFilter?.processId == item.processId && patternInfoFilter?.deviceId == item.id
        )
      : [];
    if (deviceMstFilter != undefined && deviceMstFilter?.length > 0) {
      // 生産個数係数
      let productionCoeffTh = deviceMstFilter[0]?.details?.productionQtyCoeffThMaster?.filter(
        (item) => (item.value == quantity || item.value > quantity) && !item.isOutOfRange
      );
      if (productionCoeffTh) {
        let productionCoeffThNo =
          productionCoeffTh?.length > 0
            ? productionCoeffTh[0]?.no
            : deviceMstFilter[0]?.details?.productionQtyCoeffThMaster?.[0]?.no;
        let productionCoeff =
          productionCoeffThNo == 0
            ? 0
            : deviceMstFilter[0]?.details?.productionQtyCoeffThItems?.filter(
                (item) => item.no == productionCoeffThNo && !item.isOutOfRange
              )[0]?.value;

        productionQuantityCoeff = productionCoeff;
        productionQuantityCoeff = productionQuantityCoeff ? productionQuantityCoeff : 1;
      }
    }
    let count = 1;
    let calItems = calData?.dataType === DataType.Data ? calData?.dataItems : calData?.editItems;
    calItems?.forEach((e, index) => {
      let totalAmt = 0; // 原価合計金額
      let dandoriAmt = 0;
      let sagyoAmt = 0;
      let dandoriTm = 0;
      let sagyoTm = 0;
      let totalTm = 0;
      let estTotalAmt = 0;
      let estDandoriAmt = 0;
      let estSagyoAmt = 0;

      dandoriAmt = e.dandoriAmt ? e.dandoriAmt : 0; // 段取金額
      sagyoAmt = e.sagyoAmt ? e.sagyoAmt * productionQuantityCoeff * quantity : 0; // 作業/加工金額*生産個数係数
      totalAmt = dandoriAmt + sagyoAmt; // 段取金額+作業/加工金額*生産個数係数

      dandoriTm = e.dandoriTime ? e.dandoriTime : 0;
      sagyoTm = e.sagyoTime ? e.sagyoTime * productionQuantityCoeff * quantity : 0; // 作業/加工時間*生産個数係数
      totalTm = dandoriTm + sagyoTm; // 段取時間+作業/加工時間*生産個数係数

      // 加工原価の見積合計((加工原価合計*(取引先係数＋納期係数＋受注形態係数-2))*管理係数)
      estTotalAmt = totalAmt * (coeffsTotal - 2) * coeffMng;
      // 段取金額の見積合計((段取金額*(取引先係数＋納期係数＋受注形態係数-2))*管理係数)
      estDandoriAmt = dandoriAmt * (coeffsTotal - 2) * coeffMng;
      // 作業金額の見積合計((作業金額*(取引先係数＋納期係数＋受注形態係数-2))*管理係数)
      estSagyoAmt = sagyoAmt * (coeffsTotal - 2) * coeffMng;

      let processInput = {};
      if (calProcess?.workType === WorkType.PaSurface) {
        let curProcessInput = calInputData?.surfaceProcessInputInfo?.filter(
          (item) =>
            item.processDetailTypeNo === e.processDetailTypeNo &&
            item.processDetailWorkTypeGroup === e.processDetailWorkTypeGroup
        );
        if (curProcessInput?.length > 0) {
          processInput = curProcessInput[0];
        }
      } else if (calProcess?.workType === WorkType.PaInspection) {
        processInput = calInputData.inspectionProcessInputInfo;
      } else if (calProcess?.workType === WorkType.PaAssenble) {
        processInput = calInputData.assenbleProcessInputInfo;
      } else if (calProcess?.workType === WorkType.PaPacking) {
        processInput = calInputData.packingProcessInputInfo;
      }
      if (!customFieldData[calProcess?.workType]) {
        customFieldData[calProcess?.workType] = [];
      }
      customFieldData[calProcess?.workType]?.push({
        no: index1 + 1,
        classification: calProcess?.class == 0 ? '親部品' : calProcess?.class == 1 ? '板金' : '',
        type: calProcess?.isAdditional ? '追加' : '標準',
        processCode: calProcess?.code,
        processName: calProcess?.name,
        processType: (() => {
          const matchType = kouteiMasterTypes?.find((type) => type.workType === calProcess?.workType);
          return matchType ? matchType.typeName : null;
        })(),
        no2: count,
        detailCode: calProcess?.details?.processDetailTypes?.filter(
          (record) => record?.id === e?.processDetailTypeNo
        )?.[0]?.code,
        processDetailType: calProcess?.details?.processDetailTypes?.filter(
          (record) => record?.id === e?.processDetailTypeNo
        )?.[0]?.name,
        parentCount: quantity,
        childCount: processInput?.smPartsCount ? Number(processInput?.smPartsCount) : '',
        kaitsuu:
          processInput?.calcType === MaterialSurfaceBillingUnit.SquareMeter ||
          processInput?.calcType === MaterialSurfaceBillingUnit.Work
            ? processInput?.areaCount
              ? Number(processInput?.areaCount)
              : ''
            : processInput?.weightCount
            ? Number(processInput?.weightCount)
            : '',
        menseki: processInput?.totalArea ? processInput?.totalArea : '',
        jyuuryou: processInput?.totalWeight ? processInput?.totalWeight : '',
        estTotalAmt: estTotalAmt, // 種類ごとに見積出す
        estDandoriAmt: estDandoriAmt, // 種類ごとに見積出す
        estSagyoAmt: estSagyoAmt, // 種類ごとに見積出す
        gankaTotalAmt: totalAmt, // 原価合計金額
        gankaDandoriAmt: dandoriAmt, // 段取金額
        gankaSagyoAmt: sagyoAmt, // 作業/加工金額*生産個数係数
        gankaTotalTime: totalTm, // 原価合計時間
        gankaDandoriTime: dandoriTm, // 段取時間
        gankaSagyoTime: sagyoTm, // 作業時間
      });
      count++;
    });
  });
  return customFieldData;
};

export const createProcessDataTbl2 = (selectedData) => {
  const customFieldData = {};
  let quantity = selectedData.estimateProducts.quantity;
  quantity = quantity ? Number(quantity) : 0;
  let calculateData = selectedData.estimateProducts.additions;
  calculateData?.sort((a, b) => a?.processId - b?.processId);
  let calInputData = selectedData.estimateProducts.processInput;
  let processMasterData = selectedData.estimateProducts.calcParameters.parameters.process;
  let device = selectedData.estimateProducts.calcParameters.parameters.device;
  let processPattern = selectedData.estimateProducts.processPattern;
  let calProcessMstData = [];
  let count = 0;
  // 取引先係数＋納期係数＋受注形態係数
  let coeffClient = selectedData?.estimateProducts?.otherProduct?.coeffClient
    ? Number(selectedData?.estimateProducts?.otherProduct?.coeffClient)
    : 0;
  let coeffDeliveryDates = selectedData?.estimateProducts?.otherProduct?.coeffDeliveryDates
    ? Number(selectedData?.estimateProducts?.otherProduct?.coeffDeliveryDates)
    : 0;
  let coeffOrders = selectedData?.estimateProducts?.otherProduct?.coeffOrders
    ? Number(selectedData?.estimateProducts?.otherProduct?.coeffOrders)
    : 0;
  let coeffsTotal = coeffClient + coeffDeliveryDates + coeffOrders;
  // 管理係数
  let coeffMng = selectedData?.estimateProducts?.otherProduct?.coeffManagements
    ? Number(selectedData?.estimateProducts?.otherProduct?.coeffManagements)
    : 0;

  let iq3List = selectedData?.esIq3Info;
  let totalWeight = selectedData?.estimateProducts?.totalWeight
    ? toDecimal(selectedData?.estimateProducts?.totalWeight, 2)
    : '';
  let totalArea = selectedData?.estimateProducts?.totalSurfaceArea
    ? toDecimal(selectedData?.estimateProducts?.totalSurfaceArea, 2)
    : '';
  let iq3TotalPartCount = iq3List?.reduce((total, item) => {
    return total + Number(item.partCount);
  }, 0);

  calculateData?.forEach((calData, index1) => {
    let calProcess = processMasterData?.filter((record) => record?.id === calData.processId)?.[0];
    calProcessMstData?.push(calProcess);
    let curParPatternInfoFilter = processPattern
      ? processPattern?.details?.filter((item) => item.processId === calData.processId && item.isUsedForCalc)
      : [];
    let patternInfoFilter =
      curParPatternInfoFilter && curParPatternInfoFilter?.length > 0 ? curParPatternInfoFilter[0] : [];
    // 生産個数係数取得
    let productionQuantityCoeff = 1;
    let deviceMstFilter = device
      ? device?.filter(
          (item) => patternInfoFilter?.processId == item.processId && patternInfoFilter?.deviceId == item.id
        )
      : [];
    if (deviceMstFilter != undefined && deviceMstFilter?.length > 0) {
      // 生産個数係数
      let productionCoeffTh = deviceMstFilter[0]?.details?.productionQtyCoeffThMaster?.filter(
        (item) => (item.value == quantity || item.value > quantity) && !item.isOutOfRange
      );
      if (productionCoeffTh) {
        let productionCoeffThNo =
          productionCoeffTh?.length > 0
            ? productionCoeffTh[0]?.no
            : deviceMstFilter[0]?.details?.productionQtyCoeffThMaster?.[0]?.no;
        let productionCoeff =
          productionCoeffThNo == 0
            ? 0
            : deviceMstFilter[0]?.details?.productionQtyCoeffThItems?.filter(
                (item) => item.no == productionCoeffThNo && !item.isOutOfRange
              )[0]?.value;

        productionQuantityCoeff = productionCoeff;
        productionQuantityCoeff = productionQuantityCoeff ? productionQuantityCoeff : 1;
      }
    }
    if (calProcess?.workType === WorkType.PaCustom) {
      let count2 = 1;
      let calItems = calData?.dataType === DataType.Data ? calData?.dataItems : calData?.editItems;
      calItems?.forEach((e, index) => {
        let totalAmt = 0; // 原価合計金額
        let dandoriAmt = 0;
        let sagyoAmt = 0;
        let dandoriTm = 0;
        let sagyoTm = 0;
        let totalTm = 0;
        let estTotalAmt = 0;
        let estDandoriAmt = 0;
        let estSagyoAmt = 0;

        dandoriAmt = e.dandoriAmt ? e.dandoriAmt : 0; // 段取金額
        sagyoAmt = e.sagyoAmt ? e.sagyoAmt * productionQuantityCoeff * quantity : 0; // 作業/加工金額*生産個数係数
        totalAmt = (dandoriAmt ? Math.round(dandoriAmt) : 0) + (sagyoAmt ? Math.round(sagyoAmt) : 0); // 段取金額+作業/加工金額*生産個数係数

        dandoriTm = e.dandoriTime ? e.dandoriTime : 0;
        sagyoTm = e.sagyoTime ? e.sagyoTime * productionQuantityCoeff * quantity : 0; // 作業/加工時間*生産個数係数
        totalTm = dandoriTm + sagyoTm; // 段取時間+作業/加工時間*生産個数係数

        // 加工原価の見積合計((加工原価合計*(取引先係数＋納期係数＋受注形態係数-2))*管理係数)
        estTotalAmt = totalAmt * (coeffsTotal - 2) * coeffMng;
        // 段取金額の見積合計((段取金額*(取引先係数＋納期係数＋受注形態係数-2))*管理係数)
        estDandoriAmt = dandoriAmt * (coeffsTotal - 2) * coeffMng;
        // 作業金額の見積合計((作業金額*(取引先係数＋納期係数＋受注形態係数-2))*管理係数)
        estSagyoAmt = sagyoAmt * (coeffsTotal - 2) * coeffMng;

        const commonAttribute = calProcess?.id;

        if (!customFieldData[commonAttribute]) {
          count++;
          customFieldData[commonAttribute] = [];
        } else {
          count2++;
          count = customFieldData[commonAttribute][0]['no'];
        }
        customFieldData[commonAttribute]?.push({
          no: count,
          classification: calProcess?.class == 0 ? '親部品' : calProcess?.class == 1 ? '板金' : '',
          type: calProcess?.isAdditional ? '追加' : '標準',
          processCode: calProcess?.code,
          processName: calProcess?.name,
          processType: (() => {
            const matchType = kouteiMasterTypes?.find((type) => type.workType === calProcess?.workType);
            return matchType ? matchType.typeName : null;
          })(),
          no2: count2,
          detailCode: calProcess?.details?.processDetailTypes?.filter(
            (record) => record?.id === e?.processDetailTypeNo
          )?.[0]?.code,
          processDetailType: calProcess?.details?.processDetailTypes?.filter(
            (record) => record?.id === e?.processDetailTypeNo
          )?.[0]?.name,
          parentCount: quantity,
          childCount: iq3TotalPartCount,
          kaitsuu: e?.kasho ? e.kasho : '',
          menseki: totalArea,
          jyuuryou: totalWeight,
          estTotalAmt: estTotalAmt, // 種類ごとに見積出す
          estDandoriAmt: estDandoriAmt, // 種類ごとに見積出す
          estSagyoAmt: estSagyoAmt, // 種類ごとに見積出す
          gankaTotalAmt: totalAmt, // 原価合計金額
          gankaDandoriAmt: dandoriAmt, // 段取金額
          gankaSagyoAmt: sagyoAmt, // 作業/加工金額*生産個数係数
          gankaTotalTime: totalTm, // 原価合計時間
          gankaDandoriTime: dandoriTm, // 段取時間
          gankaSagyoTime: sagyoTm, // 作業時間
        });
      });
    }
  });
  return customFieldData;
};

const getChildProcessCount = (selectedData, iq3Infos) => {
  let processMasterData = selectedData?.estimateProducts?.calcParameters?.parameters?.process;
  const isUsedForCalcArrayPerItem = iq3Infos?.map((item) =>
    item?.processSelect?.details?.filter((detail) => detail.isUsedForCalc)?.map((detail) => detail.processId)
  );
  const foundCountArr = [];
  isUsedForCalcArrayPerItem?.forEach((subArray) => {
    const subArrayCounts = subArray?.reduce((count, num) => {
      count[num] = (count[num] || 0) + 1;
      return count;
    }, {});

    for (const key in subArrayCounts) {
      foundCountArr[key] = (foundCountArr[key] || 0) + subArrayCounts[key];
    }
  });

  let dataSheet4 = [];
  Object.keys(foundCountArr)?.forEach((key, index) => {
    let useProcessMasterData = processMasterData?.filter((record) => record?.id === parseInt(key))?.[0];
    let matchType = kouteiMasterTypes?.find((type) => type.workType === useProcessMasterData?.workType);
    let relatedType = matchType ? matchType.typeName : null;
    dataSheet4.push({
      no: index + 1,
      id: parseInt(key),
      classification: useProcessMasterData?.class == 0 ? '親部品' : useProcessMasterData?.class == 1 ? 'iQ3' : '',
      type: useProcessMasterData?.isAdditional ? '追加' : '標準',
      processCode: useProcessMasterData?.code,
      processName: useProcessMasterData?.name,
      count: foundCountArr[key],
      workType: useProcessMasterData?.workType,
    });
  });

  return dataSheet4;
};

export const calEstDataSheet5 = (esIq3Infos, parentEstData) => {
  let parameters = parentEstData?.estimateProducts?.calcParameters?.parameters;
  let resData = [];
  let iq3ManufacturingCostUnitPrice = 0;
  let iq3ManufacturingCostTotalPrice = 0;
  let iq3EstimateMaterialUnitPrices = 0;
  let iq3EstimateMaterialTotalPrices = 0;
  let iq3PurUnitPrice = 0;
  let iq3PurTotalPrice = 0;
  let quantity = parentEstData?.estimateProducts?.quantity ? Number(parentEstData?.estimateProducts?.quantity) : 1;
  let device = parentEstData?.estimateProducts?.calcParameters?.parameters?.device;
  let productionQuantityCoeff = 1;
  for (let s = 0; s < esIq3Infos?.length; s++) {
    resData[s] = {
      workCostTotalPrice: 0,
      materialCostUnitPrice: 0,
      materialCostTotalPrice: 0,
    };
    // 材料、材質、材料表面保護、材料サイズパラメータ情報
    let materialIq3 = parentEstData?.estimateProducts?.calcParameters?.parameters?.materialIq3;
    let materialTypeIq3 = parentEstData?.estimateProducts?.calcParameters?.parameters?.materialTypeIq3;
    let materialSizeIq3 = parentEstData?.estimateProducts?.calcParameters?.parameters?.materialSizeIq3;
    let materialSurfaceIq3 = parentEstData?.estimateProducts?.calcParameters?.parameters?.materialSurfaceIq3;

    // 材料情報を再設定
    let materialIq3Info = materialIq3?.find((item) => item.id === esIq3Infos[s]?.materialIq3Id && item?.info?.isUsed);
    esIq3Infos[s].materialIq3Id = materialIq3Info ? esIq3Infos[s]?.materialIq3Id : materialIq3?.[0]?.id;
    esIq3Infos[s].materialName = materialIq3Info ? esIq3Infos[s]?.materialName : materialIq3?.[0]?.name;

    materialIq3Info = materialIq3?.find((item) => item.id === esIq3Infos[s]?.materialIq3Id);

    // 材質情報を再設定
    let materialTypeIq3Info = materialTypeIq3?.find(
      (item) =>
        item.id === esIq3Infos[s]?.materialTypeIq3Id &&
        item.id === materialIq3Info?.materialTypeIq3Id &&
        item?.info?.isUsed
    );
    let initMaterialTypeIq3Info = materialTypeIq3?.find(
      (item) => item.id === materialIq3Info?.materialTypeIq3Id && item?.info?.isUsed
    );
    esIq3Infos[s].materialTypeIq3Id = materialTypeIq3Info
      ? esIq3Infos[s]?.materialTypeIq3Id
      : initMaterialTypeIq3Info?.id;
    esIq3Infos[s].materialTypeName = materialTypeIq3Info
      ? esIq3Infos[s]?.materialTypeName
      : initMaterialTypeIq3Info?.name;

    // 材料表面保護情報を再設定
    let materialSurfaceIq3Info = materialSurfaceIq3?.find(
      (item) =>
        item.id === esIq3Infos[s]?.materialSurfaceIq3Id &&
        item.materialTypeIq3Id === esIq3Infos[s].materialTypeIq3Id &&
        item?.info?.isUsed
    );
    let initMaterialSurfaceIq3Info = materialSurfaceIq3?.find(
      (item) => item.materialTypeIq3Id === esIq3Infos[s].materialTypeIq3Id && item?.info?.isUsed
    );

    esIq3Infos[s].materialSurfaceIq3Id = materialSurfaceIq3Info
      ? esIq3Infos[s]?.materialSurfaceIq3Id
      : initMaterialSurfaceIq3Info?.id;
    esIq3Infos[s].materialSurfaceName = materialSurfaceIq3Info
      ? esIq3Infos[s]?.materialSurfaceName
      : initMaterialSurfaceIq3Info?.name;

    // 板厚情報を再設定
    esIq3Infos[s].thickness = esIq3Infos[s].materialIq3Id;

    // 材料サイズを再設定
    esIq3Infos[s].materialDetailSizes?.map((item) => {
      if (item.sizeName !== 'Free' && item.sizeName !== 'Other') {
        let materialSizeInfo = materialSizeIq3?.find((mst) => mst.id === item?.materialSizeIq3Id && mst.info.isUsed);
        if (materialSizeInfo) {
          // マスタの情報を材料詳細のサイズに更新
          item.sizeName = materialSizeInfo.name;
          esIq3Infos[s].materialDetailPatterns
            ?.filter((pattern) => pattern?.materialSizeIq3Id === materialSizeInfo?.id)
            ?.map((pattern) => {
              pattern.sizeName = materialSizeInfo.name;
              pattern.sizeX = materialSizeInfo.info.sizeX;
              pattern.sizeY = materialSizeInfo.info.sizeY;
            });
        }
      }
    });

    // マスタに存在するサイズ情報のみを利用
    esIq3Infos[s].materialDetailSizes = esIq3Infos[s].materialDetailSizes?.filter(
      (item) =>
        item.sizeName === 'Free' ||
        materialSizeIq3?.findIndex((mst) => mst.id == item.materialSizeIq3Id && mst.info.isUsed) > -1
    );

    // マスタに追加した情報を追加
    materialSizeIq3
      ?.filter(
        (mst) =>
          mst.name !== 'Free' &&
          mst.info.isUsed &&
          esIq3Infos[s].materialDetailSizes.findIndex((item) => mst.id == item.materialSizeIq3Id) === -1
      )
      ?.map((item, index) => {
        let data = getInitMaterialSizeInfo(index, item);
        esIq3Infos[s].materialDetailSizes.push(data);
        esIq3Infos[s].materialDetailPatterns = getInitMaterialPatternsInfo(esIq3Infos[s].materialDetailPatterns, item);
      });

    //工程積算計算
    let iq3SetupCostTotalPrice = 0;
    let iq3WorkCostTotalPrice = 0;
    let iq3PurchaseUnitPrice = 0;
    let iq3PurchaseTotalPrice = 0;
    let iq3ProductionQuantityCoeff = 1;
    let iq3SetupCoeff = 1;
    let materialCoeff = 0;

    let partCnt = Number(esIq3Infos[s]?.partCount); // 員数
    let manufacturingCnt = quantity * partCnt; // 製造数 * 員数
    manufacturingCnt = manufacturingCnt ? manufacturingCnt : 1;

    let processPatterns = parentEstData?.estimateProducts?.calcParameters?.parameters?.processPattern;
    let processSelect = processPatterns?.filter((item) => item.id === esIq3Infos[s]?.processSelect?.id);
    if (processSelect?.length > 0) esIq3Infos[s].processSelect = processSelect[0];

    let iq3ProcessPattern = esIq3Infos[s]?.processSelect;
    //各子部品の工程積算合計計算
    let iq3UpdateKouteiCalInfo = esIq3Infos[s]?.additions;
    if (iq3UpdateKouteiCalInfo) {
      // 計算した工程データの足し算
      iq3UpdateKouteiCalInfo?.forEach((iq3UpdatedKouteiContent) => {
        let deviceMstFilter = [];
        //利用する工程パターンを取得
        let patternInfoFilter = [];
        let curPatternInfoFilter = iq3ProcessPattern
          ? iq3ProcessPattern?.details?.filter(
              (item) => item.processId == iq3UpdatedKouteiContent?.processId && item.isUsedForCalc
            )
          : [];
        if (curPatternInfoFilter && curPatternInfoFilter?.length > 0) {
          patternInfoFilter = curPatternInfoFilter[0];
          //複数処理（追加工程）の場合、工程IDで判断できないため、工程詳細IDで判断して対象データを取得
          if (curPatternInfoFilter?.length > 1) {
            let patternInfoFilterByTypeId = curPatternInfoFilter?.filter(
              (item) =>
                item.multiprocesses?.[0]?.processDetailTypeId ===
                iq3UpdatedKouteiContent?.dataItems?.[0]?.processDetailTypeNo
            );
            if (patternInfoFilterByTypeId && patternInfoFilterByTypeId?.length > 0) {
              patternInfoFilter = patternInfoFilterByTypeId[0];
            }
          }
          deviceMstFilter = device
            ? device?.filter(
                (item) => patternInfoFilter?.processId == item.processId && patternInfoFilter?.deviceId == item.id
              )
            : [];
          // 段取個数係数取得
          if (
            iq3UpdatedKouteiContent.totalDandori.dataItemsAmt > 0 ||
            iq3UpdatedKouteiContent.totalDandori.editItemsAmt > 0
          ) {
            if (deviceMstFilter != undefined && deviceMstFilter?.length > 0) {
              // 段取個数係数
              let prepQtyCoeffTh = deviceMstFilter[0]?.details?.prepQtyCoeffThMaster?.filter(
                (item) => (item.value == manufacturingCnt || item.value > manufacturingCnt) && !item.isOutOfRange
              );
              if (prepQtyCoeffTh) {
                let prepQtyCoeffThNo =
                  prepQtyCoeffTh?.length > 0
                    ? prepQtyCoeffTh?.[0]?.no
                    : deviceMstFilter?.[0]?.details?.prepQtyCoeffThMaster?.[0]?.no;
                let prepQtyCoeff =
                  prepQtyCoeffThNo == 0
                    ? 0
                    : deviceMstFilter?.[0]?.details?.prepQtyCoeffItems?.filter(
                        (item) => item.no == prepQtyCoeffThNo && !item.isOutOfRange
                      )[0]?.value;
                iq3SetupCoeff = prepQtyCoeff;
                iq3SetupCoeff = iq3SetupCoeff ? iq3SetupCoeff : 1;
              }
            }
          }
          // 生産個数係数取得
          if (deviceMstFilter != undefined && deviceMstFilter?.length > 0) {
            // 生産個数係数
            let productionCoeffTh = deviceMstFilter[0]?.details?.productionQtyCoeffThMaster?.filter(
              (item) => (item.value == manufacturingCnt || item.value > manufacturingCnt) && !item.isOutOfRange
            );
            if (productionCoeffTh) {
              let productionCoeffThNo =
                productionCoeffTh?.length > 0
                  ? productionCoeffTh?.[0]?.no
                  : deviceMstFilter?.[0]?.details?.productionQtyCoeffThMaster?.[0]?.no;
              let productionCoeff =
                productionCoeffThNo == 0
                  ? 0
                  : deviceMstFilter?.[0]?.details?.productionQtyCoeffThItems?.filter(
                      (item) => item.no == productionCoeffThNo && !item.isOutOfRange
                    )[0]?.value;
              iq3ProductionQuantityCoeff = productionCoeff;
              iq3ProductionQuantityCoeff = iq3ProductionQuantityCoeff ? iq3ProductionQuantityCoeff : 1;
            }
          }

          //　現在の工程タイプ取得
          let curWorkType = parameters?.process?.filter((item) => item.id == iq3UpdatedKouteiContent?.processId)?.[0]
            ?.workType;
          //　段取金額
          let iq3SetupCostPrice =
            iq3UpdatedKouteiContent.dataType == DataType.Data
              ? iq3UpdatedKouteiContent.totalDandori.dataItemsAmt
              : iq3UpdatedKouteiContent.totalDandori.editItemsAmt;
          // 段取金額＊段取個数係数
          iq3SetupCostPrice = iq3SetupCoeff > 0 ? iq3SetupCostPrice * iq3SetupCoeff : iq3SetupCostPrice * 1;
          iq3SetupCostPrice = iq3SetupCostPrice ? iq3SetupCostPrice : 0; // NaNチェック
          iq3SetupCostTotalPrice += iq3SetupCostPrice;

          //　作業/加工金額
          let iq3WorkCostPrice =
            iq3UpdatedKouteiContent.dataType == DataType.Data
              ? iq3UpdatedKouteiContent.totalSagyo.dataItemsAmt
              : iq3UpdatedKouteiContent.totalSagyo.editItemsAmt;
          // 作業/加工金額*生産個数係数
          iq3WorkCostPrice = iq3WorkCostPrice * iq3ProductionQuantityCoeff;
          iq3WorkCostPrice = iq3WorkCostPrice ? iq3WorkCostPrice : 0; // NaNチェック

          // プログラム工程の場合、プログラム工程の原価は子部品の員数、全体の数量に影響されないため、分けて計算
          if (curWorkType == WorkType.SmProgram) {
            //　プログラム工程の合計の場合、そのまま、製造数と掛けない
            iq3WorkCostTotalPrice += iq3WorkCostPrice;
          } else if (curWorkType == WorkType.SmShearing) {
            // シャーリングOne by one項目チェックを取得
            let oneByoneCheck = deviceMstFilter[0]?.details?.isOneCut;
            if (oneByoneCheck === false) {
              //　One by one項目チェックがOFFの場合、そのまま、製造数と掛けない
              iq3WorkCostTotalPrice += iq3WorkCostPrice;
            } else {
              //　One by one項目チェックがONの場合、製造数と掛ける
              iq3WorkCostTotalPrice += iq3WorkCostPrice * manufacturingCnt;
            }
          } else {
            // 加工時間×員数
            iq3WorkCostTotalPrice += iq3WorkCostPrice * manufacturingCnt;
          }
          //板金の基本情報に表面処理費表示のため設定
          if (curWorkType == WorkType.SmSurface) {
            if (iq3SetupCostPrice !== undefined && iq3WorkCostPrice !== undefined) {
              // (段取時間＋(加工時間×製造数))
              esIq3Infos[s].surfaceTotalPrice = iq3SetupCostPrice + iq3WorkCostPrice * manufacturingCnt;
              // (合計÷製造数)
              esIq3Infos[s].surfaceUnitPrice = Number.isFinite(esIq3Infos[s].surfaceTotalPrice / manufacturingCnt)
                ? esIq3Infos[s].surfaceTotalPrice / manufacturingCnt
                : 0;
            }
          }
        }
      });
      //板金原価
      if (iq3SetupCostTotalPrice !== undefined && iq3WorkCostTotalPrice !== undefined) {
        //　合計原価（段取金額＋（加工金額＊員数））
        esIq3Infos[s].workCostTotalPrice = iq3SetupCostTotalPrice + iq3WorkCostTotalPrice;

        //　単品原価（合計原価 ÷ 製造数　)
        esIq3Infos[s].workCostUnitPrice = Number.isFinite(esIq3Infos[s].workCostTotalPrice / manufacturingCnt)
          ? esIq3Infos[s].workCostTotalPrice / manufacturingCnt
          : 0;
        iq3ManufacturingCostUnitPrice += esIq3Infos[s]?.['workCostUnitPrice'];
      }
      resData[s]['iq3ManufacturingCostUnitPrice'] = iq3ManufacturingCostUnitPrice ? iq3ManufacturingCostUnitPrice : 0;
    }

    // 親部品材料原価更新
    resData[s]['materialCostUnitPrice'] += esIq3Infos[s]?.materialCostUnitPrice;
    resData[s]['materialCostTotalPrice'] += esIq3Infos[s]?.materialCostTotalPrice;
    // 材料粗利係数
    let curMaterialTypeIq3 = parameters?.materialTypeIq3?.filter(
      (item) => item.id === esIq3Infos[s]?.materialTypeIq3Id
    );
    if (curMaterialTypeIq3?.length > 0) {
      materialCoeff = curMaterialTypeIq3?.[0]?.info.lossCoeff;
      materialCoeff = materialCoeff ? materialCoeff : 1;
    }
    // 各板金の材料原価更新
    let iq3EstimateMaterialTotalPrice = esIq3Infos[s]?.materialCostTotalPrice * materialCoeff;
    iq3EstimateMaterialTotalPrices += iq3EstimateMaterialTotalPrice;

    // 板金の購入品合計
    let iq3Purchase = esIq3Infos[s]?.purchases;
    for (let p = 0; p < iq3Purchase?.length; p++) {
      if (iq3Purchase[p]?.isDeleted === false) {
        iq3PurUnitPrice += iq3Purchase[p]?.price;
        iq3PurTotalPrice += iq3Purchase[p]?.totalPrice; //単価保持
        // iq3PurTotalPrice += iq3Purchase[p]?.isKeepPrice === true ? iq3Purchase[p]?.price : iq3Purchase[p]?.totalPrice;
        // 各板金の購入品合計
        // iq3PurchaseUnitPrice += iq3Purchase[p]?.price;
        iq3PurchaseUnitPrice += iq3Purchase[p]?.price;
        iq3Purchase[p].totalPrice = iq3Purchase[p]?.price * partCnt;
        iq3PurchaseTotalPrice += iq3Purchase[p]?.totalPrice;
      }
    }
    iq3PurUnitPrice = iq3PurUnitPrice ? iq3PurUnitPrice * partCnt : 0;
    // 各板金の購入品費
    resData[s]['purchaseUnitPrice'] = iq3PurchaseUnitPrice ? iq3PurchaseUnitPrice * partCnt : 0;
    resData[s]['purchaseTotalPrice'] = iq3PurchaseTotalPrice ? iq3PurchaseTotalPrice * quantity : 0;

    //板金の合計原価計算(材料原価＋製造原価＋購入品費)
    if (
      esIq3Infos[s]?.materialCostTotalPrice !== undefined &&
      esIq3Infos[s]?.workCostTotalPrice !== undefined &&
      esIq3Infos[s]?.purchaseTotalPrice !== undefined
    ) {
      resData[s]['costTotalPrice'] =
        esIq3Infos[s]?.materialCostTotalPrice + esIq3Infos[s]?.workCostTotalPrice + esIq3Infos[s]?.purchaseTotalPrice;
      // 各板金の合計原価
      iq3ManufacturingCostTotalPrice += esIq3Infos[s]?.workCostTotalPrice;
      // 板金金額NaNチェック(プログラム工程とシャーリング工程以外)
      resData[s]['iq3ManufacturingCostTotalPrice'] = iq3ManufacturingCostTotalPrice
        ? iq3ManufacturingCostTotalPrice
        : 0;
    }

    //板金の単品原価計算(板金の合計原価 ÷ 製造数)
    if (esIq3Infos[s].costTotalPrice !== undefined) {
      resData[s]['costUnitPrice'] = esIq3Infos[s].costTotalPrice / manufacturingCnt;
    }
  }
  return resData;
};

const getSheet5Data = (usedProcessList, iq3Infos, selectedData) => {
  const sheet5Data = [];
  let quantity = selectedData?.estimateProducts?.quantity;
  // 材料名称リスト
  let materialLst = selectedData?.estimateProducts?.calcParameters?.parameters?.materialIq3?.filter(
    (i) => i?.info?.isUsed
  );
  // 材料表面保護
  let materialSurfaceLst = selectedData?.estimateProducts?.calcParameters?.parameters?.materialSurfaceIq3?.filter(
    (i) => i?.info?.isUsed
  );
  // 取引先係数＋納期係数＋受注形態係数
  let coeffClient = selectedData?.estimateProducts?.otherProduct?.coeffClient
    ? Number(selectedData?.estimateProducts?.otherProduct?.coeffClient)
    : 0;
  let coeffDeliveryDates = selectedData?.estimateProducts?.otherProduct?.coeffDeliveryDates
    ? Number(selectedData?.estimateProducts?.otherProduct?.coeffDeliveryDates)
    : 0;
  let coeffOrders = selectedData?.estimateProducts?.otherProduct?.coeffOrders
    ? Number(selectedData?.estimateProducts?.otherProduct?.coeffOrders)
    : 0;
  let coeffsTotal = coeffClient + coeffDeliveryDates + coeffOrders;
  // 管理係数
  let coeffMng = selectedData?.estimateProducts?.otherProduct?.coeffManagements
    ? Number(selectedData?.estimateProducts?.otherProduct?.coeffManagements)
    : 0;
  let coeffPurchase = selectedData?.estimateProducts?.otherProduct?.coeffPurchases
    ? Number(selectedData?.estimateProducts?.otherProduct?.coeffPurchases)
    : 0;
  let device = selectedData?.estimateProducts?.calcParameters?.parameters?.device?.filter((i) => i?.info?.isUsed);
  iq3Infos?.forEach((estData, index) => {
    let iq3KoutebetsuInfos = settingIQ3CalInfoByUnit(selectedData, estData, quantity);
    let manufacturingCnt = estData.partCount * selectedData?.estimateProducts?.quantity;
    let additionsData = estData.additions;
    let hijyu = materialLst?.filter((e) => e.id === estData.materialIq3Id)?.map((e) => e.info.gravity)[0];
    let prodNo = quantity * estData.partCount;
    let thickness = materialLst?.filter((e) => e.id === estData.materialIq3Id)?.map((e) => e.info.thick)[0];
    let weightUnitPrice = materialLst?.filter((e) => e.id === estData.materialIq3Id)?.map((e) => e.info.unitPrice)[0];

    let surfaceAddPrice = 0;
    let weightAddPrice = 0;
    let surfaceProtection = materialSurfaceLst
      ?.filter((e) => e.id === estData.materialSurfaceIq3Id)
      ?.map((e) => e.name)[0];
    // 材料表面パラメータ情報
    let surfaceInfo = materialSurfaceLst?.find((item) => item.id === estData.materialSurfaceIq3Id);
    if (surfaceInfo?.info?.unit === MaterialSurfaceBillingUnit.SquareMeter) {
      surfaceAddPrice = surfaceInfo ? surfaceInfo.info.price : 0;
      weightAddPrice = 0;
    } else {
      surfaceAddPrice = 0;
      weightAddPrice = surfaceInfo ? surfaceInfo.info.price : 0;
    }

    let materialDetailInfo = estData.materialDetailSizes?.filter((item) => item.isUsed)?.[0];
    let materialPattern1Info = estData.materialDetailPatterns?.filter(
      (item) => item.sizeName === materialDetailInfo?.sizeName && item.patternType === 1
    )?.[0];
    let materialPattern2Info = estData.materialDetailPatterns?.filter(
      (item) => item.sizeName === materialDetailInfo?.sizeName && item.patternType === 2
    )?.[0];
    // 材料粗利係数
    let materialCoeff = 0;
    let curMaterialTypeIq3 = selectedData?.estimateProducts?.calcParameters?.parameters?.materialTypeIq3?.filter(
      (item) => item.id === estData?.materialTypeIq3Id
    );
    if (curMaterialTypeIq3?.length > 0) {
      materialCoeff = curMaterialTypeIq3?.[0]?.info.lossCoeff;
      materialCoeff = materialCoeff ? materialCoeff : 1;
    }
    const resultData = [];
    let estimateUnit = 0;
    let estimateTotal = 0;
    let materialUnit = 0;
    let smCostUnit = 0;
    let smPurchaseUnit = 0;
    let materialTotal = 0;
    let smCostTotal = 0;
    let smPurchaseTotal = 0;

    materialTotal = estData.materialCostTotalPrice * materialCoeff; // 子部品毎に材料の見積金額＝BB行（材料費＊材料粗利係数）
    smCostTotal = estData.workCostTotalPrice * (coeffsTotal - 2) * coeffMng; // 子部品毎に板金工程の見積金額＝BC行｛原価*(取引先係数＋受注形態係数＋納期係数-2)*管理係数｝
    smPurchaseTotal = estData.purchaseTotalPrice * coeffPurchase; // 子部品毎に購入品の見積金額＝BD行（購入品費＊購入品係数or仕入係数）

    materialUnit = Number.isFinite(materialTotal / manufacturingCnt) ? materialTotal / manufacturingCnt : 0; // 子部品毎に材料の見積単価＝AY行（材料費＊材料粗利係数）÷員数
    smCostUnit = Number.isFinite(smCostTotal / manufacturingCnt) ? smCostTotal / manufacturingCnt : 0; // 子部品毎に板金工程の見積単価＝AZ行｛原価*(取引先係数＋受注形態係数＋納期係数-2)*管理係数｝÷員数
    smPurchaseUnit = Number.isFinite(smPurchaseTotal / manufacturingCnt) ? smPurchaseTotal / manufacturingCnt : 0; // 子部品毎に購入品の見積単価＝BA行（購入品費＊購入品係数or仕入係数）÷員数

    estimateTotal = materialTotal + smCostTotal + smPurchaseTotal; // 子部品毎の見積金額＝AW行（板金工程の見積金額＋材料見積金額＋購入品見積金額）
    estimateUnit = Number.isFinite(estimateTotal / manufacturingCnt) ? estimateTotal / manufacturingCnt : 0; // 子部品毎の見積単価＝AV行（板金工程の見積金額＋材料見積金額＋購入品見積金額）÷員数

    const sheet5Object = {
      no: index + 1,
      drawingNo: estData.drawingNo,
      name: estData.name,
      partCount: estData.partCount,
      prodNo: prodNo ? prodNo : 0,
      partSizeX: estData.partSizeX ? toDecimal(estData.partSizeX, 2) : '',
      partSizeY: estData.partSizeY ? toDecimal(estData.partSizeY, 2) : '',
      '(空白)': '',
      材料名称: estData.materialName,
      材質: estData.materialTypeName,
      板厚: thickness,
      材料比重: hijyu,
      表面保護属性: surfaceProtection,
      重量: estData.weight ? toDecimal(estData.weight, 2) : '',
      表面積: estData.totalSurfaceArea ? toDecimal(estData.totalSurfaceArea, 2) : '',
      合計金額: estData.materialCostTotalPrice ? Math.round(estData.materialCostTotalPrice) : 0,
      歩留: materialDetailInfo ? materialDetailInfo.yield : '',
      枚数: materialDetailInfo ? materialDetailInfo.materialQuantity : '',
      重量単価: weightUnitPrice ? Math.round(weightUnitPrice) : 0,
      表面加算: surfaceAddPrice,
      重量加算: weightAddPrice,
      材料単価: 0, // 材料詳細画面から外したため、空白に設定
      原価率: '', // 材料詳細画面から外したため、空白に設定
      スクラップ: '', // 材料詳細画面から外したため、空白に設定
      合計金額1: materialPattern1Info?.totalPrice ? Math.round(materialPattern1Info?.totalPrice) : 0,
      歩留1: materialPattern1Info ? materialPattern1Info.yield : '',
      枚数1: materialPattern1Info ? materialPattern1Info.materialQuantity : '',
      材料サイズ選択: materialPattern1Info ? materialPattern1Info?.sizeName : '',
      サイズX: materialPattern1Info ? materialPattern1Info?.sizeX : '',
      サイズY: materialPattern1Info ? materialPattern1Info?.sizeY : '',
      合計金額2: materialPattern2Info?.totalPrice ? Math.round(materialPattern2Info?.totalPrice) : 0,
      歩留2: materialPattern2Info ? materialPattern2Info.yield : '',
      枚数2: materialPattern2Info ? materialPattern2Info.materialQuantity : '',
      材料サイズ選択1: materialPattern2Info ? materialPattern2Info?.sizeName : '',
      サイズX1: materialPattern2Info ? materialPattern2Info?.sizeX : '',
      サイズY1: materialPattern2Info ? materialPattern2Info?.sizeY : '',
      '(空白)1': '',
      単価: estData.costUnitPrice ? Math.round(estData.costUnitPrice) : 0,
      合計: estData.costTotalPrice ? Math.round(estData.costTotalPrice) : 0,
      '(空白)2': '',
      材料単価1: estData.materialCostUnitPrice ? Math.round(estData.materialCostUnitPrice) : 0,
      加工単価: estData.workCostUnitPrice ? Math.round(estData.workCostUnitPrice) : 0,
      購入品単価: estData.purchaseUnitPrice ? Math.round(estData.purchaseUnitPrice) : 0,
      材料合計: estData.materialCostTotalPrice ? Math.round(estData.materialCostTotalPrice) : 0,
      加工合計: estData.workCostTotalPrice ? Math.round(estData.workCostTotalPrice) : 0,
      購入品合計: estData.purchaseTotalPrice ? Math.round(estData.purchaseTotalPrice) : 0,
      '(空白)3': '',
      単価1: estimateUnit ? Math.round(estimateUnit) : 0, // 子部品毎の見積単価＝AV行（板金工程の見積金額＋材料見積金額＋購入品見積金額）÷員数
      合計1: estimateTotal ? Math.round(estimateTotal) : 0, // 子部品毎の見積金額＝AW行（板金工程の見積金額＋材料見積金額＋購入品見積金額）
      '(空白)4': '',
      材料単価2: materialUnit ? Math.round(materialUnit) : 0, // 子部品毎に材料の見積単価＝AY行（材料費＊材料粗利係数）÷員数
      加工単価1: smCostUnit ? Math.round(smCostUnit) : 0, // 子部品毎に板金工程の見積単価＝AZ行｛原価*(取引先係数＋受注形態係数＋納期係数-2)*管理係数｝÷員数
      購入品単価1: smPurchaseUnit ? Math.round(smPurchaseUnit) : 0, // 子部品毎に購入品の見積単価＝BA行（購入品費＊購入品係数or仕入係数）÷員数
      材料合計1: materialTotal ? Math.round(materialTotal) : 0, // 子部品毎に材料の見積金額＝BB行（材料費＊材料粗利係数）
      加工合計1: smCostTotal ? Math.round(smCostTotal) : 0, // 子部品毎に板金工程の見積金額＝BC行｛原価*(取引先係数＋受注形態係数＋納期係数-2)*管理係数｝
      購入品合計1: smPurchaseTotal ? Math.round(smPurchaseTotal) : 0, // 子部品毎に購入品の見積金額＝BD行（購入品費＊購入品係数or仕入係数）
      '(空白)5': '',
    };

    // 各工程の段取と作業情報
    let isSmDeburring = false;
    let smDeburringAutoId = usedProcessList?.filter((item) => item.id.workType === WorkType.SmDeburringAuto)?.[0]?.id
      .id;
    let smDeburringManualId = usedProcessList?.filter((item) => item.id.workType === WorkType.SmDeburringManual)?.[0]
      ?.id?.id;
    const bariMatchedData = Object.values(iq3KoutebetsuInfos).find((obj) => {
      return obj.processId === smDeburringAutoId || obj.processId === smDeburringManualId;
    });
    let isBlank = false;
    let laserId = usedProcessList?.filter((item) => item.id.workType === WorkType.SmBlankLaser)?.[0]?.id.id;
    let punchId = usedProcessList?.filter((item) => item.id.workType === WorkType.SmBlankPunch)?.[0]?.id?.id;
    let combiId = usedProcessList?.filter((item) => item.id.workType === WorkType.SmBlankCombi)?.[0]?.id?.id;
    const blankMatchedData = Object.values(iq3KoutebetsuInfos).find((obj) => {
      return obj.processId === laserId || obj.processId === punchId || obj.processId === combiId;
    });
    usedProcessList?.forEach((item, index) => {
      let setupTime = 0;
      let setupAmt = 0;
      let sagyoTime = 0;
      let sagyoAmt = 0;
      const id = item.id.id;
      const matchedData = Object.values(iq3KoutebetsuInfos).find((obj) => {
        return obj.processId === id;
      });
      if (matchedData) {
        // 段取金額
        setupTime = matchedData?.setupTime ? matchedData?.setupTime : 0;
        // 段取時間
        setupAmt = matchedData?.setupAmount ? matchedData?.setupAmount : 0;
        // 作業時間
        sagyoTime = matchedData?.processingTime ? matchedData?.processingTime : 0;
        // 作業金額
        sagyoAmt = matchedData?.processingAmount ? matchedData?.processingAmount : 0;
        //表面処理の場合
        if (item.id.workType === WorkType.SmSurface) {
          resultData[id] = {
            menseki: estData.totalSurfaceArea ? toDecimal(estData.totalSurfaceArea, 2) : '',
            jyuuryou: estData.weight ? toDecimal(estData.weight, 2) : '',
            [`setupTime${id}`]: setupTime ? secondsToHms(setupTime) : timeHmsStr,
            [`space1${id}`]: timeHmsStr,
            [`setupAmount${id}`]: setupAmt ? Math.round(setupAmt) : 0,
            [`space2${id}`]: 0,
            [`processingAmount${id}`]: sagyoAmt ? Math.round(sagyoAmt) : 0,
          };
        } else if (item.id.workType === WorkType.SmDeburringAuto || item.id.workType === WorkType.SmDeburringManual) {
          if (!isSmDeburring) {
            isSmDeburring = true;
            let setupTimeAuto = 0;
            let processingTimeAuto = 0;
            let setupAmountAuto = 0;
            let processingAmountAuto = 0;
            let setupTimeManual = 0;
            let processingTimeManual = 0;
            let setupAmountManual = 0;
            let processingAmountManual = 0;
            const autoMatchedData = Object.values(iq3KoutebetsuInfos).find((obj) => {
              return obj.processId === smDeburringAutoId;
            });
            if (autoMatchedData) {
              setupTimeAuto = autoMatchedData?.setupTime ? autoMatchedData?.setupTime : 0;
              processingTimeAuto = autoMatchedData?.processingTime ? autoMatchedData?.processingTime : 0;
              setupAmountAuto = autoMatchedData?.setupAmount ? autoMatchedData?.setupAmount : 0;
              processingAmountAuto = autoMatchedData?.processingAmount ? autoMatchedData?.processingAmount : 0;
            }
            const manualMatchedData = Object.values(iq3KoutebetsuInfos).find((obj) => {
              return obj.processId === smDeburringManualId;
            });
            if (manualMatchedData) {
              setupTimeManual = manualMatchedData?.setupTime ? manualMatchedData?.setupTime : 0;
              processingTimeManual = manualMatchedData?.processingTime ? manualMatchedData?.processingTime : 0;
              setupAmountManual = manualMatchedData?.setupAmount ? manualMatchedData?.setupAmount : 0;
              processingAmountManual = manualMatchedData?.processingAmount ? manualMatchedData?.processingAmount : 0;
            }

            let setupTime = setupTimeAuto + setupTimeManual;
            let processingTime = processingTimeAuto + processingTimeManual;
            let setupAmount = setupAmountAuto + setupAmountManual;
            let processingAmount = processingAmountAuto + processingAmountManual;

            resultData[id] = {
              [`setupTime${id}`]: setupTime ? secondsToHms(setupTime) : timeHmsStr,
              [`processingTime${id}`]: processingTime ? secondsToHms(processingTime) : timeHmsStr,
              [`space1${id}`]: timeHmsStr,
              [`setupAmount${id}`]: setupAmount ? Math.round(setupAmount) : 0,
              [`processingAmount${id}`]: processingAmount ? Math.round(processingAmount) : 0,
              [`space2${id}`]: 0,
            };
          }
        } else if (item.id.workType === WorkType.SmInspection) {
          resultData[id] = {
            [`setupTime${id}`]: setupTime ? secondsToHms(setupTime) : timeHmsStr,
            [`processingTime${id}`]: sagyoTime ? secondsToHms(sagyoTime) : timeHmsStr,
            [`setupAmount${id}`]: setupAmt ? Math.round(setupAmt) : 0,
            [`processingAmount${id}`]: sagyoAmt ? Math.round(sagyoAmt) : 0,
          };
        } else if (item.id.workType === WorkType.SmCustom) {
          resultData[id] = {
            [`setupTime${id}`]: setupTime ? secondsToHms(setupTime) : timeHmsStr,
            [`processingTime${id}`]: sagyoTime ? secondsToHms(sagyoTime) : timeHmsStr,
            [`setupAmount${id}`]: setupAmt ? Math.round(setupAmt) : 0,
            [`processingAmount${id}`]: sagyoAmt ? Math.round(sagyoAmt) : 0,
          };
        } else {
          resultData[id] = {
            [`setupTime${id}`]: setupTime ? secondsToHms(setupTime) : timeHmsStr,
            [`processingTime${id}`]: sagyoTime ? secondsToHms(sagyoTime) : timeHmsStr,
            [`space1${id}`]: timeHmsStr,
            [`setupAmount${id}`]: setupAmt ? Math.round(setupAmt) : 0,
            [`processingAmount${id}`]: sagyoAmt ? Math.round(sagyoAmt) : 0,
            [`space2${id}`]: 0,
          };
        }
      } else {
        if (
          item.id.workType === WorkType.SmBlankLaser ||
          item.id.workType === WorkType.SmBlankPunch ||
          item.id.workType === WorkType.SmBlankCombi
        ) {
          if (!isBlank && blankMatchedData === undefined) {
            isBlank = true;
            resultData[id] = {
              [`setupTime${id}`]: timeHmsStr,
              [`processingTime${id}`]: timeHmsStr,
              [`space1${id}`]: timeHmsStr,
              [`processingAmount${id}`]: 0,
              [`setupAmount${id}`]: 0,
              [`space2${id}`]: 0,
            };
          }
        } else if (item.id.workType === WorkType.SmDeburringAuto || item.id.workType === WorkType.SmDeburringManual) {
          if (!isSmDeburring && bariMatchedData === undefined) {
            isSmDeburring = true;
            resultData[id] = {
              [`setupTime${id}`]: timeHmsStr,
              [`processingTime${id}`]: timeHmsStr,
              [`space1${id}`]: timeHmsStr,
              [`processingAmount${id}`]: 0,
              [`setupAmount${id}`]: 0,
              [`space2${id}`]: 0,
            };
          }
        } else if (
          item.id.workType !== WorkType.SmSurface &&
          item.id.workType !== WorkType.SmCustom &&
          item.id.workType !== WorkType.SmInspection
        ) {
          resultData[id] = {
            [`setupTime${id}`]: timeHmsStr,
            [`processingTime${id}`]: timeHmsStr,
            [`space1${id}`]: timeHmsStr,
            [`processingAmount${id}`]: 0,
            [`setupAmount${id}`]: 0,
            [`space2${id}`]: 0,
          };
        } else if (item.id.workType === WorkType.SmInspection || item.id.workType === WorkType.SmCustom) {
          resultData[id] = {
            [`setupTime${id}`]: timeHmsStr,
            [`processingTime${id}`]: timeHmsStr,
            [`setupAmount${id}`]: 0,
            [`processingAmount${id}`]: 0,
          };
        } else {
          resultData[id] = {
            menseki: '',
            jyuuryou: '',
            [`setupTime${id}`]: timeHmsStr,
            [`space1${id}`]: timeHmsStr,
            [`setupAmount${id}`]: 0,
            [`space2${id}`]: 0,
            [`processingAmount${id}`]: 0,
          };
        }
      }
    });
    const mergedSheet5Object = [sheet5Object, ...resultData];
    const transformedObject = mergedSheet5Object?.reduce((result, item) => {
      for (const key in item) {
        result[key] = item[key];
      }
      return result;
    }, {});
    sheet5Data.push(transformedObject);
  });

  return sheet5Data;
};

const types = [
  { id: 1, value: '丸', label: '丸' },
  { id: 2, value: '角', label: '角' },
  { id: 3, value: '長角', label: '長角' },
  { id: 4, value: '長丸', label: '長丸' },
  { id: 5, value: 'その他', label: 'その他' },
];

const getSheet6Data = (selectedData) => {
  const sheet6Data = [];
  let calParam = selectedData?.estimateProducts?.calcParameters?.parameters;
  let selectedIq3Data = selectedData?.esIq3Info;
  selectedIq3Data?.forEach((estData, index) => {
    sheet6Data[index] = {};
    let processSelect = estData.processSelect.details; //isUsedForCalcArrPerItem[index];
    let processInput = estData.processInput;
    let manufacturingCnt = selectedData?.estimateProducts?.quantity * estData.partCount;
    const sheet6Object = [
      {
        no: index + 1,
        drawingNo: estData.drawingNo,
        name: estData.name,
        partCount: estData.partCount,
        prodNo: manufacturingCnt ? manufacturingCnt : 0,
        '(空白)': '',
      },
    ];
    sheet6Data[index]['partInfo'] = sheet6Object;
    let usedProcessList = processSelect?.filter((detail) => detail.isUsedForCalc);
    usedProcessList?.forEach((item) => {
      let processMst = calParam?.process?.find((process) => process?.id === item?.processId);
      let processCode = processMst?.code;
      let processName = processMst?.name;
      if (sheet6Data[index][processName] === undefined) {
        sheet6Data[index][processName] = [];
      }
      const id = item?.processId;
      let matchedData = processSelect?.find((process) => process.processId === id && process.deviceId > 0);
      if (id === 23 || id === 24 || id === 25 || id === 26 || id === 27) {
        matchedData = processSelect?.find(
          (process) =>
            process.processId === id &&
            process.deviceId > 0 &&
            process?.multiprocesses?.[0]?.processDetailTypeId === item?.multiprocesses?.[0]?.processDetailTypeId
        );
      }
      const matchedProcessInput = processInput?.find((process) => process.processId === id);
      let obj = {};
      if (matchedData?.isUsedForCalc) {
        let matchedDevice = calParam?.device?.find((devic) => devic?.id === matchedData?.deviceId);

        let deviceCode = matchedDevice === undefined ? '' : matchedDevice?.code;
        let deviceName = matchedDevice === undefined ? '' : matchedDevice?.name;

        obj.processCode = processCode;
        obj.deviceCode = deviceCode;
        obj.deviceName = deviceName;
        obj.processName = processName;
        obj.processId = processMst?.id;

        if (id === 10) {
          let element = matchedProcessInput?.details?.[0]?.elements;
          obj.element = element ? Number(element) : 0;
          obj['(空白)'] = '';
          sheet6Data[index][processName]?.push(obj);
        } else if (id === 11 || id == 12 || id == 13) {
          if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
            matchedProcessInput?.details?.[0]?.quantityList?.forEach((item, i) => {
              let matchedDetailType = types?.find((detailType) => detailType.id === item.types);
              let obj = {};
              obj.processCode = processCode;
              obj.deviceCode = deviceCode;
              obj.deviceName = deviceName;
              obj.detailCode = i + 1;
              obj.detailName = matchedDetailType?.label;
              obj.innerDia = i === 0 ? matchedProcessInput?.details?.[0]?.dimensionData?.innerDia : '';
              obj.outerDia = i === 0 ? matchedProcessInput?.details?.[0]?.dimensionData?.outerDia : '';
              obj.airFeedDistance = i === 0 ? matchedProcessInput?.details?.[0]?.dimensionData?.airFeedDistance : '';
              obj.diameter = item.diameter;
              obj.xSize = item.xsize;
              obj.ySize = item.ysize;
              obj.perimeter = item.perimeter;
              obj.count = item.count ? Number(item.count) : '';
              obj['(空白)'] = '';
              sheet6Data[index][processName]?.push(obj);
            });
          } else {
            let obj = {};
            obj.innerDia = '';
            obj.outerDia = '';
            obj.airFeedDistance = '';
            obj['(空白)'] = '';
            sheet6Data[index][processName]?.push(obj);
          }
        } else if (id === 14) {
          let bendingArr = [];
          if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
            matchedProcessInput?.details?.[0]?.bendingList?.forEach((bending) => {
              let matchedDetailType = processMst?.details?.processDetailTypes?.find(
                (detailType) => detailType.id === bending.types
              );
              let obj1 = {};
              obj1.processCode = processCode;
              obj1.deviceCode = deviceCode;
              obj1.deviceName = deviceName;
              obj1.detailCode = matchedDetailType?.code;
              obj1.detailName = matchedDetailType?.name;
              obj1.bendLength = bending?.bendLength;
              obj1.bendCount = bending?.lines ? Number(bending?.lines) : '';
              if (bending.types == 3) {
                obj1.bendCountFR = bending?.bendCount;
              } else {
                obj1.bendCountFR = '';
              }
              //bendingArr?.push(obj);
              obj1['(空白)'] = '';
              sheet6Data[index][processName]?.push(obj1);
            });
          } else {
            obj.detailCode = '';
            obj.detailName = '';
            obj.bendCount = '';
            obj.bendCountFR = '';
            //bendingArr?.push(obj);
            obj['(空白)'] = '';
            sheet6Data[index][processName]?.push(obj);
          }
        } else if (id === 15) {
          if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
            obj.detailCode = processMst?.code;
            obj.detailName = processMst?.name;

            obj.area = matchedProcessInput?.details?.[0]?.area;
            obj.weight = matchedProcessInput?.details?.[0]?.weight;
            obj.outerLength = '';
            obj.innerLength = '';
            obj.count = matchedProcessInput?.details?.[0]?.count;
            obj['(空白)'] = '';

            sheet6Data[index][processName]?.push(obj);
          } else {
            obj.detailCode = '';
            obj.detailName = '';
            obj.area = '';
            obj.weight = '';
            obj.outerLength = '';
            obj.innerLength = '';
            obj.count = '';
            obj['(空白)'] = '';
            sheet6Data[index][processName]?.push(obj);
          }
        } else if (id === 16) {
          if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
            obj.detailCode = processMst?.code;
            obj.detailName = processMst?.name;

            obj.area = '';
            obj.weight = '';

            obj.outerLength = matchedProcessInput?.details?.[0]?.length;
            obj.outerCount = matchedProcessInput?.details?.[0]?.count;
            obj.innerLength = matchedProcessInput?.details?.[1]?.length;
            obj.innerCount = matchedProcessInput?.details?.[1]?.count;
            obj['(空白)'] = '';

            sheet6Data[index][processName]?.push(obj);
          } else {
            obj.detailCode = '';
            obj.detailName = '';
            obj.area = '';
            obj.weight = '';
            obj.outerLength = '';
            obj.innerLength = '';
            obj.outerCount = '';
            obj.innerCount = '';
            obj['(空白)'] = '';
            sheet6Data[index][processName]?.push(obj);
          }
        } else if (id === 17) {
          let hyomenArr = [];
          if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
            matchedProcessInput?.details?.forEach((hyomen) => {
              let matchedDetailType = processMst?.details?.processDetailTypes?.find(
                (detailType) => detailType.id === hyomen.processDetailTypeNo
              );
              let obj1 = {};
              let calType = hyomen?.calcType == 1 || hyomen?.calcType == 2 ? '面積' : '重量';
              let areaWeight =
                hyomen?.calcType == 1 || hyomen?.calcType == 2 ? hyomen?.areaUnitPrice : hyomen?.weightUnitPrice;
              obj1.processCode = processCode;
              obj1.deviceCode = deviceCode;
              obj1.deviceName = deviceName;
              obj1.detailCode = matchedDetailType?.code;
              obj1.detailName = matchedDetailType?.name;
              obj1.calType = calType;
              obj1.areaWeight = areaWeight;
              obj1.count = hyomen?.calcType == 1 || hyomen?.calcType == 2 ? hyomen?.areaCount : hyomen?.weightCount;
              obj1['(空白)'] = '';
              sheet6Data[index][processName]?.push(obj1);
            });
          } else {
            obj.detailCode = '';
            obj.detailName = '';
            obj.areaWeight = '';
            obj.count = '';
            obj['(空白)'] = '';
            sheet6Data[index][processName]?.push(obj);
          }
        } else if (id === 18) {
          let nijiArr = [];
          if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
            matchedProcessInput?.details?.forEach((niji) => {
              let matchedDetailType = processMst?.details?.processDetailTypes?.find(
                (detailType) => detailType.id === niji.types
              );
              let obj1 = {};
              obj1.processCode = processCode;
              obj1.deviceCode = deviceCode;
              obj1.deviceName = deviceName;
              obj1.detailCode = matchedDetailType?.code;
              obj1.detailName = matchedDetailType?.name;
              obj1.count = niji?.count ? Number(niji?.count) : 0;
              obj1.x = estData?.partSizeX;
              obj1.y = estData?.partSizeY;
              obj1.perimeter = niji?.diameter;
              //nijiArr?.push(obj);
              obj1['(空白)'] = '';
              sheet6Data[index][processName]?.push(obj1);
            });
          } else {
            obj.detailCode = '';
            obj.detailName = '';
            obj.count = '';
            obj.x = '';
            obj.y = '';
            obj.perimeter = '';
            //nijiArr?.push(obj);
            obj['(空白)'] = '';
            sheet6Data[index][processName]?.push(obj);
          }
        } else if (id === 19) {
          let yosetsuArr = [];
          if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
            matchedProcessInput?.details?.[0]?.weldingList?.forEach((weld) => {
              let matchedDetailType = processMst?.details?.processDetailTypes?.find(
                (detailType) => detailType.id === weld.yousetsuType
              );
              let obj1 = {};
              obj1.processCode = processCode;
              obj1.deviceCode = deviceCode;
              obj1.deviceName = deviceName;
              obj1.detailCode = matchedDetailType?.code;
              obj1.detailName = matchedDetailType?.name;
              obj1.count = weld?.senchouKaisu ? Number(weld?.senchouKaisu) : 0;
              obj1.type = '線長';
              obj1.number = weld?.senchou ? toDecimal(weld?.senchou, 2) : toDecimal(0, 2); //連続/タップ溶接の長さ // IQX_WEBEST-193
              obj1.yousetsushiage = weld?.senchouYousetsushiage == 0 ? '適用' : '除外';
              obj1['(空白)'] = '';
              sheet6Data[index][processName]?.push(obj1);
              // 仮付け情報追加
              let obj2 = {};
              obj2.processCode = processCode;
              obj2.deviceCode = deviceCode;
              obj2.deviceName = deviceName;
              obj2.detailCode = matchedDetailType?.code;
              obj2.detailName = matchedDetailType?.name;
              obj2.count = weld?.karitsukeKaisu ? Number(weld?.karitsukeKaisu) : 0;
              obj2.type = '仮付';
              obj2.number = weld?.karitsukeCnt;
              obj2.yousetsushiage = '';
              obj2['(空白)'] = '';
              sheet6Data[index][processName]?.push(obj2);
            });
            matchedProcessInput?.details?.[0]?.subWeldingList?.forEach((subWeld) => {
              let matchedDetailType = processMst?.details?.processDetailTypes?.find(
                (detailType) => detailType.id === subWeld.subWeldingType
              );
              let obj1 = {};
              obj1.processCode = processCode;
              obj1.deviceCode = deviceCode;
              obj1.deviceName = deviceName;
              obj1.detailCode = matchedDetailType?.code;
              obj1.detailName = matchedDetailType?.name;
              obj1.count = subWeld.count ? Number(subWeld.count) : 0;
              obj1.type = subWeld.daimeter ? subWeld.daimeter : 0;
              obj1.number = subWeld.number ? subWeld.number : 0;
              obj1.yousetsushiage = subWeld?.yousetsushiage == 0 ? '適用' : '除外';
              // yosetsuArr?.push(obj);
              obj['(空白)'] = '';
              sheet6Data[index][processName]?.push(obj1);
            });
          } else {
            obj.detailCode = '';
            obj.detailName = '';
            obj.count = '';
            obj.type = '';
            obj.number = '';
            //yosetsuArr?.push(obj);
            obj['(空白)'] = '';
            sheet6Data[index][processName]?.push(obj);
          }
        } else if (id === 20) {
          let shiageArr = [];
          let matchedProcessInput = processInput?.find((process) => process.processId === 19);
          let yousetsuShiagePattern = processSelect
            ? processSelect?.filter((item) => item.processId == processMst?.id)
            : [];
          //工程選択から処理情報取得
          let multiprocesses = yousetsuShiagePattern[0]?.multiprocesses;
          if (
            (matchedProcessInput?.details?.[0]?.weldingList !== undefined &&
              matchedProcessInput?.details?.[0]?.weldingList?.length > 0) ||
            (matchedProcessInput?.details?.[0]?.subWeldingList !== undefined &&
              matchedProcessInput?.details?.[0]?.subWeldingList?.length > 0)
          ) {
            // マイン溶接の仕上情報
            matchedProcessInput?.details?.[0]?.weldingList?.forEach((weld) => {
              if (weld.senchouYousetsushiage == 0) {
                for (let i = 0; i < multiprocesses?.length; i++) {
                  let matchedDetailType = processMst?.details?.processDetailTypes?.find(
                    (detailType) => detailType.id === multiprocesses[i].processDetailTypeId
                  );
                  if (matchedDetailType?.name == undefined || matchedDetailType?.length == 0) continue;
                  let obj1 = {};
                  obj1.processCode = processCode;
                  obj1.deviceCode = deviceCode;
                  obj1.deviceName = deviceName;
                  obj1.detailCode = matchedDetailType?.code;
                  obj1.detailName = matchedDetailType?.name;
                  obj1.count = weld?.senchouKaisu ? Number(weld?.senchouKaisu) : 0;
                  obj1.type = '線長';
                  obj1.number = weld?.senchou ? toDecimal(weld?.senchou, 2) : toDecimal(0, 2); //連続/タップ溶接の長さ // IQX_WEBEST-193
                  obj1['(空白)'] = '';
                  sheet6Data[index][processName]?.push(obj1);
                }
              }
            });

            // サブ溶接の仕上情報
            matchedProcessInput?.details?.[0]?.subWeldingList?.forEach((subWeld) => {
              if (subWeld.yousetsushiage == 0) {
                for (let i = 0; i < multiprocesses?.length; i++) {
                  let matchedDetailType = processMst?.details?.processDetailTypes?.find(
                    (detailType) => detailType.id === multiprocesses[i].processDetailTypeId
                  );
                  if (matchedDetailType?.name == undefined || matchedDetailType?.length == 0) continue;
                  let obj1 = {};
                  obj1.processCode = processCode;
                  obj1.deviceCode = deviceCode;
                  obj1.deviceName = deviceName;
                  obj1.detailCode = matchedDetailType?.code;
                  obj1.detailName = matchedDetailType?.name;
                  obj1.count = subWeld.count ? Number(subWeld.count) : 0;
                  obj1.type = subWeld.daimeter ? subWeld.daimeter : 0;
                  obj1.number = subWeld.number ? subWeld.number : 0;
                  obj1['(空白)'] = '';
                  sheet6Data[index][processName]?.push(obj1);
                }
              }
            });
          } else {
            obj.detailCode = '';
            obj.detailName = '';
            obj.count = '';
            obj.type = '';
            obj.number = '';
            obj['(空白)'] = '';
            sheet6Data[index][processName]?.push(obj);
          }
          //shiageArr?.push(obj);
        } else if (id === 21 || id === 22) {
          if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
            let numOfCuts = matchedProcessInput?.details?.[0]?.numOfCuts;
            obj.numOfCuts = numOfCuts;
          } else {
            obj.numOfCuts = '';
          }
          obj['(空白)'] = '';
          sheet6Data[index][processName]?.push(obj);
        } else if (id === 23 || id === 24 || id === 25 || id === 26 || id === 27) {
          if (matchedData?.multiprocesses !== undefined && matchedData?.multiprocesses?.length > 0) {
            let matchedDetailType = processMst?.details?.processDetailTypes?.find(
              (detailType) => detailType.id === matchedData?.multiprocesses?.[0]?.processDetailTypeId
            );
            obj.detailCode = matchedDetailType?.code;
            obj.detailName = matchedDetailType?.name;
            obj.count = matchedData?.multiprocesses?.length;
          } else {
            obj.detailCode = '';
            obj.detailName = '';
            obj.count = '';
          }
          obj['(空白)'] = '';
          sheet6Data[index][processName]?.push(obj);
        }
      } else {
        obj.processCode = '';
        obj.deviceCode = '';
        obj.deviceName = '';
        obj.detailCode = '';
        obj.detailName = '';
        obj.processName = processName;
        if (id === 10) {
          obj.element = '';
          obj['(空白)'] = '';
          sheet6Data[index][processName]?.push(obj);
        } else if (id === 11 || id == 12 || id == 13) {
          obj.innerDia = '';
          obj.outerDia = '';
          obj.airFeedDistance = '';
          obj['(空白)'] = '';
          sheet6Data[index][processName]?.push(obj);
        } else if (id === 14) {
          let bending = [];

          obj.bendCount = '';
          obj.bendCountFR = '';
          obj['(空白)'] = '';
          //bending?.push(obj);

          sheet6Data[index][processName]?.push(obj);
        } else if (id === 15) {
          obj.area = '';
          obj.weight = '';
          obj.outerLength = '';
          obj.innerLength = '';
          obj.count = '';
          obj['(空白)'] = '';
          sheet6Data[index][processName]?.push(obj);
        } else if (id === 16) {
          obj.area = '';
          obj.weight = '';
          obj.outerLength = '';
          (obj.outerCount = ''), (obj.innerLength = '');
          obj.innerCount = '';
          obj['(空白)'] = '';
          sheet6Data[index][processName]?.push(obj);
        } else if (id === 17) {
          let hyomenArr = [];

          obj.areaWeight = '';
          obj.count = '';
          obj['(空白)'] = '';
          hyomenArr?.push(obj);

          sheet6Data[index][processName]?.push(obj);
        } else if (id === 18) {
          let nijiArr = [];
          obj.count = '';
          obj.x = '';
          obj.y = '';
          obj.perimeter = '';
          obj['(空白)'] = '';
          // nijiArr?.push(obj);

          sheet6Data[index][processName]?.push(obj);
        } else if (id === 19) {
          let yosetsuArr = [];
          obj.count = '';
          obj.type = '';
          obj.senchou = '';
          obj['(空白)'] = '';
          //  yosetsuArr?.push(obj);
          sheet6Data[index][processName]?.push(obj);
        } else if (id === 20) {
          let shiageArr = [];
          obj.detailCode = '';
          obj.detailName = '';
          obj.count = '';
          obj.type = '';
          obj.senchou = '';
          obj['(空白)'] = '';
          // shiageArr?.push(obj);
          sheet6Data[index][processName]?.push(obj);
        } else if (id === 21 || id === 22) {
          obj.numOfCuts = '';
          obj['(空白)'] = '';

          sheet6Data[index][processName]?.push(obj);
        } else if (id === 23 || id === 24 || id === 25 || id === 26 || id === 27) {
          obj.count = '';
          obj['(空白)'] = '';

          sheet6Data[index][processName]?.push(obj);
        }
      }
    });
  });
  return sheet6Data;
};

function incrementExcelColumn(column) {
  let numericValue = 0;
  for (let i = 0; i < column?.length; i++) {
    numericValue = numericValue * 26 + (column.charCodeAt(i) - 64);
  }
  numericValue++;
  let incrementedColumn = '';
  while (numericValue > 0) {
    const remainder = (numericValue - 1) % 26;
    incrementedColumn = String.fromCharCode(65 + remainder) + incrementedColumn;
    numericValue = Math.floor((numericValue - 1) / 26);
  }
  return incrementedColumn;
}

function calculateRowCounts(data) {
  const rowCountsArray = [];
  let addition = {};
  data?.forEach((item) => {
    const rowCounts = {};
    let largestRowCount = 0;
    for (const key in item) {
      addition[key] = addition[key] == undefined ? 0 : addition[key];
      if (Array.isArray(item[key]) && item[key][0]?.processCode !== '') {
        rowCounts[key] = item[key]?.length;
        addition[key] += item[key]?.length;
        largestRowCount = Math.max(largestRowCount, item[key]?.length);
      } else {
        rowCounts[key] = 0;
      }
    }
    rowCounts.largestRowCount = largestRowCount;
    rowCountsArray.push(rowCounts);
  });
  rowCountsArray['addition'] = addition;
  return rowCountsArray;
}

// const XLSX = require('xlsx');

const ExcelJS = require('exceljs');
const settingIndividualReportData = async (estimateData, iq3Data, stream, excelData) => {
  const workbookIndividual = new ExcelJS.Workbook();
  await workbookIndividual.xlsx.load(stream);
  workbookIndividual?.eachSheet(function (worksheet, sheetId) {
    if (worksheet?.name?.includes('ORG1')) {
      // ORG1データ記入
      settingORG1(estimateData, worksheet);
    } else if (worksheet?.name?.includes('ORG2')) {
      // ORG2データ記入
      let processData = createProcessDataTbl(estimateData);
      settingORG2(processData, worksheet, 3, estimateData);
    } else if (worksheet?.name?.includes('ORG3')) {
      // ORG3データ記入
      let processData = createProcessDataTbl2(estimateData);
      settingORG3(processData, worksheet, 3, estimateData);
    } else if (worksheet?.name?.includes('ORG4')) {
      // ORG4データ記入
      let childProcess = getChildProcessCount(estimateData, estimateData?.esIq3Info);
      settingORG4(childProcess, worksheet, 3);
    } else if (worksheet?.name?.includes('ORG5')) {
      // ORG5データ記入
      settingORG5(estimateData, worksheet);
    } else if (worksheet?.name?.includes('ORG6')) {
      // ORG6データ記入
      settingORG6(estimateData, worksheet);
    } else if (worksheet?.name?.includes('ORG7')) {
      // ORG7データ記入
      let curRowIndex = 2;
      let paPurchases = estimateData?.estimateProducts?.purchases?.filter((p) => p.isDeleted === false);
      // 親部品購入品一覧
      settingORG7(
        paPurchases,
        worksheet,
        curRowIndex,
        0,
        estimateData?.estimateProducts?.drawingNo,
        estimateData?.estimateProducts?.name,
        estimateData?.estimateProducts?.calcParameters?.parameters
      );
      if (paPurchases?.length > 0) {
        curRowIndex += estimateData?.estimateProducts?.purchases?.length;
      }
      // 板金購入品一覧
      for (let i = 0; i < iq3Data?.length; i++) {
        settingORG7(
          iq3Data?.[i]?.purchases,
          worksheet,
          curRowIndex,
          1,
          iq3Data?.[i]?.drawingNo,
          iq3Data?.[i]?.name,
          estimateData?.estimateProducts?.calcParameters?.parameters
        );
        curRowIndex += iq3Data?.[i]?.purchases?.length;
      }
    } else if (worksheet?.name?.includes('工程別合計')) {
      // 工程別合計
      settingKouteibetsuInfo(estimateData, worksheet);
    }
  });

  let uint8Array = await workbookIndividual.xlsx.writeBuffer();
  let blob = new Blob([uint8Array], { type: 'application/octet-binary' });
  let link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  let excelName = excelData.title + '(' + estimateData?.estimateProducts?.name + ')' + '.xlsx';
  link.download = excelName;
  link.click();
  link.remove();
};
const settingORG1 = (estimateData, worksheet) => {
  let estimateProduct = estimateData?.estimateProducts;
  let parameters = estimateData?.estimateProducts?.calcParameters?.parameters;
  // 材料粗利係数リスト
  let materialTypeLst = [];
  // 数量
  let qty = estimateData?.estimateProducts?.quantity ? Number(estimateData?.estimateProducts?.quantity) : 1;
  // 取引先係数＋納期係数＋受注形態係数
  let coeffClient = estimateData?.estimateProducts?.otherProduct?.coeffClient
    ? Number(estimateData?.estimateProducts?.otherProduct?.coeffClient)
    : 1;
  let coeffDeliveryDates = estimateData?.estimateProducts?.otherProduct?.coeffDeliveryDates
    ? Number(estimateData?.estimateProducts?.otherProduct?.coeffDeliveryDates)
    : 1;
  let coeffOrders = estimateData?.estimateProducts?.otherProduct?.coeffOrders
    ? Number(estimateData?.estimateProducts?.otherProduct?.coeffOrders)
    : 1;
  let coeffsTotal = coeffClient + coeffDeliveryDates + coeffOrders;
  // 管理係数
  let coeffMng = estimateData?.estimateProducts?.otherProduct?.coeffManagements
    ? Number(estimateData?.estimateProducts?.otherProduct?.coeffManagements)
    : 1;
  let coeffPurchase = estimateData?.estimateProducts?.otherProduct?.coeffPurchases
    ? Number(estimateData?.estimateProducts?.otherProduct?.coeffPurchases)
    : 1;
  let materialTotal = 0; // （材料費＊材料粗利係数）
  let costTotal = 0; // ｛原価*(取引先係数＋受注形態係数＋納期係数-2)*管理係数｝
  let purchaseTotal = 0; // （購入品費＊購入品係数or仕入係数）
  let materialUnit = 0; // （材料費＊材料粗利係数）÷数量
  let costUnit = 0; // ｛原価*(取引先係数＋受注形態係数＋納期係数-2)*管理係数｝÷数量
  let purchaseUnit = 0; // （購入品費＊購入品係数or仕入係数）÷数量
  let iq3EstimateMaterialTotalPrices = 0;
  let iq3EstimateMaterialMngTotalPrices = 0;
  let iq3EstimateMaterialUnitMngPrices = 0;

  for (let s = 0; s < estimateData?.esIq3Info?.length; s++) {
    let materialCoeff = 1;
    // 材料粗利係数
    let curMaterialTypeIq3 = parameters?.materialTypeIq3?.filter(
      (item) => item.id === estimateData?.esIq3Info[s]?.materialTypeIq3Id
    );
    if (curMaterialTypeIq3?.length > 0) {
      materialCoeff = curMaterialTypeIq3?.[0]?.info.lossCoeff;
      materialCoeff = materialCoeff ? materialCoeff : 1;
      materialTypeLst.push(curMaterialTypeIq3?.[0]);
    }
    // 各板金の材料見積
    let iq3EstimateMaterialTotalPrice = estimateData?.esIq3Info[s]?.materialCostTotalPrice * materialCoeff;
    // 各板金の材料見積総額
    iq3EstimateMaterialTotalPrices += iq3EstimateMaterialTotalPrice;

    // 各板金の材料管理費総額　（材料原価*(材料粗利係数-1)）
    let iq3EstimateMaterialMngTotalPrice = estimateData?.esIq3Info[s]?.materialCostTotalPrice * (materialCoeff - 1);
    iq3EstimateMaterialMngTotalPrices += iq3EstimateMaterialMngTotalPrice;
  }
  // 材料見積総額
  materialTotal = iq3EstimateMaterialTotalPrices ? iq3EstimateMaterialTotalPrices : 0;
  // 材料見積単価
  materialUnit = Number.isFinite(materialTotal / qty) ? materialTotal / qty : 0;
  // 材料管理費単価
  iq3EstimateMaterialUnitMngPrices = Number.isFinite(iq3EstimateMaterialMngTotalPrices / qty)
    ? iq3EstimateMaterialMngTotalPrices / qty
    : 0;

  // 製造見積総額
  costTotal =
    coeffsTotal && coeffMng
      ? estimateData?.estimateProducts?.manufacturingCostTotalPrice * (coeffsTotal - 2) * coeffMng
      : 0;
  // 製造見積単価
  costUnit = Number.isFinite(costTotal / qty) ? costTotal / qty : 0;
  // 製造管理費総額（製造管理費相当＝製造原価*(取引先係数+納期係数+受注係数-2)*(管理係数-1)）
  let costMngTotal =
    coeffsTotal && coeffMng
      ? estimateData?.estimateProducts?.manufacturingCostTotalPrice * (coeffsTotal - 2) * (coeffMng - 1)
      : 0;
  // 製造管理費単価
  let costMngUnit = Number.isFinite(costMngTotal / qty) ? costMngTotal / qty : 0;

  // 親部品の購入品合計
  let parentPurchase = estimateData?.estimateProducts?.purchases;
  let parPurUnitPrice = 0;
  let parPurTotalPrice = 0;
  for (let p = 0; p < parentPurchase?.length; p++) {
    if (parentPurchase[p]?.isDeleted === false) {
      parPurUnitPrice += parentPurchase[p]?.price;
      // ファストリリースに単価保持を一応解除(2023/11/10)
      parPurTotalPrice += parentPurchase[p]?.totalPrice;
    }
  }
  // 板金の購入品合計
  let iq3TotalPurchaseTotalPrice = estimateData?.esIq3Info?.reduce((total, purchaseitem) => {
    return total + parseFloat(purchaseitem.purchaseTotalPrice);
  }, 0);
  let totalPurchaseCost = parPurTotalPrice + iq3TotalPurchaseTotalPrice;
  // 購入品費見積総額(購入品費*"仕入先係数or購入品係数")
  purchaseTotal = totalPurchaseCost * coeffPurchase;
  // 購入品費見積単価(購入品費*"仕入先係数or購入品係数")÷数量
  purchaseUnit = Number.isFinite(purchaseTotal / qty) ? purchaseTotal / qty : 0;
  // 購入品管理費総額 (購入品管理費相当＝購入品原価*(購入品係数or仕入先係数-1))
  let purchaseMngTotal = totalPurchaseCost * (coeffPurchase - 1);
  // 購入品管理費単価
  let purchaseMngUnit = Number.isFinite(purchaseMngTotal / qty) ? purchaseMngTotal / qty : 0;

  // ■固定Field・■原価・■見積・■係数・■親部品工程index・■ｶｽﾀﾑﾌｨｰﾙﾄﾞ
  const fixedDataRows = worksheet.getRows(2, 30);
  for (let r = 0; r < fixedDataRows?.length; r++) {
    let curRow = fixedDataRows[r];
    curRow.eachCell(function (cell, colNumber) {
      if (cell.value === 'ID') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.no ? estimateProduct?.no : estimateProduct?.id;
      } else if (cell.value === 'お客様ｺｰﾄﾞ') {
        let customerCode = parameters?.client?.filter((item) => item.id === estimateProduct?.clientId)?.[0]?.code;
        curRow.getCell(colNumber + 1).value = customerCode ? customerCode : '';
      } else if (cell.value === 'お客様') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.clientName;
      } else if (cell.value === '図番') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.drawingNo;
      } else if (cell.value === '名称' && curRow.getCell(colNumber + 2).value === '重量') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.name;
      } else if (cell.value === '見積番号') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.estimateNo;
      } else if (cell.value === '数量') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.quantity;
      } else if (cell.value === '作成者') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.creater;
      } else if (cell.value === '作成日') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.created ? formatDate(estimateProduct?.created) : '';
      } else if (cell.value === '更新者') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.modifier;
      } else if (cell.value === '更新日') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.modified ? formatDate(estimateProduct?.modified) : '';
      } else if (cell.value === 'コメント') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.comment;
      } else if (cell.value === '粗利益') {
        curRow.getCell(colNumber + 1).value =
          estimateProduct?.grossTotalPrice == undefined ? 0 : Math.round(estimateProduct?.grossTotalPrice);
      } else if (cell.value === '粗利率') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.grossRate
          ? parseFloat(estimateProduct?.grossRate)?.toFixed(2)
          : '';
      } else if (cell.value === '価格調整額') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.otherProduct?.adjustment
          ? Math.round(estimateProduct?.otherProduct?.adjustment)
          : 0;
      } else if (cell.value === '受注非受注') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.estimateStatus;
      } else if (cell.value === 'サイズX') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.sizeX ? toDecimal(estimateProduct?.sizeX, 2) : '';
      } else if (cell.value === 'サイズY') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.sizeY ? toDecimal(estimateProduct?.sizeY, 2) : '';
      } else if (cell.value === 'サイズZ') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.sizeZ ? toDecimal(estimateProduct?.sizeZ, 2) : '';
      } else if (cell.value === '重量') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.totalWeight
          ? toDecimal(estimateProduct?.totalWeight, 2)
          : '';
      } else if (cell.value === '表面積') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.totalSurfaceArea
          ? toDecimal(estimateProduct?.totalSurfaceArea, 2)
          : '';
      } else if (cell.value === '体積') {
        // (Xサイズ(mm)＊Yサイズ(mm)＊Zサイズ(mm))÷1000＝体積(㎥)
        let volume = (estimateProduct?.sizeX * estimateProduct?.sizeY * estimateProduct?.sizeZ) / 1000;
        volume = Number.isFinite(volume) ? volume : 0;
        curRow.getCell(colNumber + 1).value = volume ? toDecimal(volume, 2) : '';
      } else if (cell.value === '板金重量') {
        curRow.getCell(colNumber + 1).value =
          estimateProduct?.totalWeightSm === undefined || estimateProduct?.totalWeightSm === 0
            ? toDecimal(0, 2)
            : toDecimal(estimateProduct?.totalWeightSm, 2);
      } else if (cell.value === '板金子部品種類') {
        curRow.getCell(colNumber + 1).value =
          estimateProduct?.partTypesSm === undefined || estimateProduct?.partTypesSm === 0
            ? 0
            : estimateProduct?.partTypesSm;
      } else if (cell.value === '板金子部品製作数') {
        curRow.getCell(colNumber + 1).value =
          estimateProduct?.totalQuantitySm === undefined || estimateProduct?.totalQuantitySm === 0
            ? 0
            : estimateProduct?.totalQuantitySm;
      } else if (cell.value === '原価単価' || cell.value?.richText?.[0]?.text === '原価単価') {
        curRow.getCell(colNumber + 1).value =
          estimateProduct?.costUnitPrice == undefined ? 0 : Math.round(estimateProduct?.costUnitPrice);
      } else if (cell.value === '原価総額' || cell.value?.richText?.[0]?.text === '原価総額') {
        curRow.getCell(colNumber + 1).value =
          estimateProduct?.costTotalPrice == undefined ? 0 : Math.round(estimateProduct?.costTotalPrice);
      } else if (cell.value === '材料原価単価' || cell.value?.richText?.[0]?.text === '材料原価単価') {
        curRow.getCell(colNumber + 1).value =
          estimateProduct?.materialCostTotalPrice == undefined
            ? 0
            : Math.round(
                Number.isFinite(estimateProduct?.materialCostTotalPrice / estimateProduct?.quantity)
                  ? estimateProduct?.materialCostTotalPrice / estimateProduct?.quantity
                  : 0
              );
      } else if (cell.value === '材料原価総額' || cell.value?.richText?.[0]?.text === '材料原価総額') {
        curRow.getCell(colNumber + 1).value =
          estimateProduct?.materialCostTotalPrice == undefined
            ? 0
            : Math.round(estimateProduct?.materialCostTotalPrice);
      } else if (cell.value === '製造原価単価' || cell.value?.richText?.[0]?.text === '製造原価単価') {
        let manufacturingCostUnitPrice =
          estimateProduct?.manufacturingCostTotalPrice == undefined
            ? 0
            : Math.round(
                Number.isFinite(estimateProduct?.manufacturingCostTotalPrice / estimateProduct?.quantity)
                  ? estimateProduct?.manufacturingCostTotalPrice / estimateProduct?.quantity
                  : 0
              );
        curRow.getCell(colNumber + 1).value = manufacturingCostUnitPrice;
      } else if (cell.value === '製造原価総額' || cell.value?.richText?.[0]?.text === '製造原価総額') {
        curRow.getCell(colNumber + 1).value =
          estimateProduct?.manufacturingCostTotalPrice == undefined
            ? 0
            : Math.round(estimateProduct?.manufacturingCostTotalPrice);
      } else if (cell.value === '購入品単価' || cell.value?.richText?.[0]?.text === '購入品単価') {
        curRow.getCell(colNumber + 1).value =
          estimateProduct?.purchaseUnitPrice == undefined ? 0 : Math.round(estimateProduct?.purchaseUnitPrice);
      } else if (cell.value === '購入品総額' || cell.value?.richText?.[0]?.text === '購入品総額') {
        curRow.getCell(colNumber + 1).value =
          estimateProduct?.purchaseTotalPrice == undefined ? 0 : Math.round(estimateProduct?.purchaseTotalPrice);
      } else if (cell.value === 'その他費用単価' || cell.value?.richText?.[0]?.text === 'その他費用単価') {
        //費用合計
        let costPrices = 0;
        let costPriceTotal = 0;
        let epCustomCosts = estimateData?.epCustomCosts;
        let quantity = estimateProduct?.quantity;
        for (let i = 0; i < epCustomCosts?.length; i++) {
          if (epCustomCosts[i]?.isUseUnit) {
            costPriceTotal += epCustomCosts[i]?.unitPrice * quantity; // IQX_WEBEST-235
          } else {
            costPriceTotal += epCustomCosts[i]?.totalPrice;
          }
        }
        costPrices = Number.isFinite(costPriceTotal / quantity) ? costPriceTotal / quantity : 0;
        curRow.getCell(colNumber + 1).value = costPrices == undefined ? 0 : Math.round(costPrices);
      } else if (cell.value === 'その他費用総額' || cell.value?.richText?.[0]?.text === 'その他費用総額') {
        //費用合計
        let costPrices = 0;
        let costPriceTotal = 0;
        let epCustomCosts = estimateData?.epCustomCosts;
        let quantity = estimateProduct?.quantity;
        for (let i = 0; i < epCustomCosts?.length; i++) {
          if (epCustomCosts[i]?.isUseUnit) {
            costPriceTotal += epCustomCosts[i]?.unitPrice * quantity; // IQX_WEBEST-235
          } else {
            costPriceTotal += epCustomCosts[i]?.totalPrice;
          }
        }
        curRow.getCell(colNumber + 1).value = costPriceTotal == undefined ? 0 : Math.round(costPriceTotal);
      } else if (cell.value === '見積単価' || cell.value?.richText?.[0]?.text === '見積単価') {
        curRow.getCell(colNumber + 1).value =
          estimateProduct?.estimateUnitPrice == undefined ? 0 : Math.round(estimateProduct?.estimateUnitPrice);
      } else if (cell.value === '見積総額' || cell.value?.richText?.[0]?.text === '見積総額') {
        curRow.getCell(colNumber + 1).value =
          estimateProduct?.estimateTotalPrice == undefined ? 0 : Math.round(estimateProduct?.estimateTotalPrice);
      } else if (cell.value === '材料見積単価' || cell.value?.richText?.[0]?.text === '材料見積単価') {
        curRow.getCell(colNumber + 1).value = materialUnit == undefined ? 0 : Math.round(materialUnit);
      } else if (cell.value === '材料見積総額' || cell.value?.richText?.[0]?.text === '材料見積総額') {
        curRow.getCell(colNumber + 1).value = materialTotal == undefined ? 0 : Math.round(materialTotal);
      } else if (cell.value === '製造見積単価' || cell.value?.richText?.[0]?.text === '製造見積単価') {
        curRow.getCell(colNumber + 1).value = costUnit == undefined ? 0 : Math.round(costUnit);
      } else if (cell.value === '製造見積総額' || cell.value?.richText?.[0]?.text === '製造見積総額') {
        curRow.getCell(colNumber + 1).value = costTotal == undefined ? 0 : Math.round(costTotal);
      } else if (cell.value === '購入品見積単価' || cell.value?.richText?.[0]?.text === '購入品見積単価') {
        curRow.getCell(colNumber + 1).value = purchaseUnit == undefined ? 0 : Math.round(purchaseUnit);
      } else if (cell.value === '購入品見積総額' || cell.value?.richText?.[0]?.text === '購入品見積総額') {
        curRow.getCell(colNumber + 1).value = purchaseTotal == undefined ? 0 : Math.round(purchaseTotal);
      } else if (cell.value === '材料管理費単価' || cell.value?.richText?.[0]?.text === '材料管理費単価') {
        // IQX_WEBEST-352 管理費の計算式
        curRow.getCell(colNumber + 1).value =
          iq3EstimateMaterialUnitMngPrices == undefined ? 0 : Math.round(iq3EstimateMaterialUnitMngPrices);
      } else if (cell.value === '材料管理費総額' || cell.value?.richText?.[0]?.text === '材料管理費総額') {
        // IQX_WEBEST-352 管理費の計算式
        curRow.getCell(colNumber + 1).value =
          iq3EstimateMaterialMngTotalPrices == undefined ? 0 : Math.round(iq3EstimateMaterialMngTotalPrices);
      } else if (cell.value === '製造管理費単価' || cell.value?.richText?.[0]?.text === '製造管理費単価') {
        // IQX_WEBEST-352 管理費の計算式
        curRow.getCell(colNumber + 1).value = costMngUnit == undefined ? 0 : Math.round(costMngUnit);
      } else if (cell.value === '製造管理費総額' || cell.value?.richText?.[0]?.text === '製造管理費総額') {
        // IQX_WEBEST-352 管理費の計算式
        curRow.getCell(colNumber + 1).value = costMngTotal == undefined ? 0 : Math.round(costMngTotal);
      } else if (cell.value === '購入品管理費単価' || cell.value?.richText?.[0]?.text === '購入品管理費単価') {
        // IQX_WEBEST-352 管理費の計算式
        curRow.getCell(colNumber + 1).value = purchaseMngUnit == undefined ? 0 : Math.round(purchaseMngUnit);
      } else if (cell.value === '購入品管理費総額' || cell.value?.richText?.[0]?.text === '購入品管理費総額') {
        // IQX_WEBEST-352 管理費の計算式
        curRow.getCell(colNumber + 1).value = purchaseMngTotal == undefined ? 0 : Math.round(purchaseMngTotal);
      } else if (cell.value === '受注ﾀｲﾌﾟ') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.coeffOrdersName;
        curRow.getCell(colNumber + 2).value = estimateProduct?.otherProduct?.coeffOrders
          ? toDecimal(estimateProduct?.otherProduct?.coeffOrders, 2)
          : '';
      } else if (cell.value === '納期ﾀｲﾌﾟ') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.coeffDeliveryDatesName;
        curRow.getCell(colNumber + 2).value = estimateProduct?.otherProduct?.coeffDeliveryDates
          ? toDecimal(estimateProduct?.otherProduct?.coeffDeliveryDates, 2)
          : '';
      } else if (cell.value === '購入品ﾀｲﾌﾟ') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.coeffPurchasesName;
        curRow.getCell(colNumber + 2).value = estimateProduct?.otherProduct?.coeffPurchases
          ? toDecimal(estimateProduct?.otherProduct?.coeffPurchases, 2)
          : '';
      } else if (cell.value === '管理費ﾀｲﾌﾟ') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.coeffManagementsName;
        curRow.getCell(colNumber + 2).value = estimateProduct?.otherProduct?.coeffManagements
          ? toDecimal(estimateProduct?.otherProduct?.coeffManagements, 2)
          : '';
      } else if (cell.value === '取引先係数') {
        curRow.getCell(colNumber + 1).value = estimateProduct?.clientName;
        curRow.getCell(colNumber + 2).value = estimateProduct?.otherProduct?.coeffClient
          ? toDecimal(estimateProduct?.otherProduct?.coeffClient, 2)
          : '';
      } else if (cell.value === '材料粗利係数' || cell.value?.richText?.[0]?.text === '材料粗利係数') {
        let materialLossCoeffRow = curRow;
        for (let s = 0; s < materialTypeLst?.length; s++) {
          materialLossCoeffRow = worksheet.getRow(curRow?.number + s);
          materialLossCoeffRow.getCell(colNumber + 1).value = materialTypeLst?.[s]?.name;
          materialLossCoeffRow.getCell(colNumber + 2).value = materialTypeLst?.[s]?.info?.lossCoeff
            ? toDecimal(materialTypeLst?.[s]?.info?.lossCoeff, 2)
            : '';
        }
      } else if (cell.value === '費用') {
        curRow.getCell(colNumber + 1).value = estimateData?.epCustomCosts?.length
          ? estimateData?.epCustomCosts?.length
          : 0;
      } else if (cell.value === 'ラベル') {
        curRow.getCell(colNumber + 1).value = estimateData?.epCustomLabels?.length
          ? estimateData?.epCustomLabels?.length
          : 0;
      } else if (cell.value === 'メモ') {
        curRow.getCell(colNumber + 1).value = estimateData?.epCustomMemos?.length
          ? estimateData?.epCustomMemos?.length
          : 0;
      } else if (cell.value === '日付') {
        curRow.getCell(colNumber + 1).value = estimateData?.epCustomDates?.length
          ? estimateData?.epCustomDates?.length
          : 0;
      } else if (cell.value === '■親部品工程index') {
        let processHeaderRow = worksheet.getRow(curRow.number + 1);
        let processSelectOya = (parameters?.process || [])?.filter((e) => e.class === 0);
        let dataRowIndex = curRow.number + 2;
        processSelectOya?.forEach((e, index) => {
          let processDataRow = worksheet.getRow(dataRowIndex + index);
          let processMasterOyaColIndex = colNumber;
          processHeaderRow.eachCell(function (cell, colNumber) {
            if (colNumber >= processMasterOyaColIndex) {
              if (cell.value == 'No.') {
                processDataRow.getCell(colNumber).value = index + 1;
              } else if (cell.value == '分類') {
                processDataRow.getCell(colNumber).value = '親部品';
              } else if (cell.value == '種別') {
                processDataRow.getCell(colNumber).value = e?.isAdditional ? '追加' : '標準';
              } else if (cell.value == 'ｺｰﾄﾞ') {
                processDataRow.getCell(colNumber).value = e?.code;
              } else if (cell.value == '工程名') {
                processDataRow.getCell(colNumber).value = e?.name;
              } else if (cell.value?.includes('種類ｶｳﾝﾄ') || cell.value?.richText?.[0]?.text?.includes('種類ｶｳﾝﾄ')) {
                let detailTypes = e?.details?.processDetailTypes;
                if (detailTypes?.length > 0) {
                  processDataRow.getCell(colNumber).value = detailTypes?.filter((d) => d.isUsed)?.length
                    ? detailTypes?.filter((d) => d.isUsed)?.length
                    : 0;
                } else {
                  processDataRow.getCell(colNumber).value = 0;
                }
              }
            }
          });
          processDataRow.commit();
        });
      }
    });
  }
  // ■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[費用]・■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[ラベル]・■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[メモ]・■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[日付]
  const customFieldDetailRow = worksheet.getRow(31);
  let headerRow = worksheet.getRow(customFieldDetailRow?.number + 1);
  //不要データクリア
  const lastRow = worksheet.lastRow;
  const rows = worksheet.getRows(headerRow?.number + 1, lastRow.number);
  for (let r = 0; r < rows?.length; r++) {
    rows[r].values = [];
    rows[r].commit();
  }
  let customDataRow = [];
  customFieldDetailRow.eachCell(function (cell, headerColNumber) {
    let startRowIndex = customFieldDetailRow?.number + 2;
    if (cell.value === '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[費用]') {
      estimateData?.epCustomCosts?.forEach((e, index) => {
        let isCostCell = true;
        customDataRow = worksheet.getRow(startRowIndex + index);
        headerRow?.eachCell(function (cell, colNumber) {
          if (headerColNumber === colNumber || headerColNumber < colNumber) {
            if (cell.value == 'No.' && isCostCell) {
              if (customFieldDetailRow.getCell(colNumber).value === '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[費用]') {
                customDataRow.getCell(colNumber).value = index + 1;
              } else {
                isCostCell = false;
              }
            } else if (cell.value == 'ｺｰﾄﾞ' && isCostCell) {
              customDataRow.getCell(colNumber).value = e.additionalItemCode;
            } else if (cell.value == '名称' && isCostCell) {
              customDataRow.getCell(colNumber).value = e.additionalItemName;
            } else if (cell.value == '単価' && isCostCell) {
              customDataRow.getCell(colNumber).value = e.unitPrice ? e.unitPrice : 0;
            } else if (cell.value == '数量' && isCostCell) {
              customDataRow.getCell(colNumber).value = qty;
            } else if (cell.value == '合計' && isCostCell) {
              customDataRow.getCell(colNumber).value = e.totalPrice;
            }
          }
        });
        customDataRow.commit();
      });
    } else if (cell.value === '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[ラベル]') {
      let headerRow = worksheet.getRow(customFieldDetailRow?.number + 1);
      estimateData?.epCustomLabels?.forEach((e, index) => {
        let isLabelCell = true;
        customDataRow = worksheet.getRow(startRowIndex + index);
        headerRow?.eachCell(function (cell, colNumber) {
          if (headerColNumber === colNumber || headerColNumber < colNumber) {
            if (cell.value == 'No.' && isLabelCell) {
              if (customFieldDetailRow.getCell(colNumber).value === '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[ラベル]') {
                customDataRow.getCell(colNumber).value = index + 1;
              } else {
                isLabelCell = false;
              }
            } else if (cell.value == 'ｺｰﾄﾞ' && isLabelCell) {
              customDataRow.getCell(colNumber).value = e.additionalItemCode;
            } else if (cell.value == '名称' && isLabelCell) {
              customDataRow.getCell(colNumber).value = e.additionalItemName;
              customDataRow.getCell(colNumber + 1).value = e.label;
            }
          }
        });
        customDataRow.commit();
      });
    } else if (cell.value === '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[メモ]') {
      let headerRow = worksheet.getRow(customFieldDetailRow?.number + 1);
      estimateData?.epCustomMemos?.forEach((e, index) => {
        let isMemoCell = true;
        customDataRow = worksheet.getRow(startRowIndex + index);
        headerRow?.eachCell(function (cell, colNumber) {
          if (headerColNumber === colNumber || headerColNumber < colNumber) {
            if (cell.value == 'No.' && isMemoCell) {
              if (customFieldDetailRow.getCell(colNumber).value === '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[メモ]') {
                customDataRow.getCell(colNumber).value = index + 1;
              } else {
                isMemoCell = false;
              }
            } else if (cell.value == 'ｺｰﾄﾞ' && isMemoCell) {
              customDataRow.getCell(colNumber).value = e.additionalItemCode;
            } else if (cell.value == '名称' && isMemoCell) {
              customDataRow.getCell(colNumber).value = e.additionalItemName;
              customDataRow.getCell(colNumber + 1).value = e.memo;
            }
          }
        });
        customDataRow.commit();
      });
    } else if (cell.value === '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[日付]') {
      let headerRow = worksheet.getRow(customFieldDetailRow?.number + 1);
      estimateData?.epCustomDates?.forEach((e, index) => {
        let isDateCell = true;
        customDataRow = worksheet.getRow(startRowIndex + index);
        headerRow?.eachCell(function (cell, colNumber) {
          if (headerColNumber === colNumber || headerColNumber < colNumber) {
            if (cell.value == 'No.') {
              if (customFieldDetailRow.getCell(colNumber).value === '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[日付]') {
                customDataRow.getCell(colNumber).value = index + 1;
              } else {
                isDateCell = false;
              }
            } else if (cell.value == 'ｺｰﾄﾞ' && isDateCell) {
              customDataRow.getCell(colNumber).value = e.additionalItemCode;
            } else if (cell.value == '名称' && isDateCell) {
              customDataRow.getCell(colNumber).value = e.additionalItemName;
              customDataRow.getCell(colNumber + 1).value = e.date ? dayjs(e.date).format('YYYY-MM-DD') : '';
            }
          }
        });
        customDataRow.commit();
      });
    }
  });
};
const settingORG2 = (processData, worksheet, curRowIndex, estimateData) => {
  //不要データクリア
  const lastRow = worksheet.lastRow;
  const rows = worksheet.getRows(curRowIndex, lastRow.number);
  for (let r = 0; r < rows?.length; r++) {
    rows[r].values = [];
    rows[r].commit();
  }
  let processCnt = 1;
  let headerRow = worksheet.getRow(2);
  let totalRow = worksheet.getRow(3);
  let totalColIndex = 1;
  for (const key in processData) {
    if (processData.hasOwnProperty(key)) {
      if (parseInt(key) !== WorkType.PaCustom) {
        const dataArray = processData[key];
        let curRowIndex = 0;
        let genkaTotal = 0;
        let dandoriTotal = 0;
        let sagyoTotal = 0;
        let genkaTotalTime = 0;
        let dandoriTotalTime = 0;
        let sagyoTotalTime = 0;
        let estTotal = 0;
        let estDandoriTotal = 0;
        let estSagyoTotal = 0;
        let curColIndex = 1;
        for (let rowData of dataArray) {
          let curRow = worksheet.getRow(curRowIndex + 4); // IQX_WEBEST-236
          if (parseInt(key) === WorkType.PaSurface) {
            curColIndex = 1;
            processCnt = 1;
          } else if (parseInt(key) === WorkType.PaAssenble) {
            curColIndex = 25;
            processCnt = 25;
          } else if (parseInt(key) === WorkType.PaInspection) {
            curColIndex = 49;
            processCnt = 49;
          } else if (parseInt(key) === WorkType.PaPacking) {
            curColIndex = 73;
            processCnt = 73;
          }
          for (let key in rowData) {
            let curCol = curRow.getCell(curColIndex);
            if (Object.hasOwnProperty.call(rowData, key)) {
              curCol.value = rowData[key];
              if (key === 'estTotalAmt') {
                estTotal += rowData[key] ? Math.round(rowData[key]) : 0;
                curCol.value = rowData[key] ? Math.round(rowData[key]) : 0;
              }
              if (key === 'estDandoriAmt') {
                estDandoriTotal += rowData[key] ? Math.round(rowData[key]) : 0;
                curCol.value = rowData[key] ? Math.round(rowData[key]) : 0;
              }
              if (key === 'estSagyoAmt') {
                estSagyoTotal += rowData[key] ? Math.round(rowData[key]) : 0;
                curCol.value = rowData[key] ? Math.round(rowData[key]) : 0;
              }
              if (key === 'gankaTotalAmt') {
                genkaTotal += rowData[key] ? Math.round(rowData[key]) : 0;
                curCol.value = rowData[key] ? Math.round(rowData[key]) : 0;
              }
              if (key === 'gankaDandoriAmt') {
                dandoriTotal += rowData[key] ? Math.round(rowData[key]) : 0;
                curCol.value = rowData[key] ? Math.round(rowData[key]) : 0;
              }
              if (key === 'gankaSagyoAmt') {
                sagyoTotal += rowData[key] ? Math.round(rowData[key]) : 0;
                curCol.value = rowData[key] ? Math.round(rowData[key]) : 0;
              }
              if (key === 'gankaTotalTime') {
                genkaTotalTime += rowData[key] ? Math.round(rowData[key]) : 0;
                curCol.value = rowData[key] ? secondsToHms(rowData[key]) : timeHmsStr;
              }
              if (key === 'gankaDandoriTime') {
                dandoriTotalTime += rowData[key] ? Math.round(rowData[key]) : 0;
                curCol.value = rowData[key] ? secondsToHms(rowData[key]) : timeHmsStr;
              }
              if (key === 'gankaSagyoTime') {
                sagyoTotalTime += rowData[key] ? Math.round(rowData[key]) : 0;
                curCol.value = rowData[key] ? secondsToHms(rowData[key]) : timeHmsStr;
              }
            }
            curColIndex++;
          }
          curRowIndex++;
          curRow.commit();
        }
        // 合計行データ設定
        if (dataArray?.length > 0) {
          totalColIndex = processCnt;
          if (headerRow.getCell(totalColIndex).value === 'No.') {
            totalRow.getCell(totalColIndex).value = dataArray[0].no;
          }
          if (headerRow.getCell(totalColIndex + 1).value === '分類') {
            totalRow.getCell(totalColIndex + 1).value = dataArray[0].classification;
          }
          if (headerRow.getCell(totalColIndex + 2).value === '種別') {
            totalRow.getCell(totalColIndex + 2).value = dataArray[0].type;
          }
          if (headerRow.getCell(totalColIndex + 3).value === '工程ｺｰﾄﾞ') {
            totalRow.getCell(totalColIndex + 3).value = dataArray[0].processCode;
          }
          if (headerRow.getCell(totalColIndex + 4).value === '工程名') {
            totalRow.getCell(totalColIndex + 4).value = dataArray[0].processName;
          }
          if (headerRow.getCell(totalColIndex + 5).value === '工程ﾀｲﾌﾟ') {
            totalRow.getCell(totalColIndex + 5).value = dataArray[0].processType;
          }
          if (headerRow.getCell(totalColIndex + 6).value === 'No.') {
            totalRow.getCell(totalColIndex + 6).value = 0;
          }
          if (headerRow.getCell(totalColIndex + 8).value === '種類') {
            totalRow.getCell(totalColIndex + 8).value = '合計';
          }
          if (headerRow.getCell(totalColIndex + 14).value === '見積合計金額') {
            // 加工原価の見積合計((加工原価合計*(取引先係数＋納期係数＋受注形態係数-2))*管理係数)
            // let totalProcessEstimate = genkaTotal * (coeffsTotal - 2) * coeffMng;
            totalRow.getCell(totalColIndex + 14).value = estTotal ? Math.round(estTotal) : 0;
          }
          if (headerRow.getCell(totalColIndex + 15).value === '段取金額') {
            // 段取金額の見積合計((段取金額*(取引先係数＋納期係数＋受注形態係数-2))*管理係数)
            // let totalDandoriEstimate = dandoriTotal * (coeffsTotal - 2) * coeffMng;
            totalRow.getCell(totalColIndex + 15).value = estDandoriTotal ? Math.round(estDandoriTotal) : 0;
          }
          if (headerRow.getCell(totalColIndex + 16).value === '加工金額') {
            // 作業金額の見積合計((作業金額*(取引先係数＋納期係数＋受注形態係数-2))*管理係数)
            // let totalSagyoEstimate = sagyoTotal * (coeffsTotal - 2) * coeffMng;
            totalRow.getCell(totalColIndex + 16).value = estSagyoTotal ? Math.round(estSagyoTotal) : 0;
          }
          if (headerRow.getCell(totalColIndex + 17).value === '原価合計金額') {
            totalRow.getCell(totalColIndex + 17).value = genkaTotal ? Math.round(genkaTotal) : 0;
          }
          if (headerRow.getCell(totalColIndex + 18).value === '段取金額') {
            totalRow.getCell(totalColIndex + 18).value = dandoriTotal ? Math.round(dandoriTotal) : 0;
          }
          if (headerRow.getCell(totalColIndex + 19).value === '加工金額') {
            totalRow.getCell(totalColIndex + 19).value = sagyoTotal ? Math.round(sagyoTotal) : 0;
          }
          if (headerRow.getCell(totalColIndex + 20).value === '合計時間') {
            totalRow.getCell(totalColIndex + 20).value = genkaTotalTime ? secondsToHms(genkaTotalTime) : timeHmsStr;
          }
          if (headerRow.getCell(totalColIndex + 21).value === '段取時間') {
            totalRow.getCell(totalColIndex + 21).value = dandoriTotalTime ? secondsToHms(dandoriTotalTime) : timeHmsStr;
          }
          if (headerRow.getCell(totalColIndex + 22).value === '加工時間') {
            totalRow.getCell(totalColIndex + 22).value = sagyoTotalTime ? secondsToHms(sagyoTotalTime) : timeHmsStr;
          }
        }
      }
    }
  }
};
const settingORG3 = (processData, worksheet, curRowIndex, estimateData) => {
  //不要データクリア
  const lastRow = worksheet.lastRow;
  const rows = worksheet.getRows(curRowIndex, lastRow.number);
  for (let r = 0; r < rows?.length; r++) {
    rows[r].values = [];
    rows[r].commit();
  }
  let processCnt = 1; // 追加工程1種類目：A列～
  let headerRow = worksheet.getRow(2);
  let totalRow = worksheet.getRow(3);
  let totalColIndex = 1;
  let parentCustomProcess = estimateData?.estimateProducts?.calcParameters?.parameters?.process?.filter(
    (proc) => proc.workType === WorkType.PaCustom
  );
  for (const key in processData) {
    if (processData.hasOwnProperty(key)) {
      const dataArray = processData[key];
      let curRowIndex = 0;
      let genkaTotal = 0;
      let dandoriTotal = 0;
      let sagyoTotal = 0;
      let genkaTotalTime = 0;
      let dandoriTotalTime = 0;
      let sagyoTotalTime = 0;
      let estTotal = 0;
      let estDandoriTotal = 0;
      let estSagyoTotal = 0;
      let curColIndex = 1;
      for (let rowData of dataArray) {
        let curRow = worksheet.getRow(curRowIndex + 4); // IQX_WEBEST-236
        if (parseInt(key) === parentCustomProcess?.[0].id) {
          // 追加工程1種類目：A列～
          curColIndex = 1;
          processCnt = 1;
        } else if (parseInt(key) === parentCustomProcess?.[1].id) {
          // 追加工程2種類目：Y列～
          curColIndex = 25;
          processCnt = 25;
        } else if (parseInt(key) === parentCustomProcess?.[2].id) {
          // 追加工程3種類目：AW列～
          curColIndex = 49;
          processCnt = 49;
        } else if (parseInt(key) === parentCustomProcess?.[3].id) {
          // 追加工程4種類目：BU列～
          curColIndex = 73;
          processCnt = 73;
        } else if (parseInt(key) === parentCustomProcess?.[4].id) {
          // 追加工程5種類目：CS列～
          curColIndex = 97;
          processCnt = 97;
        }
        for (let key in rowData) {
          let curCol = curRow.getCell(curColIndex);
          if (Object.hasOwnProperty.call(rowData, key)) {
            curCol.value = rowData[key];
            if (key === 'estTotalAmt') {
              estTotal += rowData[key] ? Math.round(rowData[key]) : 0;
              curCol.value = rowData[key] ? Math.round(rowData[key]) : 0;
            }
            if (key === 'estDandoriAmt') {
              estDandoriTotal += rowData[key] ? Math.round(rowData[key]) : 0;
              curCol.value = rowData[key] ? Math.round(rowData[key]) : 0;
            }
            if (key === 'estSagyoAmt') {
              estSagyoTotal += rowData[key] ? Math.round(rowData[key]) : 0;
              curCol.value = rowData[key] ? Math.round(rowData[key]) : 0;
            }
            if (key === 'gankaTotalAmt') {
              genkaTotal += rowData[key] ? Math.round(rowData[key]) : 0;
              curCol.value = rowData[key] ? Math.round(rowData[key]) : 0;
            }
            if (key === 'gankaDandoriAmt') {
              dandoriTotal += rowData[key] ? Math.round(rowData[key]) : 0;
              curCol.value = rowData[key] ? Math.round(rowData[key]) : 0;
            }
            if (key === 'gankaSagyoAmt') {
              sagyoTotal += rowData[key] ? Math.round(rowData[key]) : 0;
              curCol.value = rowData[key] ? Math.round(rowData[key]) : 0;
            }
            if (key === 'gankaTotalTime') {
              genkaTotalTime += rowData[key] ? Math.round(rowData[key]) : 0;
              curCol.value = rowData[key] ? secondsToHms(rowData[key]) : timeHmsStr;
            }
            if (key === 'gankaDandoriTime') {
              dandoriTotalTime += rowData[key] ? Math.round(rowData[key]) : 0;
              curCol.value = rowData[key] ? secondsToHms(rowData[key]) : timeHmsStr;
            }
            if (key === 'gankaSagyoTime') {
              sagyoTotalTime += rowData[key] ? Math.round(rowData[key]) : 0;
              curCol.value = rowData[key] ? secondsToHms(rowData[key]) : timeHmsStr;
            }
          }
          curColIndex++;
        }
        curRowIndex++;
        curRow.commit();
      }
      // 合計行データ設定
      if (dataArray?.length > 0) {
        totalColIndex = processCnt;
        if (headerRow.getCell(totalColIndex).value === 'No.') {
          totalRow.getCell(totalColIndex).value = dataArray[0].no;
        }
        if (headerRow.getCell(totalColIndex + 1).value === '分類') {
          totalRow.getCell(totalColIndex + 1).value = dataArray[0].classification;
        }
        if (headerRow.getCell(totalColIndex + 2).value === '種別') {
          totalRow.getCell(totalColIndex + 2).value = dataArray[0].type;
        }
        if (headerRow.getCell(totalColIndex + 3).value === '工程ｺｰﾄﾞ') {
          totalRow.getCell(totalColIndex + 3).value = dataArray[0].processCode;
        }
        if (headerRow.getCell(totalColIndex + 4).value === '工程名') {
          totalRow.getCell(totalColIndex + 4).value = dataArray[0].processName;
        }
        if (headerRow.getCell(totalColIndex + 5).value === '工程ﾀｲﾌﾟ') {
          totalRow.getCell(totalColIndex + 5).value = dataArray[0].processType;
        }
        if (headerRow.getCell(totalColIndex + 6).value === 'No.') {
          totalRow.getCell(totalColIndex + 6).value = 0;
        }
        if (headerRow.getCell(totalColIndex + 8).value === '種類') {
          totalRow.getCell(totalColIndex + 8).value = '合計';
        }
        if (headerRow.getCell(totalColIndex + 14).value === '見積合計金額') {
          // 加工原価の見積合計((加工原価合計*(取引先係数＋納期係数＋受注形態係数-2))*管理係数)
          // let totalProcessEstimate = genkaTotal * (coeffsTotal - 2) * coeffMng;
          totalRow.getCell(totalColIndex + 14).value = estTotal ? Math.round(estTotal) : 0;
        }
        if (headerRow.getCell(totalColIndex + 15).value === '段取金額') {
          // 段取金額の見積合計((段取金額*(取引先係数＋納期係数＋受注形態係数-2))*管理係数)
          // let totalDandoriEstimate = dandoriTotal * (coeffsTotal - 2) * coeffMng;
          totalRow.getCell(totalColIndex + 15).value = estDandoriTotal ? Math.round(estDandoriTotal) : 0;
        }
        if (headerRow.getCell(totalColIndex + 16).value === '加工金額') {
          // 作業金額の見積合計((作業金額*(取引先係数＋納期係数＋受注形態係数-2))*管理係数)
          // let totalSagyoEstimate = sagyoTotal * (coeffsTotal - 2) * coeffMng;
          totalRow.getCell(totalColIndex + 16).value = estSagyoTotal ? Math.round(estSagyoTotal) : 0;
        }
        if (headerRow.getCell(totalColIndex + 17).value === '原価合計金額') {
          totalRow.getCell(totalColIndex + 17).value = genkaTotal ? Math.round(genkaTotal) : 0;
        }
        if (headerRow.getCell(totalColIndex + 18).value === '段取金額') {
          totalRow.getCell(totalColIndex + 18).value = dandoriTotal ? Math.round(dandoriTotal) : 0;
        }
        if (headerRow.getCell(totalColIndex + 19).value === '加工金額') {
          totalRow.getCell(totalColIndex + 19).value = sagyoTotal ? Math.round(sagyoTotal) : 0;
        }
        if (headerRow.getCell(totalColIndex + 20).value === '合計時間') {
          totalRow.getCell(totalColIndex + 20).value = genkaTotalTime ? secondsToHms(genkaTotalTime) : timeHmsStr;
        }
        if (headerRow.getCell(totalColIndex + 21).value === '段取時間') {
          totalRow.getCell(totalColIndex + 21).value = dandoriTotalTime ? secondsToHms(dandoriTotalTime) : timeHmsStr;
        }
        if (headerRow.getCell(totalColIndex + 22).value === '加工時間') {
          totalRow.getCell(totalColIndex + 22).value = sagyoTotalTime ? secondsToHms(sagyoTotalTime) : timeHmsStr;
        }
      }
    }
  }
};
// const settingORG3 = (processData, worksheet, curRowIndex, estimateData) => {
//   //不要データクリア
//   const lastRow = worksheet.lastRow;
//   const rows = worksheet.getRows(curRowIndex, lastRow.number);
//   for (let r = 0; r < rows?.length; r++) {
//     rows[r].values = [];
//     rows[r].commit();
//   }
//   const fixedColCnt = 24;
//   let processCnt = 0;
//   let headerRow = worksheet.getRow(2);
//   let totalRow = worksheet.getRow(3);
//   let totalColIndex = 1;
//   // 取引先係数＋納期係数＋受注形態係数
//   let coeffClient = estimateData?.estimateProducts?.otherProduct?.coeffClient
//     ? Number(estimateData?.estimateProducts?.otherProduct?.coeffClient)
//     : 0;
//   let coeffDeliveryDates = estimateData?.estimateProducts?.otherProduct?.coeffDeliveryDates
//     ? Number(estimateData?.estimateProducts?.otherProduct?.coeffDeliveryDates)
//     : 0;
//   let coeffOrders = estimateData?.estimateProducts?.otherProduct?.coeffOrders
//     ? Number(estimateData?.estimateProducts?.otherProduct?.coeffOrders)
//     : 0;
//   let coeffsTotal = coeffClient + coeffDeliveryDates + coeffOrders;
//   // 管理係数
//   let coeffMng = estimateData?.estimateProducts?.otherProduct?.coeffManagements
//     ? Number(estimateData?.estimateProducts?.otherProduct?.coeffManagements)
//     : 0;
//   for (const key in processData) {
//     if (processData.hasOwnProperty(key)) {
//       if (parseInt(key) === WorkType.PaCustom) {
//         const dataArray = processData[key];
//         let curRowIndex = 0;
//         for (let rowData of dataArray) {
//           let curRow = worksheet.getRow(curRowIndex + 3);
//           let curColIndex = 1;
//           curColIndex += processCnt;
//           for (let key in rowData) {
//             let curCol = curRow.getCell(curColIndex);
//             if (Object.hasOwnProperty.call(rowData, key)) {
//               curCol.value = rowData[key];
//             }
//             curColIndex++;
//           }
//           curRowIndex++;
//           curRow.commit();
//         }
//         processCnt += fixedColCnt;
//       }
//     }
//   }
// };
const settingORG4 = (processes, worksheet, curRowIndex) => {
  const row1 = worksheet.getRow(2);
  let curRow = [];
  //不要データクリア
  const lastRow = worksheet.lastRow;
  const rows = worksheet.getRows(curRowIndex, lastRow.number);
  for (let r = 0; r < rows?.length; r++) {
    rows[r].values = [];
    rows[r].commit();
  }
  for (let i = 0; i < processes?.length; i++) {
    let process = processes[i];
    curRow = worksheet.getRow(i + curRowIndex);
    row1.eachCell(function (cell, colNumber) {
      if (cell.value == 'No.') {
        curRow.getCell(colNumber).value = i + curRowIndex - 2;
      } else if (cell.value == '分類') {
        curRow.getCell(colNumber).value = process.classification;
      } else if (cell.value == '種別') {
        curRow.getCell(colNumber).value = process.type;
      } else if (cell.value == 'ｺｰﾄﾞ') {
        curRow.getCell(colNumber).value = process.processCode;
      } else if (cell.value == '工程名') {
        curRow.getCell(colNumber).value = process.processName;
      } else if (cell.value.includes('種類ｶｳﾝﾄ')) {
        curRow.getCell(colNumber).value = process.count;
      }
    });
    curRow.commit();
  }
};
const settingORG5 = (estimateData, worksheet) => {
  let parameters = estimateData?.estimateProducts?.calcParameters?.parameters;
  let defaultProcessParam = ['段取時間', '加工時間', '段取金額', '加工金額', '(空白)'];
  let bariProcessParam = ['面積', '重量', '段取時間', '加工時間', '段取金額', '加工金額', '(空白)'];
  let processMasterData = parameters?.process;

  let iq3Proccesses = [];
  processMasterData
    ?.filter((item) => item.class === 1)
    ?.map((item) => iq3Proccesses?.push({ id: item.id, workType: item.workType, name: item.name }));

  let processParams = [];

  iq3Proccesses?.forEach((id) => {
    if (id.id === 15 || id.id === 16) {
      processParams?.push({
        id: id,
        param: bariProcessParam,
        name: id.name,
      });
    } else {
      processParams?.push({
        id: id,
        param: defaultProcessParam,
        name: id.name,
      });
    }
  });
  const usedProcessList = processParams;
  const data = getSheet5Data(usedProcessList, estimateData?.esIq3Info, estimateData);
  let rowIndex = 0;
  let curRow = [];
  for (let rowData of data) {
    curRow = worksheet.getRow(3 + rowIndex);
    let colNumber = 1;
    for (let key in rowData) {
      if (Object.hasOwnProperty.call(rowData, key)) {
        curRow.getCell(colNumber).value = rowData[key];
      }
      colNumber++;
    }
    curRow.commit();
    rowIndex++;
  }
  //不要データクリア
  const lastRow = worksheet.lastRow;
  const rows = worksheet.getRows(rowIndex + 3, lastRow.number);
  for (let r = 0; r < rows?.length; r++) {
    rows[r].values = [];
    rows[r].commit();
  }
};
const settingORG6 = (estimateData, worksheet) => {
  const data = getSheet6Data(estimateData);
  let rowIndex = 0;
  let curRow = [];
  let programColIndex = 0;
  let blankColIndex = 0;
  let bendingColIndex = 0;
  let bariColIndex = 0;
  let surfaceColIndex = 0;
  let secondaryColIndex = 0;
  let weldColIndex = 0;
  let weldFinishColIndex = 0;
  let sharringColIndex = 0;
  let kensaColIndex = 0;
  let addition1ColIndex = 0;
  let addition2ColIndex = 0;
  let addition3ColIndex = 0;
  let addition4ColIndex = 0;
  let addition5ColIndex = 0;
  let headerRow = worksheet.getRow(1);
  headerRow.eachCell(function (cell, colNumber) {
    if (cell.value == 'プログラム') {
      programColIndex = colNumber;
    } else if (cell.value == 'ブランク') {
      blankColIndex = colNumber;
    } else if (cell.value == 'ベンディング') {
      bendingColIndex = colNumber;
    } else if (cell.value == 'バリ取') {
      bariColIndex = colNumber;
    } else if (cell.value == '表面処理') {
      surfaceColIndex = colNumber;
    } else if (cell.value == '2次加工') {
      secondaryColIndex = colNumber;
    } else if (cell.value == '溶接') {
      weldColIndex = colNumber;
    } else if (cell.value == '溶接仕上') {
      weldFinishColIndex = colNumber;
    } else if (cell.value == 'シャーリング') {
      sharringColIndex = colNumber;
    } else if (cell.value == '検査') {
      kensaColIndex = colNumber;
    } else if (cell.value == '追加工程①') {
      addition1ColIndex = colNumber;
    } else if (cell.value == '追加工程②') {
      addition2ColIndex = colNumber;
    } else if (cell.value == '追加工程③') {
      addition3ColIndex = colNumber;
    } else if (cell.value == '追加工程④') {
      addition4ColIndex = colNumber;
    } else if (cell.value == '追加工程⑤') {
      addition5ColIndex = colNumber;
    }
  });
  //不要データクリア
  const lastRow = worksheet.lastRow;
  const rows = worksheet.getRows(3, lastRow.number);
  for (let r = 0; r < rows?.length; r++) {
    rows[r].values = [];
    rows[r].commit();
  }
  let parameters = estimateData?.estimateProducts?.calcParameters?.parameters;
  for (let rowData of data) {
    curRow = rowIndex === 0 ? worksheet.getRow(3 + rowIndex) : worksheet.getRow(rowIndex);
    let colNumber = 1;
    let listCurRowNumber = [];
    let isDeburr = false;
    // 各工程のループ
    for (let key in rowData) {
      let workType = parameters?.process?.filter(
        (item) => item.name == key && item.class == ServiceClass.SheetMetal && !item.isDelete
      )?.[0]?.workType;
      if (key === 'partInfo') {
        let partInfo = rowData[key];
        if (partInfo?.length > 0) {
          colNumber = 1;
          curRow.getCell(colNumber).value = partInfo[0]?.no;
          curRow.getCell(colNumber + 1).value = partInfo[0]?.drawingNo;
          curRow.getCell(colNumber + 2).value = partInfo[0]?.name;
          curRow.getCell(colNumber + 3).value = partInfo[0]?.partCount;
          curRow.getCell(colNumber + 4).value = partInfo[0]?.prodNo;
          curRow.commit();
          listCurRowNumber.push(curRow?.number + 1);
        }
      } else if (workType == WorkType.SmProgram) {
        colNumber = programColIndex;
        let program = rowData[key];
        if (program?.length > 0) {
          curRow.getCell(colNumber).value = program[0]?.processCode;
          curRow.getCell(colNumber + 1).value = program[0]?.deviceCode;
          curRow.getCell(colNumber + 2).value = program[0]?.deviceName;
          curRow.getCell(colNumber + 3).value = program[0]?.element;
          curRow.commit();
          listCurRowNumber.push(curRow?.number);
        }
      } else if (
        workType == WorkType.SmBlankCombi ||
        workType == WorkType.SmBlankLaser ||
        workType == WorkType.SmBlankPunch
      ) {
        let dataObj = rowData[key];
        for (let b = 0; b < dataObj?.length; b++) {
          let listCurRow = worksheet.getRow(curRow?.number + b);
          colNumber = blankColIndex;
          listCurRow.getCell(colNumber).value = dataObj[b]?.processCode;
          listCurRow.getCell(colNumber + 1).value = dataObj[b]?.deviceCode;
          listCurRow.getCell(colNumber + 2).value = dataObj[b]?.deviceName;
          listCurRow.getCell(colNumber + 3).value = dataObj[b]?.outerDia;
          listCurRow.getCell(colNumber + 4).value = dataObj[b]?.innerDia;
          listCurRow.getCell(colNumber + 5).value = dataObj[b]?.airFeedDistance;
          listCurRow.getCell(colNumber + 6).value = dataObj[b]?.detailCode;
          listCurRow.getCell(colNumber + 7).value = dataObj[b]?.detailName;
          listCurRow.getCell(colNumber + 8).value = dataObj[b]?.diameter;
          listCurRow.getCell(colNumber + 9).value = dataObj[b]?.xSize;
          listCurRow.getCell(colNumber + 10).value = dataObj[b]?.ySize;
          listCurRow.getCell(colNumber + 11).value = dataObj[b]?.perimeter;
          listCurRow.getCell(colNumber + 12).value = dataObj[b]?.count;
          listCurRow.commit();
        }
        let dataObjLen = dataObj?.length ? dataObj?.length : 0;
        listCurRowNumber.push(curRow?.number + dataObjLen);
      } else if (workType == WorkType.SmBending) {
        let dataObj = rowData[key];
        for (let b = 0; b < dataObj?.length; b++) {
          let listCurRow = worksheet.getRow(curRow?.number + b);
          colNumber = bendingColIndex;
          listCurRow.getCell(colNumber).value = dataObj[b]?.processCode;
          listCurRow.getCell(colNumber + 1).value = dataObj[b]?.deviceCode;
          listCurRow.getCell(colNumber + 2).value = dataObj[b]?.deviceName;
          listCurRow.getCell(colNumber + 3).value = dataObj[b]?.detailCode;
          listCurRow.getCell(colNumber + 4).value = dataObj[b]?.detailName;
          listCurRow.getCell(colNumber + 5).value = dataObj[b]?.bendLength;
          listCurRow.getCell(colNumber + 6).value = dataObj[b]?.bendCount;
          listCurRow.getCell(colNumber + 7).value = dataObj[b]?.bendCountFR;
          listCurRow.commit();
        }
        let dataObjLen = dataObj?.length ? dataObj?.length : 0;
        listCurRowNumber.push(curRow?.number + dataObjLen);
      } else if (workType == WorkType.SmDeburringAuto || workType == WorkType.SmDeburringManual) {
        if (isDeburr) continue;
        let dataObj = rowData['自動バリ取'];
        let listCurRow = [];
        for (let b = 0; b < dataObj?.length; b++) {
          listCurRow = worksheet.getRow(curRow?.number + b);
          colNumber = bariColIndex;
          listCurRow.getCell(colNumber).value = dataObj[b]?.processCode;
          listCurRow.getCell(colNumber + 1).value = dataObj[b]?.deviceCode;
          listCurRow.getCell(colNumber + 2).value = dataObj[b]?.deviceName;
          listCurRow.getCell(colNumber + 3).value = dataObj[b]?.detailCode;
          listCurRow.getCell(colNumber + 4).value = dataObj[b]?.detailName;
          listCurRow.getCell(colNumber + 5).value = dataObj[b]?.area;
          listCurRow.getCell(colNumber + 6).value = dataObj[b]?.weight;
          listCurRow.getCell(colNumber + 7).value = dataObj[b]?.outerLength;
          listCurRow.getCell(colNumber + 8).value = dataObj[b]?.innerLength;
          listCurRow.getCell(colNumber + 9).value = Number(dataObj[b]?.count);
          listCurRow.commit();
        }
        let dataObjLen = dataObj?.length ? dataObj?.length : 0;
        listCurRowNumber.push(curRow?.number + dataObjLen);
        let dataObjManual = rowData['手動バリ取'];
        let manulRow = listCurRow?.number ? listCurRow?.number + 1 : curRow?.number;
        for (let b = 0; b < dataObjManual?.length; b++) {
          listCurRow = worksheet.getRow(manulRow + b);
          colNumber = bariColIndex;
          listCurRow.getCell(colNumber).value = dataObjManual[b]?.processCode;
          listCurRow.getCell(colNumber + 1).value = dataObjManual[b]?.deviceCode;
          listCurRow.getCell(colNumber + 2).value = dataObjManual[b]?.deviceName;
          listCurRow.getCell(colNumber + 3).value = dataObjManual[b]?.detailCode;
          listCurRow.getCell(colNumber + 4).value = dataObjManual[b]?.detailName;
          listCurRow.getCell(colNumber + 5).value = dataObjManual[b]?.area;
          listCurRow.getCell(colNumber + 6).value = dataObjManual[b]?.weight;
          listCurRow.getCell(colNumber + 7).value = dataObjManual[b]?.outerLength;
          listCurRow.getCell(colNumber + 8).value = dataObjManual[b]?.innerLength;
          listCurRow.getCell(colNumber + 9).value =
            Number(dataObjManual[b]?.outerCount) + Number(dataObjManual[b]?.innerCount);
          listCurRow.commit();
        }
        let dataObjManualLen = dataObjManual?.length ? dataObjManual?.length : 0;
        listCurRowNumber.push(curRow?.number + dataObjManualLen);
        isDeburr = true;
      } else if (workType == WorkType.SmSurface) {
        let dataObj = rowData[key];
        for (let b = 0; b < dataObj?.length; b++) {
          let listCurRow = worksheet.getRow(curRow?.number + b);
          colNumber = surfaceColIndex;
          listCurRow.getCell(colNumber).value = dataObj[b]?.processCode;
          listCurRow.getCell(colNumber + 1).value = dataObj[b]?.deviceCode;
          listCurRow.getCell(colNumber + 2).value = dataObj[b]?.deviceName;
          listCurRow.getCell(colNumber + 3).value = dataObj[b]?.detailCode;
          listCurRow.getCell(colNumber + 4).value = dataObj[b]?.detailName;
          listCurRow.getCell(colNumber + 5).value = dataObj[b]?.calType;
          listCurRow.getCell(colNumber + 6).value = dataObj[b]?.areaWeight;
          listCurRow.getCell(colNumber + 7).value = dataObj[b]?.count;
          listCurRow.commit();
        }
        let dataObjLen = dataObj?.length ? dataObj?.length : 0;
        listCurRowNumber.push(curRow?.number + dataObjLen);
      } else if (workType == WorkType.SmSecondaryWork) {
        let dataObj = rowData[key];
        for (let b = 0; b < dataObj?.length; b++) {
          let listCurRow = worksheet.getRow(curRow?.number + b);
          colNumber = secondaryColIndex;
          listCurRow.getCell(colNumber).value = dataObj[b]?.processCode;
          listCurRow.getCell(colNumber + 1).value = dataObj[b]?.deviceCode;
          listCurRow.getCell(colNumber + 2).value = dataObj[b]?.deviceName;
          listCurRow.getCell(colNumber + 3).value = dataObj[b]?.detailCode;
          listCurRow.getCell(colNumber + 4).value = dataObj[b]?.detailName;
          listCurRow.getCell(colNumber + 5).value = dataObj[b]?.perimeter;
          listCurRow.getCell(colNumber + 6).value = dataObj[b]?.count;
          listCurRow.commit();
        }
        let dataObjLen = dataObj?.length ? dataObj?.length : 0;
        listCurRowNumber.push(curRow?.number + dataObjLen);
      } else if (workType == WorkType.SmWelding) {
        let dataObj = rowData[key];
        for (let b = 0; b < dataObj?.length; b++) {
          let listCurRow = worksheet.getRow(curRow?.number + b);
          colNumber = weldColIndex;
          listCurRow.getCell(colNumber).value = dataObj[b]?.processCode;
          listCurRow.getCell(colNumber + 1).value = dataObj[b]?.deviceCode;
          listCurRow.getCell(colNumber + 2).value = dataObj[b]?.deviceName;
          listCurRow.getCell(colNumber + 3).value = dataObj[b]?.detailCode;
          listCurRow.getCell(colNumber + 4).value = dataObj[b]?.detailName;
          listCurRow.getCell(colNumber + 5).value = dataObj[b]?.type;
          listCurRow.getCell(colNumber + 6).value = dataObj[b]?.number;
          listCurRow.getCell(colNumber + 7).value = dataObj[b]?.count;
          listCurRow.getCell(colNumber + 8).value = dataObj[b]?.yousetsushiage;
          listCurRow.commit();
        }
        let dataObjLen = dataObj?.length ? dataObj?.length : 0;
        listCurRowNumber.push(curRow?.number + dataObjLen);
      } else if (workType == WorkType.SmWeldFinish) {
        let dataObj = rowData[key];
        for (let b = 0; b < dataObj?.length; b++) {
          let listCurRow = worksheet.getRow(curRow?.number + b);
          colNumber = weldFinishColIndex;
          listCurRow.getCell(colNumber).value = dataObj[b]?.processCode;
          listCurRow.getCell(colNumber + 1).value = dataObj[b]?.deviceCode;
          listCurRow.getCell(colNumber + 2).value = dataObj[b]?.deviceName;
          listCurRow.getCell(colNumber + 3).value = dataObj[b]?.detailCode;
          listCurRow.getCell(colNumber + 4).value = dataObj[b]?.detailName;
          listCurRow.getCell(colNumber + 5).value = dataObj[b]?.type;
          listCurRow.getCell(colNumber + 6).value = dataObj[b]?.number;
          listCurRow.getCell(colNumber + 7).value = dataObj[b]?.count;
          listCurRow.commit();
        }
        let dataObjLen = dataObj?.length ? dataObj?.length : 0;
        listCurRowNumber.push(curRow?.number + dataObjLen);
      } else if (workType == WorkType.SmShearing) {
        let dataObj = rowData[key];
        for (let b = 0; b < dataObj?.length; b++) {
          let listCurRow = worksheet.getRow(curRow?.number + b);
          colNumber = sharringColIndex;
          listCurRow.getCell(colNumber).value = dataObj[b]?.processCode;
          listCurRow.getCell(colNumber + 1).value = dataObj[b]?.deviceCode;
          listCurRow.getCell(colNumber + 2).value = dataObj[b]?.deviceName;
          listCurRow.getCell(colNumber + 3).value = dataObj[b]?.detailCode;
          listCurRow.getCell(colNumber + 4).value = dataObj[b]?.detailName;
          listCurRow.getCell(colNumber + 5).value = dataObj[b]?.numOfCuts;
          listCurRow.commit();
        }
        let dataObjLen = dataObj?.length ? dataObj?.length : 0;
        listCurRowNumber.push(curRow?.number + dataObjLen);
      } else if (workType == WorkType.SmInspection) {
        let dataObj = rowData[key];
        for (let b = 0; b < dataObj?.length; b++) {
          let listCurRow = worksheet.getRow(curRow?.number + b);
          colNumber = kensaColIndex;
          listCurRow.getCell(colNumber).value = dataObj[b]?.processCode;
          listCurRow.getCell(colNumber + 1).value = dataObj[b]?.deviceCode;
          listCurRow.getCell(colNumber + 2).value = dataObj[b]?.deviceName;
          listCurRow.getCell(colNumber + 3).value = dataObj[b]?.detailCode;
          listCurRow.getCell(colNumber + 4).value = dataObj[b]?.detailName;
          listCurRow.getCell(colNumber + 5).value = dataObj[b]?.numOfCuts;
          listCurRow.commit();
        }
        let dataObjLen = dataObj?.length ? dataObj?.length : 0;
        listCurRowNumber.push(curRow?.number + dataObjLen);
      } else if (workType == WorkType.SmCustom) {
        let dataObj = rowData[key];
        for (let b = 0; b < dataObj?.length; b++) {
          let listCurRow = worksheet.getRow(curRow?.number + b);
          if (dataObj[b]?.processId === 23) {
            colNumber = addition1ColIndex;
          } else if (dataObj[b]?.processId === 24) {
            colNumber = addition2ColIndex;
          } else if (dataObj[b]?.processId === 25) {
            colNumber = addition3ColIndex;
          } else if (dataObj[b]?.processId === 26) {
            colNumber = addition4ColIndex;
          } else if (dataObj[b]?.processId === 27) {
            colNumber = addition5ColIndex;
          }
          listCurRow.getCell(colNumber).value = dataObj[b]?.processCode;
          listCurRow.getCell(colNumber + 1).value = dataObj[b]?.deviceCode;
          listCurRow.getCell(colNumber + 2).value = dataObj[b]?.deviceName;
          listCurRow.getCell(colNumber + 3).value = dataObj[b]?.detailCode;
          listCurRow.getCell(colNumber + 4).value = dataObj[b]?.detailName;
          listCurRow.getCell(colNumber + 5).value = dataObj[b]?.count;
          listCurRow.commit();
        }
        let dataObjLen = dataObj?.length ? dataObj?.length : 0;
        listCurRowNumber.push(curRow?.number + dataObjLen);
      }
    }
    let maxNum = listCurRowNumber && listCurRowNumber?.length > 0 ? Math.max(...listCurRowNumber) : curRow?.number;
    rowIndex = maxNum;
  }
  let rowCountArr = calculateRowCounts(data);
  headerRow.eachCell(function (cell, colNumber) {
    if (cell.value == 'プログラム') {
      let processNm = parameters?.process?.filter((item) => item.workType == WorkType.SmProgram)?.[0]?.name;
      headerRow.getCell(colNumber + 1).value = rowCountArr.addition[processNm];
    } else if (cell.value == 'ブランク') {
      let processNm = parameters?.process?.filter((item) => item.workType == WorkType.SmBlankCombi)?.[0]?.name;
      let processNmLaser = parameters?.process?.filter((item) => item.workType == WorkType.SmBlankLaser)?.[0]?.name;
      let processNmPunch = parameters?.process?.filter((item) => item.workType == WorkType.SmBlankPunch)?.[0]?.name;
      if (rowCountArr.addition[processNm]) {
        headerRow.getCell(colNumber + 1).value = rowCountArr.addition[processNm];
      } else if (rowCountArr.addition[processNmLaser]) {
        headerRow.getCell(colNumber + 1).value = rowCountArr.addition[processNmLaser];
      } else if (rowCountArr.addition[processNmPunch]) {
        headerRow.getCell(colNumber + 1).value = rowCountArr.addition[processNmPunch];
      }
    } else if (cell.value == 'ベンディング') {
      let processNm = parameters?.process?.filter((item) => item.workType == WorkType.SmBending)?.[0]?.name;
      headerRow.getCell(colNumber + 1).value = rowCountArr.addition[processNm];
    } else if (cell.value == 'バリ取') {
      let burringCnt = 0;
      let processNmAuto = parameters?.process?.filter((item) => item.workType == WorkType.SmDeburringAuto)?.[0]?.name;
      let processNmManual = parameters?.process?.filter((item) => item.workType == WorkType.SmDeburringManual)?.[0]
        ?.name;
      if (rowCountArr.addition[processNmAuto]) {
        burringCnt += rowCountArr.addition[processNmAuto];
      }
      if (rowCountArr.addition[processNmManual]) {
        burringCnt += rowCountArr.addition[processNmManual];
      }
      headerRow.getCell(colNumber + 1).value = burringCnt;
    } else if (cell.value == '表面処理') {
      let processNm = parameters?.process?.filter((item) => item.workType == WorkType.SmSurface)?.[0]?.name;
      headerRow.getCell(colNumber + 1).value = rowCountArr.addition[processNm];
    } else if (cell.value == '2次加工') {
      let processNm = parameters?.process?.filter((item) => item.workType == WorkType.SmSecondaryWork)?.[0]?.name;
      headerRow.getCell(colNumber + 1).value = rowCountArr.addition[processNm];
    } else if (cell.value == '溶接') {
      let processNm = parameters?.process?.filter((item) => item.workType == WorkType.SmWelding)?.[0]?.name;
      headerRow.getCell(colNumber + 1).value = rowCountArr.addition[processNm];
    } else if (cell.value == '溶接仕上') {
      let processNm = parameters?.process?.filter((item) => item.workType == WorkType.SmWeldFinish)?.[0]?.name;
      headerRow.getCell(colNumber + 1).value = rowCountArr.addition[processNm];
    } else if (cell.value == 'シャーリング') {
      let processNm = parameters?.process?.filter((item) => item.workType == WorkType.SmShearing)?.[0]?.name;
      headerRow.getCell(colNumber + 1).value = rowCountArr.addition[processNm];
    } else if (cell.value == '検査') {
      let processNm = parameters?.process?.filter((item) => item.workType == WorkType.SmInspection)?.[0]?.name;
      headerRow.getCell(colNumber + 1).value = rowCountArr.addition[processNm];
    } else if (cell.value == '追加工程①') {
      let processNm = parameters?.process?.filter((item) => item.id == 23)?.[0]?.name;
      headerRow.getCell(colNumber + 1).value = rowCountArr.addition[processNm];
    } else if (cell.value == '追加工程②') {
      let processNm = parameters?.process?.filter((item) => item.id == 24)?.[0]?.name;
      headerRow.getCell(colNumber + 1).value = rowCountArr.addition[processNm];
    } else if (cell.value == '追加工程③') {
      let processNm = parameters?.process?.filter((item) => item.id == 25)?.[0]?.name;
      headerRow.getCell(colNumber + 1).value = rowCountArr.addition[processNm];
    } else if (cell.value == '追加工程④') {
      let processNm = parameters?.process?.filter((item) => item.id == 26)?.[0]?.name;
      headerRow.getCell(colNumber + 1).value = rowCountArr.addition[processNm];
    } else if (cell.value == '追加工程⑤') {
      let processNm = parameters?.process?.filter((item) => item.id == 27)?.[0]?.name;
      headerRow.getCell(colNumber + 1).value = rowCountArr.addition[processNm];
    }
  });
  headerRow.commit();
};
const settingORG7 = (purchaseInfos, worksheet, curRowIndex, type, drawingNo, name, parameters) => {
  let purchases = purchaseInfos?.filter((p) => p.isDeleted === false);
  if (purchases?.length > 0) {
    const row1 = worksheet.getRow(1);
    let curRow = [];
    //不要データクリア
    const lastRow = worksheet.lastRow;
    const rows = worksheet.getRows(curRowIndex, lastRow.number);
    for (let r = 0; r < rows?.length; r++) {
      rows[r].values = [];
      rows[r].commit();
    }
    for (let i = 0; i < purchases?.length; i++) {
      let parPurchaseData = purchases[i];
      curRow = worksheet.getRow(i + curRowIndex);
      row1.eachCell(function (cell, colNumber) {
        if (cell.value == 'No.') {
          curRow.getCell(colNumber).value = i + curRowIndex - 1;
        } else if (cell.value == '区分') {
          curRow.getCell(colNumber).value = type === 0 ? '親部品' : '板金';
        } else if (cell.value == '図番') {
          curRow.getCell(colNumber).value = drawingNo;
        } else if (cell.value == '名称') {
          curRow.getCell(colNumber).value = name;
        } else if (cell.value == '種別') {
          const purchaseCategorys = parameters?.purchaseCategory?.filter(
            (i) => i?.info?.isUsed && i.id === parPurchaseData.purchaseCategoryId
          );
          curRow.getCell(colNumber).value =
            purchaseCategorys && purchaseCategorys?.length > 0 ? purchaseCategorys?.[0]?.name : '';
        } else if (cell.value == '品名') {
          curRow.getCell(colNumber).value = parPurchaseData.name;
        } else if (cell.value == '型番') {
          curRow.getCell(colNumber).value = parPurchaseData.model;
        } else if (cell.value == '単価') {
          curRow.getCell(colNumber).value = parPurchaseData.unitPrice ? Math.round(parPurchaseData.unitPrice) : 0;
        } else if (cell.value == '単価保持') {
          curRow.getCell(colNumber).value = parPurchaseData.isKeepPrice;
        } else if (cell.value == '数量別単価') {
          curRow.getCell(colNumber).value = parPurchaseData.unitPriceByQuantity
            ? Math.round(parPurchaseData.unitPriceByQuantity)
            : 0;
        } else if (cell.value == '必要数') {
          curRow.getCell(colNumber).value = parPurchaseData.required;
        } else if (cell.value == '金額') {
          curRow.getCell(colNumber).value = parPurchaseData.price ? Math.round(parPurchaseData.price) : 0;
        } else if (cell.value == '合計必要数') {
          curRow.getCell(colNumber).value = parPurchaseData.totalRequired;
        } else if (cell.value == '合計金額') {
          curRow.getCell(colNumber).value = parPurchaseData.totalPrice ? Math.round(parPurchaseData.totalPrice) : 0;
        } else if (cell.value == '取付時間') {
          curRow.getCell(colNumber).value = parPurchaseData.processTime
            ? secondsToHms(parPurchaseData.processTime)
            : timeHmsStr;
        } else if (cell.value == '合計取付時間') {
          curRow.getCell(colNumber).value = parPurchaseData.totalProcessTime
            ? secondsToHms(parPurchaseData.totalProcessTime)
            : timeHmsStr;
        } else if (cell.value == '仕入先') {
          curRow.getCell(colNumber).value = parPurchaseData.supplierName;
        } else if (
          cell.value == 'メーカー' ||
          cell.value == 'ﾒｰｶｰ' ||
          cell.value?.richText?.[0]?.text === 'メーカー' ||
          cell.value?.richText?.[0]?.text === 'ﾒｰｶｰ'
        ) {
          curRow.getCell(colNumber).value = parPurchaseData.makerName;
        } else if (cell.value == '備考') {
          curRow.getCell(colNumber).value = parPurchaseData.remarks;
        }
      });
      curRow.commit();
    }
  }
};
const settingKouteibetsuInfo = (estimateData, worksheet) => {
  let totalSetupTm = 0;
  let totalSetupAmt = 0;
  let totalProcessingTm = 0;
  let totalProcessingAmt = 0;
  let processes = estimateData?.estimateProducts?.calcParameters?.parameters?.process;
  let parentProcesses = [];
  let iq3Processes = [];
  if (processes) {
    parentProcesses = processes?.filter((item) => item.class === ServiceClass.Parent);
    iq3Processes = processes?.filter((item) => item.class === ServiceClass.SheetMetal);
  }
  // 数量
  let qty = estimateData?.estimateProducts?.quantity ? Number(estimateData?.estimateProducts?.quantity) : 1;
  const parentInfo = settingParentCalInfo(estimateData, parentProcesses, qty);
  let processIdMap = settingIQ3CalInfo(estimateData, qty);
  // 材料原価
  const materialPrice = estimateData?.estimateProducts?.materialCostTotalPrice
    ? estimateData?.estimateProducts?.materialCostTotalPrice
    : 0;

  const iq3Info = Object.values(processIdMap)?.map((entry, key) => {
    const process = iq3Processes?.find((i) => i.id === entry.processId);
    return {
      key: key + 1,
      processNm: process ? process.name : '',
      setupAmount: entry.setupAmount,
      setupTime: entry.setupTime,
      processingAmount: entry.processingAmount,
      processingTime: entry.processingTime,
      totalAmount: entry.totalAmount,
      unitAmount: entry.unitAmount,
      totalAmountTime: entry.totalAmountTime,
      unitAmountTime: entry.unitAmountTime,
      manufacturingCnt: entry.manufacturingCnt,
    };
  });
  const totalInfo = calTotal(parentInfo, iq3Info, qty);
  const unitAmount = Number.isFinite(totalInfo?.totalAmt / qty) ? totalInfo?.totalAmt / qty : 0;
  const totalTitle = ['No', '工程', '段取時間', '段取原価', '加工時間', '加工原価', '合計時間', '製造原価', '単価'];

  let curRow = worksheet.getRow(1);
  // 合計ヘッダ
  for (let i = 0; i < totalTitle.length; i++) {
    curRow.getCell(i + 1).value = totalTitle[i];
  }
  // 合計データ
  curRow = worksheet.getRow(worksheet.lastRow.number + 1);
  curRow.getCell(2).value = '合計';
  curRow.getCell(3).value = secondsToHms(totalInfo?.setupTime);
  curRow.getCell(4).value = JPYs.format(Math.round(totalInfo?.setupAmt));
  curRow.getCell(5).value = secondsToHms(totalInfo?.workTime);
  curRow.getCell(6).value = JPYs.format(Math.round(totalInfo?.workAmt));
  curRow.getCell(7).value = secondsToHms(totalInfo?.totalTime);
  curRow.getCell(8).value = JPYs.format(Math.round(totalInfo?.totalAmt));
  curRow.getCell(9).value = JPYs.format(Math.round(unitAmount));
  // 合計材料原価
  curRow = worksheet.getRow(worksheet.lastRow.number + 1);
  curRow.getCell(7).value = '材料原価';
  curRow.getCell(8).value = JPYs.format(Math.round(materialPrice));
  curRow.getCell(9).value = JPYs.format(Math.round(materialPrice / qty));

  // 親部品工程
  curRow = worksheet.getRow(worksheet.lastRow.number + 1);
  curRow.getCell(1).value = '親部品工程';
  // 合計ヘッダ
  curRow = worksheet.getRow(worksheet.lastRow.number + 1);
  for (let i = 0; i < totalTitle?.length; i++) {
    curRow.getCell(i + 1).value = totalTitle[i];
  }
  // 親部品工程データ
  curRow = worksheet.getRow(worksheet.lastRow.number + 1);
  let parCurRow = curRow.number;
  for (let i = 0; i < parentInfo?.length; i++) {
    curRow = worksheet.getRow(parCurRow + i);
    let curInfo = parentInfo[i];
    curRow.getCell(1).value = i + 1;
    curRow.getCell(2).value = curInfo?.processNm;
    curRow.getCell(3).value = secondsToHms(curInfo?.setupTime);
    curRow.getCell(4).value = JPYs.format(Math.round(curInfo?.setupAmount));
    curRow.getCell(5).value = secondsToHms(curInfo?.processingTime);
    curRow.getCell(6).value = JPYs.format(Math.round(curInfo?.processingAmount));
    curRow.getCell(7).value = secondsToHms(curInfo?.setupTime + curInfo?.processingTime);
    curRow.getCell(8).value = JPYs.format(Math.round(curInfo?.setupAmount + curInfo?.processingAmount));
    curRow.getCell(9).value = JPYs.format(Math.round((curInfo?.setupAmount + curInfo?.processingAmount) / qty));
    totalSetupTm += curInfo?.setupTime;
    totalSetupAmt += curInfo?.setupAmount;
    totalProcessingTm += curInfo?.processingTime;
    totalProcessingAmt += curInfo?.processingAmount;
  }
  // 合計データ
  curRow = worksheet.getRow(worksheet.lastRow.number + 1);
  curRow.getCell(2).value = '合計';
  curRow.getCell(3).value = secondsToHms(totalSetupTm);
  curRow.getCell(4).value = JPYs.format(Math.round(totalSetupAmt));
  curRow.getCell(5).value = secondsToHms(totalProcessingTm);
  curRow.getCell(6).value = JPYs.format(Math.round(totalProcessingAmt));
  curRow.getCell(7).value = secondsToHms(totalSetupTm + totalProcessingTm);
  curRow.getCell(8).value = JPYs.format(Math.round(totalSetupAmt + totalProcessingAmt));
  curRow.getCell(9).value = JPYs.format(Math.round((totalSetupAmt + totalProcessingAmt) / qty));

  // 板金子部品工程
  totalSetupTm = 0;
  totalSetupAmt = 0;
  totalProcessingTm = 0;
  totalProcessingAmt = 0;
  curRow = worksheet.getRow(worksheet.lastRow.number + 1);
  curRow.getCell(1).value = '板金子部品工程';
  // 合計ヘッダ
  curRow = worksheet.getRow(worksheet.lastRow.number + 1);
  for (let i = 0; i < totalTitle?.length; i++) {
    curRow.getCell(i + 1).value = totalTitle[i];
  }
  // 板金子部品工程データ
  curRow = worksheet.getRow(worksheet.lastRow.number + 1);
  let iq3CurRow = curRow.number;
  for (let i = 0; i < iq3Info?.length; i++) {
    curRow = worksheet.getRow(iq3CurRow + i);
    let curInfo = iq3Info[i];
    curRow.getCell(1).value = i + 1;
    curRow.getCell(2).value = curInfo?.processNm;
    curRow.getCell(3).value = secondsToHms(curInfo?.setupTime);
    curRow.getCell(4).value = JPYs.format(Math.round(curInfo?.setupAmount));
    curRow.getCell(5).value = secondsToHms(curInfo?.processingTime);
    curRow.getCell(6).value = JPYs.format(Math.round(curInfo?.processingAmount));
    curRow.getCell(7).value = secondsToHms(curInfo?.setupTime + curInfo?.processingTime);
    curRow.getCell(8).value = JPYs.format(Math.round(curInfo?.setupAmount + curInfo?.processingAmount));
    curRow.getCell(9).value = JPYs.format(Math.round((curInfo?.setupAmount + curInfo?.processingAmount) / qty));
    totalSetupTm += curInfo?.setupTime;
    totalSetupAmt += curInfo?.setupAmount;
    totalProcessingTm += curInfo?.processingTime;
    totalProcessingAmt += curInfo?.processingAmount;
  }
  // 合計データ
  curRow = worksheet.getRow(worksheet.lastRow.number + 1);
  curRow.getCell(2).value = '合計';
  curRow.getCell(3).value = secondsToHms(totalSetupTm);
  curRow.getCell(4).value = JPYs.format(Math.round(totalSetupAmt));
  curRow.getCell(5).value = secondsToHms(totalProcessingTm);
  curRow.getCell(6).value = JPYs.format(Math.round(totalProcessingAmt));
  curRow.getCell(7).value = secondsToHms(totalSetupTm + totalProcessingTm);
  curRow.getCell(8).value = JPYs.format(Math.round(totalSetupAmt + totalProcessingAmt));
  curRow.getCell(9).value = JPYs.format(Math.round((totalSetupAmt + totalProcessingAmt) / qty));
  // 合計材料原価
  curRow = worksheet.getRow(worksheet.lastRow.number + 1);
  curRow.getCell(7).value = '材料原価';
  curRow.getCell(8).value = JPYs.format(Math.round(materialPrice));
  curRow.getCell(9).value = JPYs.format(Math.round(materialPrice / qty));
  curRow.commit();
};
const settingParentCalInfo = (selectedDataDetail, parentProcesses, quantity) => {
  //親部品の工程積算情報
  let updateKouteiCalInfo = selectedDataDetail?.estimateProducts?.additions;
  if (updateKouteiCalInfo) {
    // 製造原価計算(段取金額＊段取個数係数+作業/加工金額*生産個数係数)
    let processPattern = selectedDataDetail?.estimateProducts?.processSelect;
    // 親部品の計算した工程データの足し算
    let parentResult = [];
    let index = 0;
    updateKouteiCalInfo?.forEach((updatedKouteiContent) => {
      let patternInfoFilter = [];
      let curParPatternInfoFilter = processPattern
        ? processPattern?.details?.filter(
            (item) => item.processId === updatedKouteiContent.processId && item.isUsedForCalc
          )
        : [];
      if (curParPatternInfoFilter && curParPatternInfoFilter?.length > 0) {
        patternInfoFilter = curParPatternInfoFilter[0];
        let process = parentProcesses?.filter((item) => item.id === updatedKouteiContent.processId)?.[0];
        // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
        // 段取原価合計金額計算
        // 段取金額（各工程計算で段取個数係数を参照して計算したため、ここに段取係数を再計算しない。）
        let setupCostPrice =
          updatedKouteiContent.dataType == DataType.Data
            ? updatedKouteiContent.totalDandori.dataItemsAmt
            : updatedKouteiContent.totalDandori.editItemsAmt;
        setupCostPrice = setupCostPrice ? setupCostPrice : 0;

        // 加工原価合計金額計算
        // 作業/加工金額*生産個数係数
        let workCostPrice =
          updatedKouteiContent.dataType == DataType.Data
            ? updatedKouteiContent.totalSagyo.dataItemsAmt
            : updatedKouteiContent.totalSagyo.editItemsAmt;
        workCostPrice = workCostPrice * quantity; // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい

        let totalCostPrice = setupCostPrice + workCostPrice;
        let unitCostPrice = Number.isFinite(totalCostPrice / quantity) ? totalCostPrice / quantity : 0;

        // 段取時間
        let setupCostPriceTm =
          updatedKouteiContent.dataType == DataType.Data
            ? updatedKouteiContent.totalDandori.dataItemsTime
            : updatedKouteiContent.totalDandori.editItemsTime;
        setupCostPriceTm = setupCostPriceTm ? setupCostPriceTm : 0;

        // 加工時間
        let workCostPriceTm =
          updatedKouteiContent.dataType == DataType.Data
            ? updatedKouteiContent.totalSagyo.dataItemsTime
            : updatedKouteiContent.totalSagyo.editItemsTime;
        workCostPriceTm = workCostPriceTm * quantity; // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい

        // 合計時間（段取時間＋(加工時間×数量)　）
        let totalCostPriceTm = setupCostPriceTm + workCostPriceTm;
        // 合計時間（単品）（合計時間÷数量）
        let unitCostPriceTm = Number.isFinite(totalCostPriceTm / quantity) ? totalCostPriceTm / quantity : 0;

        parentResult.push({
          key: index + 1,
          processNm: process?.name,
          setupAmount: setupCostPrice,
          setupTime: setupCostPriceTm,
          processingAmount: workCostPrice,
          processingTime: workCostPriceTm,
          totalCostAmount: totalCostPrice,
          unitCostAmount: unitCostPrice,
          totalCostAmountTime: totalCostPriceTm,
          unitCostAmountTime: unitCostPriceTm,
        });
        index++;
      }
    });
    return parentResult;
  }
};
const settingIQ3CalInfo = (selectedDataDetail, quantity) => {
  let parameters = selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
  let device = selectedDataDetail?.estimateProducts?.calcParameters?.parameters?.device;
  let processIdMap = {};
  selectedDataDetail?.esIq3Info?.forEach((iq3) => {
    let manufacturingCnt = quantity * iq3.partCount;
    let iq3ProcessPattern = iq3?.processSelect;
    iq3.additions?.forEach((iq3UpdatedKouteiContent) => {
      let deviceMstFilter = [];
      //利用する工程パターンを取得
      let patternInfoFilter = [];
      let curPatternInfoFilter = iq3ProcessPattern
        ? iq3ProcessPattern?.details?.filter(
            (item) => item.processId == iq3UpdatedKouteiContent?.processId && item.isUsedForCalc
          )
        : [];
      if (curPatternInfoFilter && curPatternInfoFilter?.length > 0) {
        patternInfoFilter = curPatternInfoFilter[0];
        //複数処理（追加工程）の場合、工程IDで判断できないため、工程詳細IDで判断して対象データを取得
        if (curPatternInfoFilter?.length > 1) {
          let patternInfoFilterByTypeId = curPatternInfoFilter?.filter(
            (item) =>
              item.multiprocesses?.[0]?.processDetailTypeId ===
              iq3UpdatedKouteiContent?.dataItems?.[0]?.processDetailTypeNo
          );
          if (patternInfoFilterByTypeId && patternInfoFilterByTypeId?.length > 0) {
            patternInfoFilter = patternInfoFilterByTypeId[0];
          }
        }
        deviceMstFilter = device
          ? device?.filter(
              (item) => patternInfoFilter?.processId == item.processId && patternInfoFilter?.deviceId == item.id
            )
          : [];
        //　現在の工程タイプ取得
        let curWorkType = parameters?.process?.filter((item) => item.id == iq3UpdatedKouteiContent?.processId)?.[0]
          ?.workType;
        //　段取金額
        let iq3SetupCostPrice =
          iq3UpdatedKouteiContent.dataType == DataType.Data
            ? iq3UpdatedKouteiContent.totalDandori.dataItemsAmt
            : iq3UpdatedKouteiContent.totalDandori.editItemsAmt;
        iq3SetupCostPrice = iq3SetupCostPrice ? iq3SetupCostPrice : 0; // NaNチェック
        //　段取時間
        let iq3SetupCostPriceTime =
          iq3UpdatedKouteiContent.dataType == DataType.Data
            ? iq3UpdatedKouteiContent.totalDandori.dataItemsTime
            : iq3UpdatedKouteiContent.totalDandori.editItemsTime;
        iq3SetupCostPriceTime = iq3SetupCostPriceTime ? iq3SetupCostPriceTime : 0;

        //　作業/加工金額
        let iq3WorkCostPrice =
          iq3UpdatedKouteiContent.dataType == DataType.Data
            ? iq3UpdatedKouteiContent.totalSagyo.dataItemsAmt
            : iq3UpdatedKouteiContent.totalSagyo.editItemsAmt;
        // 作業/加工金額
        iq3WorkCostPrice = iq3WorkCostPrice ? iq3WorkCostPrice : 0; // NaNチェック

        //　作業時間
        let iq3WorkCostPriceTime =
          iq3UpdatedKouteiContent.dataType == DataType.Data
            ? iq3UpdatedKouteiContent.totalSagyo.dataItemsTime
            : iq3UpdatedKouteiContent.totalSagyo.editItemsTime;
        // 作業時間
        iq3WorkCostPriceTime = iq3WorkCostPriceTime ? iq3WorkCostPriceTime : 0; // NaNチェック

        // プログラム工程の場合、プログラム工程の原価は子部品の員数、全体の数量に影響されないため、分けて計算
        if (curWorkType == WorkType.SmProgram) {
          //　プログラム工程の合計の場合、そのまま、製造数と掛けない
          iq3WorkCostPrice = iq3WorkCostPrice;
        } else if (curWorkType == WorkType.SmShearing) {
          // シャーリングOne by one項目チェックを取得
          let oneByoneCheck = deviceMstFilter[0]?.details?.isOneCut;
          if (oneByoneCheck === false) {
            //　One by one項目チェックがOFFの場合、そのまま、製造数と掛けない
            iq3WorkCostPrice = iq3WorkCostPrice;
          } else {
            //　One by one項目チェックがONの場合、製造数と掛ける
            iq3WorkCostPrice = iq3WorkCostPrice * manufacturingCnt;
            iq3WorkCostPriceTime = iq3WorkCostPriceTime * manufacturingCnt;
          }
        } else {
          // 加工時間×製造数
          iq3WorkCostPrice = iq3WorkCostPrice * manufacturingCnt;
          iq3WorkCostPriceTime = iq3WorkCostPriceTime * manufacturingCnt;
        }
        //　合計金額
        let totalCostPrice =
          (iq3SetupCostPrice ? Math.round(iq3SetupCostPrice) : 0) +
          (iq3WorkCostPrice ? Math.round(iq3WorkCostPrice) : 0);
        let unitCostPrice = Number.isFinite(totalCostPrice / quantity) ? totalCostPrice / quantity : 0;
        //　合計時間
        let totalCostPriceTm = iq3SetupCostPriceTime + iq3WorkCostPriceTime;
        let unitCostPriceTm = Number.isFinite(totalCostPriceTm / quantity) ? totalCostPriceTm / quantity : 0;

        const { processId, dataType, totalDandori, totalSagyo } = iq3UpdatedKouteiContent;
        if (!processIdMap[processId]) {
          processIdMap[processId] = {
            processId,
            setupAmount: 0,
            setupTime: 0,
            processingAmount: 0,
            processingTime: 0,
            totalAmount: 0,
            unitAmount: 0,
            totalAmountTime: 0,
            unitAmountTime: 0,
            manufacturingCnt: manufacturingCnt,
          };
        }
        processIdMap[processId].setupAmount += iq3SetupCostPrice;
        processIdMap[processId].processingAmount += iq3WorkCostPrice;
        processIdMap[processId].setupTime += iq3SetupCostPriceTime;
        processIdMap[processId].processingTime += iq3WorkCostPriceTime;
        processIdMap[processId].totalAmount += totalCostPrice;
        processIdMap[processId].unitAmount += unitCostPrice;
        processIdMap[processId].totalAmountTime += totalCostPriceTm;
        processIdMap[processId].unitAmountTime += unitCostPriceTm;
      }
    });
  });

  return processIdMap;
};
const calTotal = (parentData, iq3Data, suryou) => {
  let totalArr = {
    setupTime: 0,
    setupAmt: 0,
    workTime: 0,
    workAmt: 0,
    totalTime: 0,
    totalAmt: 0,
  };
  let setupAmount = 0;
  let processingAmount = 0;
  let setupTime = 0;
  let processingTime = 0;
  let totalTime = 0;
  let totalAmount = 0;
  let tanka = 0;
  if (parentData != null) {
    for (let i = 0; i < parentData?.length; i++) {
      setupAmount += parentData[i].setupAmount;
      processingAmount += parentData[i].processingAmount;
      totalAmount += parentData[i].totalCostAmount;
      tanka += parentData[i].unitCostAmount;
      setupTime += parentData[i].setupTime;
      processingTime += parentData[i].processingTime;
      totalTime += parentData[i].totalCostAmountTime;
    }
  }
  // iq3
  let setupAmount_IQ3 = 0;
  let processingAmount_IQ3 = 0;
  let setupTime_IQ3 = 0;
  let processingTime_IQ3 = 0;
  let totalTime_IQ3 = 0;
  let totalAmount_IQ3 = 0;
  let tanka_IQ3 = 0;
  if (iq3Data != null) {
    for (let i = 0; i < iq3Data?.length; i++) {
      setupAmount_IQ3 += iq3Data[i].setupAmount;
      processingAmount_IQ3 += iq3Data[i].processingAmount;
      totalAmount_IQ3 += iq3Data[i].totalAmount;
      tanka_IQ3 += iq3Data[i].unitAmount;
      setupTime_IQ3 += iq3Data[i].setupTime;
      processingTime_IQ3 += iq3Data[i].processingTime;
      totalTime_IQ3 += iq3Data[i].totalAmountTime;
    }
  }
  totalArr.setupAmt = setupAmount + setupAmount_IQ3;
  totalArr.setupTime = setupTime + setupTime_IQ3;
  totalArr.workAmt = processingAmount + processingAmount_IQ3;
  totalArr.workTime = processingTime + processingTime_IQ3;
  totalArr.totalTime = totalTime + totalTime_IQ3;
  totalArr.totalAmt = totalAmount + totalAmount_IQ3;
  return totalArr;
};
const settingIQ3CalInfoByUnit = (selectedDataDetail, iq3, quantity) => {
  let parameters = selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
  let device = selectedDataDetail?.estimateProducts?.calcParameters?.parameters?.device;
  let processIdMap = {};
  let manufacturingCnt = quantity * iq3.partCount;
  let iq3ProcessPattern = iq3?.processSelect;
  iq3.additions?.forEach((iq3UpdatedKouteiContent) => {
    let deviceMstFilter = [];
    //利用する工程パターンを取得
    let patternInfoFilter = [];
    let curPatternInfoFilter = iq3ProcessPattern
      ? iq3ProcessPattern?.details?.filter(
          (item) => item.processId == iq3UpdatedKouteiContent?.processId && item.isUsedForCalc
        )
      : [];
    if (curPatternInfoFilter && curPatternInfoFilter?.length > 0) {
      patternInfoFilter = curPatternInfoFilter[0];
      //複数処理（追加工程）の場合、工程IDで判断できないため、工程詳細IDで判断して対象データを取得
      if (curPatternInfoFilter?.length > 1) {
        let patternInfoFilterByTypeId = curPatternInfoFilter?.filter(
          (item) =>
            item.multiprocesses?.[0]?.processDetailTypeId ===
            iq3UpdatedKouteiContent?.dataItems?.[0]?.processDetailTypeNo
        );
        if (patternInfoFilterByTypeId && patternInfoFilterByTypeId?.length > 0) {
          patternInfoFilter = patternInfoFilterByTypeId[0];
        }
      }
      deviceMstFilter = device
        ? device?.filter(
            (item) => patternInfoFilter?.processId == item.processId && patternInfoFilter?.deviceId == item.id
          )
        : [];
      //　現在の工程タイプ取得
      let curWorkType = parameters?.process?.filter((item) => item.id == iq3UpdatedKouteiContent?.processId)?.[0]
        ?.workType;
      //　段取金額
      let iq3SetupCostPrice =
        iq3UpdatedKouteiContent.dataType == DataType.Data
          ? iq3UpdatedKouteiContent.totalDandori.dataItemsAmt
          : iq3UpdatedKouteiContent.totalDandori.editItemsAmt;
      iq3SetupCostPrice = iq3SetupCostPrice ? iq3SetupCostPrice : 0; // NaNチェック
      //　段取時間
      let iq3SetupCostPriceTime =
        iq3UpdatedKouteiContent.dataType == DataType.Data
          ? iq3UpdatedKouteiContent.totalDandori.dataItemsTime
          : iq3UpdatedKouteiContent.totalDandori.editItemsTime;
      iq3SetupCostPriceTime = iq3SetupCostPriceTime ? iq3SetupCostPriceTime : 0;

      //　作業/加工金額
      let iq3WorkCostPrice =
        iq3UpdatedKouteiContent.dataType == DataType.Data
          ? iq3UpdatedKouteiContent.totalSagyo.dataItemsAmt
          : iq3UpdatedKouteiContent.totalSagyo.editItemsAmt;
      // 作業/加工金額
      iq3WorkCostPrice = iq3WorkCostPrice ? iq3WorkCostPrice : 0; // NaNチェック

      //　作業時間
      let iq3WorkCostPriceTime =
        iq3UpdatedKouteiContent.dataType == DataType.Data
          ? iq3UpdatedKouteiContent.totalSagyo.dataItemsTime
          : iq3UpdatedKouteiContent.totalSagyo.editItemsTime;
      // 作業時間
      iq3WorkCostPriceTime = iq3WorkCostPriceTime ? iq3WorkCostPriceTime : 0; // NaNチェック

      // プログラム工程の場合、プログラム工程の原価は子部品の員数、全体の数量に影響されないため、分けて計算
      if (curWorkType == WorkType.SmProgram) {
        //　プログラム工程の合計の場合、そのまま、製造数と掛けない
        iq3WorkCostPrice = iq3WorkCostPrice;
      } else if (curWorkType == WorkType.SmShearing) {
        // シャーリングOne by one項目チェックを取得
        let oneByoneCheck = deviceMstFilter[0]?.details?.isOneCut;
        if (oneByoneCheck === false) {
          //　One by one項目チェックがOFFの場合、そのまま、製造数と掛けない
          iq3WorkCostPrice = iq3WorkCostPrice;
        } else {
          //　One by one項目チェックがONの場合、製造数と掛ける
          iq3WorkCostPrice = iq3WorkCostPrice * manufacturingCnt;
          iq3WorkCostPriceTime = iq3WorkCostPriceTime * manufacturingCnt;
        }
      } else {
        // 加工時間×製造数
        iq3WorkCostPrice = iq3WorkCostPrice * manufacturingCnt;
        iq3WorkCostPriceTime = iq3WorkCostPriceTime * manufacturingCnt;
      }
      //　合計金額
      let totalCostPrice =
        (iq3SetupCostPrice ? Math.round(iq3SetupCostPrice) : 0) + (iq3WorkCostPrice ? Math.round(iq3WorkCostPrice) : 0);
      let unitCostPrice = Number.isFinite(totalCostPrice / quantity) ? totalCostPrice / quantity : 0;
      //　合計時間
      let totalCostPriceTm = iq3SetupCostPriceTime + iq3WorkCostPriceTime;
      let unitCostPriceTm = Number.isFinite(totalCostPriceTm / quantity) ? totalCostPriceTm / quantity : 0;

      const { processId, dataType, totalDandori, totalSagyo } = iq3UpdatedKouteiContent;
      if (!processIdMap[processId]) {
        processIdMap[processId] = {
          processId,
          setupAmount: 0,
          setupTime: 0,
          processingAmount: 0,
          processingTime: 0,
          totalAmount: 0,
          unitAmount: 0,
          totalAmountTime: 0,
          unitAmountTime: 0,
          manufacturingCnt: manufacturingCnt,
        };
      }
      processIdMap[processId].setupAmount += iq3SetupCostPrice;
      processIdMap[processId].processingAmount += iq3WorkCostPrice;
      processIdMap[processId].setupTime += iq3SetupCostPriceTime;
      processIdMap[processId].processingTime += iq3WorkCostPriceTime;
      processIdMap[processId].totalAmount += totalCostPrice;
      processIdMap[processId].unitAmount += unitCostPrice;
      processIdMap[processId].totalAmountTime += totalCostPriceTm;
      processIdMap[processId].unitAmountTime += unitCostPriceTm;
    }
  });

  return processIdMap;
};

export async function convertServerExcelToHtml(excelData, selectedEstimateData, iq3Infos) {
  try {
    const s3Stream = await getS3FileContent(excelData?.id);
    settingIndividualReportData(selectedEstimateData, iq3Infos, s3Stream, excelData);
  } catch (error) {
    console.error('Error converting Excel to HTML:', error);
  }
}

//Excel File出力
export const handleExportToExcel = (contents, title) => {
  const wb = XLSX.utils.book_new();

  contents?.forEach(({ sheetName, html }) => {
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = html;

    const table = tempContainer.querySelector('table');

    if (table) {
      const ws = XLSX.utils.table_to_sheet(tempContainer);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
    }
  });

  XLSX.writeFile(wb, title);
};

// 材料詳細パターン
const materialDetailPatterns = [
  { value: PatternType.Pattern1, label: 'パターン1' },
  { value: PatternType.Pattern2, label: 'パターン2' },
];

// 材料詳細パターン名
export const getMaterialDetailPatternName = (patternNo) => {
  if (patternNo === undefined) return '';
  return materialDetailPatterns?.find((pattern) => pattern.value === patternNo)?.label;
};

export const scaleSet = (id, sheetX, sheetY, canvas = '') => {
  // const canvas = document.getElementById('canvas');
  let scale = 1;
  let x = canvas.width;
  let y = canvas.height;
  if (x < 0 || y < 0) return 0;
  let a = x / y; // CanvasのScale
  let b = sheetX / sheetY; // 材料サイズScale
  if (a > b) scale = y / sheetY;
  else scale = x / sheetX;
  return scale;
};

// Scale
let ca1Scale = 1;
let ca2Scale = 1;
export const draw = (partInfo, settingInfo, materialInfo, isPattern2, ySetting, rightFit, topFit, canvas = '') => {
  let partCntInfo = {
    partCntX: 1,
    partCntY: 1,
    p1NoOfMaterial: 0,
    p1NoOfPlace: 0,
    p2NoOfPlace: 0,
    maxNoOfPlace: 0,
    p1PartCntX: 0,
    p1PartCntY: 0,
    p2PartCntX: 0,
    p2PartCntY: 0,
    invalidMaterial: false,
    invalidXMaterial: false,
    invalidYMaterial: false,
  };
  if (partInfo.mPartX <= 0 || partInfo.mPartY <= 0) return;
  if (materialInfo.mSheetX < 0 || materialInfo.mSheetY < 0) return;

  if (canvas !== '') {
    //　指定したCanvasに配置できるScaleを計算
    ca1Scale = scaleSet(0, materialInfo.mSheetX, materialInfo.mSheetY, canvas);
    if (ca1Scale == 0) return;
  }

  //　利用可能な材料サイズ（材料ー掴み代）配置
  let materialX = materialInfo.mSheetX - settingInfo.mClampdzX;
  let materialY = materialInfo.mSheetY - settingInfo.mClampdzY;
  materialX = toDecimal(materialX, 2);
  materialY = toDecimal(materialY, 2);
  drawRect(0, { X: 0, Y: 0 }, { X: materialInfo.mSheetX, Y: materialInfo.mSheetY }, 0, canvas);

  // 掴み配置
  //mClampdzX
  let mClampdzXDrawInfo = drawRect(0, { X: 0, Y: 0 }, { X: settingInfo.mClampdzX, Y: materialInfo.mSheetY }, 2, canvas);
  // mClampdzY
  drawRect(
    0,
    { X: 0, Y: materialInfo.mSheetY - settingInfo.mClampdzY },
    { X: materialInfo.mSheetX, Y: settingInfo.mClampdzY },
    2,
    canvas
  );

  // 1つの製品を配置する時に使用する矩形サイズを計算する
  // 製品の矩形X
  let settingPartX = partInfo.mPartX + settingInfo.mMarginX;
  let settingPartY = partInfo.mPartY + settingInfo.mMarginY;
  settingPartX = toDecimal(settingPartX, 2);
  settingPartY = toDecimal(settingPartY, 2);
  // 材料に配置できる総数を確認する(X方向)
  let settingPartCntX = Math.floor(materialX / settingPartX);
  let clearSettingPartCntX = settingPartCntX;
  // 材料に配置できる総数を確認する(Y方向)
  let settingPartCntY = Math.floor(materialY / settingPartY);
  // settingPartCntY = settingPartCntY <= 0 ? 1 : settingPartCntY; // IQX_WEBEST-203の対応、クローズする
  let clearSettingPartCntY = settingPartCntY;

  // 材料に配置できる最大数を計算する（パターン１に配置する部品数）
  let settingMaxCnt = Math.floor(settingPartCntX * settingPartCntY);
  partCntInfo.maxNoOfPlace = settingMaxCnt; // 最大加工数

  // パターン校数
  let n = Math.floor(partInfo.totalProcessSuryou / settingMaxCnt);
  //材料校数が1より小さい場合、１校数で配置できるXY方向を再計算
  if (n < 1) {
    // Y方向
    if (!ySetting) {
      settingPartCntY = Math.floor(partInfo.totalProcessSuryou / settingPartCntX) + 1;
    } else {
      settingPartCntX = Math.floor(partInfo.totalProcessSuryou / settingPartCntY) + 1;
    }
    clearSettingPartCntY = settingPartCntY;
    settingMaxCnt = partInfo.totalProcessSuryou;
    // バグ：158の修正-START
    if (!ySetting) {
      if (settingMaxCnt < settingPartCntX) {
        settingPartCntX = settingMaxCnt;
      }
    } else {
      // Y方向
      if (settingMaxCnt < settingPartCntY) {
        settingPartCntY = settingMaxCnt;
      }
    }
    // バグ：158の修正-END
    n = 1;
  }
  partCntInfo.p1NoOfMaterial = n; // パターン1の材料枚数
  partCntInfo.p1NoOfPlace = settingMaxCnt; // パターン1の配置数
  partCntInfo.p1PartCntX = settingPartCntX; // パターン1のX配置数
  partCntInfo.p1PartCntY = settingPartCntY; // パターン1のY配置数

  // パターン２の場合、最大部品数 － パターン１に配置する部品数
  if (isPattern2) {
    // Totalcount ＞ Maxcount *nかつ n＝1の場合、パターン1の配置が1枚とパターン2の配置が必要となる
    if (partInfo.totalProcessSuryou > settingMaxCnt * n && n == 1) {
      settingMaxCnt = partInfo.totalProcessSuryou - settingMaxCnt;
      if (!ySetting) {
        // settingPartCntY = Math.round(settingMaxCnt / settingPartCntX);
        settingPartCntY = Math.floor(settingMaxCnt / settingPartCntX) + 1;
        if (settingMaxCnt < settingPartCntX) {
          settingPartCntX = settingMaxCnt;
        }
      } else {
        if (settingMaxCnt < settingPartCntY) {
          settingPartCntY = settingMaxCnt;
        }
      }
    } else if (partInfo.totalProcessSuryou > settingMaxCnt * n && n > 1) {
      // Totalcount ＞ Maxcount *nかつ n≠1の場合、パターン1の配置がｎ枚とパターン2の配置が必要となる
      settingMaxCnt = partInfo.totalProcessSuryou - settingMaxCnt * n;
      if (!ySetting) {
        // settingPartCntY = Math.round(settingMaxCnt / settingPartCntX);
        settingPartCntY = Math.floor(settingMaxCnt / settingPartCntX) + 1;
        if (settingMaxCnt < settingPartCntX) {
          settingPartCntX = settingMaxCnt;
        }
      } else {
        if (settingMaxCnt < settingPartCntY) {
          settingPartCntY = settingMaxCnt;
        }
      }
    } else if (partInfo.totalProcessSuryou == settingMaxCnt * n && partInfo.totalProcessSuryou == n) {
      settingPartCntY = 0;
    } else if (partInfo.totalProcessSuryou <= settingMaxCnt * n && n == 1) {
      settingPartCntY = 0;
    }
    clearSettingPartCntY = settingPartCntY;
  }

  let rectStartX = mClampdzXDrawInfo.widthX;
  let rectStartY = (materialInfo.mSheetY - settingInfo.mClampdzY) * ca1Scale;
  rectStartY = rectStartY - partInfo.mPartY * ca1Scale;
  let drawRectInfo = {};

  if (settingPartCntX > 0 && settingPartCntY > 0) {
    // 部品合計数X
    partCntInfo.partCntX = settingPartCntX;
    partCntInfo.p2PartCntX = settingPartCntX; // パターン2のX配置数

    // パターン１又パターン２はleftToRight設定の場合
    if ((!ySetting && !isPattern2) || (!ySetting && isPattern2)) {
      // 部品矩形配置Y
      let fillCnt = settingPartCntX;
      for (let y = 0; y < settingPartCntY; y++) {
        rectStartX = mClampdzXDrawInfo.widthX;
        if (y > 0) {
          rectStartY = drawRectInfo.widthY - settingInfo.mMarginY * ca1Scale;
          if (fillCnt == settingMaxCnt) {
            settingPartCntX = settingPartCntX;
            fillCnt = fillCnt;
          } else if (fillCnt + settingPartCntX > settingMaxCnt) {
            settingPartCntX = settingMaxCnt - fillCnt;
            fillCnt = fillCnt + settingPartCntX;
          }
        }
        // 部品矩形配置X
        for (let x = 0; x < settingPartCntX; x++) {
          if (x > 0) {
            rectStartX = drawRectInfo.widthX + settingInfo.mMarginX * ca1Scale;
          }
          drawRectInfo = drawRect(
            0,
            { X: rectStartX, Y: rectStartY },
            { X: partInfo.mPartX, Y: partInfo.mPartY },
            1,
            canvas
          );
        }
        if (fillCnt != settingMaxCnt) {
          fillCnt = settingPartCntX * (y + 1);
        }
      }
    } else {
      // パターン１又パターン２はBottomToTop設定の場合
      let fillCnt = settingPartCntY;
      let p2settingPartCntY = settingPartCntY;
      for (let max = 0; fillCnt < settingMaxCnt || max == 0; max++) {
        // 部品矩形配置X
        if (max > 0) {
          rectStartX = drawRectInfo.widthX + settingInfo.mMarginX * ca1Scale;
          rectStartY = (materialInfo.mSheetY - settingInfo.mClampdzY) * ca1Scale;
          rectStartY = rectStartY - partInfo.mPartY * ca1Scale;
          if (fillCnt == settingMaxCnt) {
            settingPartCntY = settingPartCntY;
            fillCnt = fillCnt;
          } else if (fillCnt + settingPartCntY > settingMaxCnt) {
            settingPartCntY = settingMaxCnt - fillCnt;
            fillCnt = fillCnt + settingPartCntY;
          }
        }
        // 部品矩形配置Y
        for (let y = 0; y < settingPartCntY; y++) {
          if (y > 0) {
            rectStartY = drawRectInfo.widthY - settingInfo.mMarginY * ca1Scale;
          }
          drawRectInfo = drawRect(
            0,
            { X: rectStartX, Y: rectStartY },
            { X: partInfo.mPartX, Y: partInfo.mPartY },
            1,
            canvas
          );
        }

        if (fillCnt != settingMaxCnt) {
          fillCnt = settingPartCntY * (max + 1);
        }
        settingPartCntX = max + 1;
        partCntInfo.partCntX = settingPartCntX;
        partCntInfo.p2PartCntX = settingPartCntX; // パターン2のX配置数
        clearSettingPartCntX = settingPartCntX;
      }
      settingPartCntY = p2settingPartCntY;
    }
    // 部品合計数X
    partCntInfo.partCntY = settingPartCntY;
    partCntInfo.p2PartCntY = settingPartCntY; // パターン2のY配置数

    if (canvas != '') {
      //　材料のFit配置
      // const canvas = canvas;
      const canvasContext = canvas.getContext('2d');
      canvasContext.beginPath();
      // 左Fit配置
      if (rightFit) {
        let startClearX = settingInfo.mClampdzX * ca1Scale + settingPartX * clearSettingPartCntX * ca1Scale;
        let clearX = materialInfo.mSheetX * ca1Scale - startClearX;
        canvasContext.clearRect(startClearX, 0, clearX, canvas.height);
      }
      // 上Fit配置
      if (topFit) {
        let clearY = settingInfo.mClampdzY * ca1Scale + settingPartY * clearSettingPartCntY * ca1Scale;
        clearY = materialInfo.mSheetY * ca1Scale - clearY;
        canvasContext.clearRect(0, 0, canvas.width, clearY);
      }
    }
  } else {
    // 部品合計数X
    partCntInfo.partCntX = 0;
    partCntInfo.partCntY = 0;
  }
  partCntInfo.p2NoOfPlace = settingMaxCnt; // パターン2の配置数
  return partCntInfo;
};

export const freeDraw = (
  partInfo,
  settingInfo,
  materialInfo,
  isPattern2,
  ySetting,
  rightFit,
  topFit,
  canvas = '',
  isExistPattern2
) => {
  let partCntInfo = {
    partCntX: 1,
    partCntY: 1,
    p1NoOfMaterial: 0,
    p1NoOfPlace: 0,
    p2NoOfPlace: 0,
    maxNoOfPlace: 0,
    p1PartCntX: 0,
    p1PartCntY: 0,
    p2PartCntX: 0,
    p2PartCntY: 0,
    invalidMaterial: false,
    invalidXMaterial: false,
    invalidYMaterial: false,
  };
  if (partInfo.mPartX <= 0 || partInfo.mPartY <= 0) return;
  if (materialInfo.p1MSheetX < 0 || materialInfo.p1MSheetY < 0) return;

  if (canvas !== '') {
    //　指定したCanvasに配置できるScaleを計算
    if (!isPattern2) {
      ca1Scale = scaleSet(0, materialInfo.p1MSheetX, materialInfo.p1MSheetY, canvas);
    } else {
      ca1Scale = scaleSet(0, materialInfo.p2MSheetX, materialInfo.p2MSheetY, canvas);
    }
    if (ca1Scale == 0) return;
  }

  //　利用可能な材料サイズ（材料ー掴み代）配置
  let materialX = materialInfo.p1MSheetX - settingInfo.mClampdzX;
  let materialY = materialInfo.p1MSheetY - settingInfo.mClampdzY;
  materialX = toDecimal(materialX, 2);
  materialY = toDecimal(materialY, 2);
  if (!isPattern2) {
    drawRect(0, { X: 0, Y: 0 }, { X: materialInfo.p1MSheetX, Y: materialInfo.p1MSheetY }, 0, canvas);
  } else {
    drawRect(0, { X: 0, Y: 0 }, { X: materialInfo.p2MSheetX, Y: materialInfo.p2MSheetY }, 0, canvas);
  }
  // 掴み配置
  //mClampdzX
  let mClampdzXDrawInfo = drawRect(
    0,
    { X: 0, Y: 0 },
    { X: settingInfo.mClampdzX, Y: !isPattern2 ? materialInfo.p1MSheetY : materialInfo.p2MSheetY },
    2,
    canvas
  );
  // mClampdzY
  drawRect(
    0,
    { X: 0, Y: (!isPattern2 ? materialInfo.p1MSheetY : materialInfo.p2MSheetY) - settingInfo.mClampdzY },
    { X: !isPattern2 ? materialInfo.p1MSheetX : materialInfo.p2MSheetX, Y: settingInfo.mClampdzY },
    2,
    canvas
  );
  // パターン２がない場合、配置しない。
  if (isPattern2 && !isExistPattern2 && canvas !== '') {
    return partCntInfo;
  } else {
    // 1つの製品を配置する時に使用する矩形サイズを計算する
    // 製品の矩形X
    let settingPartX = partInfo.mPartX + settingInfo.mMarginX;
    let settingPartY = partInfo.mPartY + settingInfo.mMarginY;
    settingPartX = toDecimal(settingPartX, 2);
    settingPartY = toDecimal(settingPartY, 2);
    // 材料に配置できる総数を確認する(X方向)
    let settingPartCntX = Math.floor(materialX / settingPartX);
    if (settingPartCntX === 0) {
      partCntInfo.invalidXMaterial = true;
    }
    let clearSettingPartCntX = settingPartCntX;
    // 材料に配置できる総数を確認する(Y方向)
    let settingPartCntY = Math.floor(materialY / settingPartY);
    if (settingPartCntY === 0) {
      partCntInfo.invalidYMaterial = true;
    }
    // settingPartCntY = settingPartCntY <= 0 ? 1 : settingPartCntY; // IQX_WEBEST-203の対応、クローズする
    let clearSettingPartCntY = settingPartCntY;

    // 材料に配置できる最大数を計算する（パターン１に配置する部品数）
    let settingMaxCnt = Math.floor(settingPartCntX * settingPartCntY);
    partCntInfo.maxNoOfPlace = settingMaxCnt; // 最大加工数

    // パターン校数
    let n = Math.floor(partInfo.totalProcessSuryou / settingMaxCnt);
    //材料校数が1より小さい場合、１校数で配置できるXY方向を再計算
    if (n < 1) {
      // Y方向
      if (!ySetting) {
        settingPartCntY =
          partInfo.totalProcessSuryou % settingPartCntX === 0
            ? Math.floor(partInfo.totalProcessSuryou / settingPartCntX)
            : Math.floor(partInfo.totalProcessSuryou / settingPartCntX) + 1;
      } else {
        settingPartCntX =
          partInfo.totalProcessSuryou % settingPartCntY === 0
            ? Math.floor(partInfo.totalProcessSuryou / settingPartCntY)
            : Math.floor(partInfo.totalProcessSuryou / settingPartCntY) + 1;
      }
      settingMaxCnt = partInfo.totalProcessSuryou;
      // バグ：158の修正-START
      if (!ySetting) {
        if (settingMaxCnt < settingPartCntX) {
          settingPartCntX = settingMaxCnt;
        }
      } else {
        // Y方向
        if (settingMaxCnt < settingPartCntY) {
          settingPartCntY = settingMaxCnt;
        }
      }
      // バグ：158の修正-END
      n = 1;
    }
    partCntInfo.p1NoOfMaterial = n; // パターン1の材料枚数
    partCntInfo.p1NoOfPlace = settingMaxCnt; // パターン1の配置数
    partCntInfo.p1PartCntX = settingPartCntX; // パターン1のX配置数
    partCntInfo.p1PartCntY = settingPartCntY; // パターン1のY配置数
    clearSettingPartCntY = settingPartCntY;

    // パターン２の場合、最大部品数 － パターン１に配置する部品数
    if (isPattern2) {
      if (materialInfo.p2MSheetX < 0 || materialInfo.p2MSheetY < 0) return;

      //　利用可能な材料サイズ（材料ー掴み代）配置
      materialX = materialInfo.p2MSheetX - settingInfo.mClampdzX;
      materialY = materialInfo.p2MSheetY - settingInfo.mClampdzY;

      // 材料に配置できる総数を確認する(X方向)
      settingPartCntX = Math.floor(materialX / settingPartX);
      // 材料に配置できる総数を確認する(Y方向)
      settingPartCntY = Math.floor(materialY / settingPartY);
      settingPartCntY = settingPartCntY <= 0 ? 1 : settingPartCntY;
      let orgP2PartCntY = settingPartCntY; // Drawing前、パターン2のy配置数
      // Totalcount ＞ Maxcount *nかつ n＝1の場合、パターン1の配置が1枚とパターン2の配置が必要となる
      if (partInfo.totalProcessSuryou > settingMaxCnt * n && n == 1) {
        settingMaxCnt = partInfo.totalProcessSuryou - settingMaxCnt;
        if (!ySetting) {
          // settingPartCntY = Math.round(settingMaxCnt / settingPartCntX);
          settingPartCntY =
            settingMaxCnt % settingPartCntX === 0
              ? Math.floor(settingMaxCnt / settingPartCntX)
              : Math.floor(settingMaxCnt / settingPartCntX) + 1;
          if (settingMaxCnt < settingPartCntX) {
            settingPartCntX = settingMaxCnt;
          }
        } else {
          if (settingMaxCnt < settingPartCntY) {
            settingPartCntY = settingMaxCnt;
          }
        }
      } else if (partInfo.totalProcessSuryou > settingMaxCnt * n && n > 1) {
        // Totalcount ＞ Maxcount *nかつ n≠1の場合、パターン1の配置がｎ枚とパターン2の配置が必要となる
        settingMaxCnt = partInfo.totalProcessSuryou - settingMaxCnt * n;
        if (!ySetting) {
          // settingPartCntY = Math.round(settingMaxCnt / settingPartCntX);
          settingPartCntY =
            settingMaxCnt % settingPartCntX === 0
              ? Math.floor(settingMaxCnt / settingPartCntX)
              : Math.floor(settingMaxCnt / settingPartCntX) + 1;
          if (settingMaxCnt < settingPartCntX) {
            settingPartCntX = settingMaxCnt;
          }
        } else {
          if (settingMaxCnt < settingPartCntY) {
            settingPartCntY = settingMaxCnt;
          }
        }
      } else if (partInfo.totalProcessSuryou == settingMaxCnt * n && partInfo.totalProcessSuryou == n) {
        settingPartCntY = 0;
      } else if (partInfo.totalProcessSuryou <= settingMaxCnt * n && n == 1) {
        settingPartCntY = 0;
      }
      if (orgP2PartCntY < settingPartCntY) {
        partCntInfo.invalidMaterial = true;
      }
      clearSettingPartCntY = settingPartCntY;
    }

    let rectStartX = mClampdzXDrawInfo.widthX;
    let rectStartY =
      ((isPattern2 ? materialInfo.p2MSheetY : materialInfo.p1MSheetY) - settingInfo.mClampdzY) * ca1Scale;
    rectStartY = rectStartY - partInfo.mPartY * ca1Scale;
    let drawRectInfo = {};

    if (settingPartCntX > 0 && settingPartCntY > 0) {
      // 部品合計数X
      partCntInfo.partCntX = settingPartCntX;
      partCntInfo.p2PartCntX = settingPartCntX; // パターン2のX配置数
      let orgP2PartCntX = settingPartCntX; // Drawing前、パターン2のX配置数
      let p2settingPartCntY = settingPartCntY;

      // パターン１又パターン２はleftToRight設定の場合
      if ((!ySetting && !isPattern2) || (!ySetting && isPattern2)) {
        // 部品矩形配置Y
        let fillCnt = settingPartCntX;
        for (let y = 0; y < settingPartCntY; y++) {
          rectStartX = mClampdzXDrawInfo.widthX;
          if (y > 0) {
            rectStartY = drawRectInfo.widthY - settingInfo.mMarginY * ca1Scale;
            if (fillCnt == settingMaxCnt) {
              settingPartCntX = settingPartCntX;
              fillCnt = fillCnt;
            } else if (fillCnt + settingPartCntX > settingMaxCnt) {
              settingPartCntX = settingMaxCnt - fillCnt;
              fillCnt = fillCnt + settingPartCntX;
            }
          }
          // 部品矩形配置X
          for (let x = 0; x < settingPartCntX; x++) {
            if (x > 0) {
              rectStartX = drawRectInfo.widthX + settingInfo.mMarginX * ca1Scale;
            }
            drawRectInfo = drawRect(
              0,
              { X: rectStartX, Y: rectStartY },
              { X: partInfo.mPartX, Y: partInfo.mPartY },
              1,
              canvas
            );
          }
          if (fillCnt != settingMaxCnt) {
            fillCnt = settingPartCntX * (y + 1);
          }
        }
      } else {
        // パターン１又パターン２はBottomToTop設定の場合
        let fillCnt = settingPartCntY;
        for (let max = 0; fillCnt < settingMaxCnt || max == 0; max++) {
          // 部品矩形配置X
          if (max > 0) {
            rectStartX = drawRectInfo.widthX + settingInfo.mMarginX * ca1Scale;
            rectStartY =
              ((isPattern2 ? materialInfo.p2MSheetY : materialInfo.p1MSheetY) - settingInfo.mClampdzY) * ca1Scale;
            rectStartY = rectStartY - partInfo.mPartY * ca1Scale;
            if (fillCnt == settingMaxCnt) {
              settingPartCntY = settingPartCntY;
              fillCnt = fillCnt;
            } else if (fillCnt + settingPartCntY > settingMaxCnt) {
              settingPartCntY = settingMaxCnt - fillCnt;
              fillCnt = fillCnt + settingPartCntY;
            }
          }
          // 部品矩形配置Y
          for (let y = 0; y < settingPartCntY; y++) {
            if (y > 0) {
              rectStartY = drawRectInfo.widthY - settingInfo.mMarginY * ca1Scale;
            }
            drawRectInfo = drawRect(
              0,
              { X: rectStartX, Y: rectStartY },
              { X: partInfo.mPartX, Y: partInfo.mPartY },
              1,
              canvas
            );
          }

          if (fillCnt != settingMaxCnt) {
            fillCnt = settingPartCntY * (max + 1);
          }
          settingPartCntX = max + 1;
          partCntInfo.partCntX = settingPartCntX;
          partCntInfo.p2PartCntX = settingPartCntX; // パターン2のX配置数
          clearSettingPartCntX = settingPartCntX;
        }
        if (orgP2PartCntX < settingPartCntX) {
          partCntInfo.invalidMaterial = true;
        }
        settingPartCntY = p2settingPartCntY;
      }
      // 部品合計数X
      partCntInfo.partCntY = settingPartCntY;
      partCntInfo.p2PartCntY = settingPartCntY; // パターン2のY配置数

      if (canvas != '') {
        //　材料のFit配置
        // const canvas = canvas;
        const canvasContext = canvas.getContext('2d');
        canvasContext.beginPath();
        // 左Fit配置
        if (rightFit) {
          let startClearX = settingInfo.mClampdzX * ca1Scale + settingPartX * clearSettingPartCntX * ca1Scale;
          let clearX = (isPattern2 ? materialInfo.p2MSheetX : materialInfo.p1MSheetX) * ca1Scale - startClearX;
          canvasContext.clearRect(startClearX, 0, clearX, canvas.height);
        }
        // 上Fit配置
        if (topFit) {
          let clearY = settingInfo.mClampdzY * ca1Scale + settingPartY * clearSettingPartCntY * ca1Scale;
          clearY = (isPattern2 ? materialInfo.p2MSheetY : materialInfo.p1MSheetY) * ca1Scale - clearY;
          canvasContext.clearRect(0, 0, canvas.width, clearY);
        }
      }
    } else {
      if (settingPartCntX === 0) {
        partCntInfo.invalidXMaterial = true;
      }
      if (settingPartCntY === 0) {
        partCntInfo.invalidYMaterial = true;
      }
      // 部品合計数X
      partCntInfo.partCntX = 0;
      partCntInfo.partCntY = 0;
    }
    partCntInfo.p2NoOfPlace = settingMaxCnt; // パターン2の配置数
    return partCntInfo;
  }
};

export const drawRect = (id, pt, sz, type, canvas = '') => {
  let canvasContext;
  if (canvas !== '') {
    // const canvas = canvas;
    canvasContext = canvas.getContext('2d');
    canvasContext.beginPath();
  }
  let p1 = { X: 0, Y: 0 };
  let p2 = { X: 0, Y: 0 };
  // パターン１計算Scale
  let scale = ca1Scale;
  // パターン２計算Scale
  if (id == 1) scale = ca2Scale;

  // 開始XY
  p1.X = pt.X * scale;
  p1.Y = pt.Y * scale;
  let startX = type === 1 ? pt.X : p1.X;
  let startY = type === 1 ? pt.Y : p1.Y;
  // 幅XY
  p2.X = sz.X * scale;
  p2.Y = sz.Y * scale;
  let widthX = p2.X;
  let widthY = p2.Y;

  // 材料サイズ色
  if (type == 0) {
    const { backgroundColor = 'black' } = { backgroundColor: 'LightSteelBlue' };
    if (canvas !== '') canvasContext.fillStyle = backgroundColor;
  } else if (type == 1) {
    // 各部品矩形色
    const { backgroundColor = 'black' } = { backgroundColor: 'Gray' };
    if (canvas !== '') canvasContext.fillStyle = backgroundColor;
  } else {
    // 掴み代色
    const { backgroundColor = 'black' } = { backgroundColor: 'LightGray' };
    if (canvas !== '') canvasContext.fillStyle = backgroundColor;
  }

  if (canvas !== '') canvasContext.fillRect(startX, startY, widthX, widthY);
  let drawWidthHeight = {
    widthX: startX + widthX,
    widthY: startY - widthY,
  };
  return drawWidthHeight;
};

export const formatPMXDate = (record) => {
  const date = new Date(record);
  const formattedDate = `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
    .getDate()
    .toString()
    .padStart(2, '0')}`;
  const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;
  const updDate = formattedDate + ' ' + formattedTime;
  return updDate;
};

// 板金見積の工程入力データ作成
export const getDetails = (workType, param, processSelect) => {
  let parameters = param;

  let subWeldingType = 0;
  let subWeldingGroupType = 0;
  let weldingType = 0;
  let weldingGroupType = 0;
  let secondaryWorkProcessDetailsId = 1;
  let secondaryWorkProcessDetailsGroup = 10310;
  const weldingDetails = parameters?.process?.filter((i) => i.workType === WorkType.SmWelding);
  const secondaryWorkDetails = parameters?.process?.filter((i) => i.workType === WorkType.SmSecondaryWork);
  if (secondaryWorkDetails?.length > 0) {
    secondaryWorkProcessDetailsId = secondaryWorkDetails?.[0]?.details?.processDetailTypes?.[0]?.id;
    secondaryWorkProcessDetailsGroup = secondaryWorkDetails?.[0]?.details?.processDetailTypes?.[0]?.group;
  }
  if (weldingDetails?.length > 0) {
    let subYousetsu = weldingDetails?.[0]?.details?.processDetailTypes?.filter(
      (i) => (i.group === WorkTypeGroup.SmInstSize && i.isUsed) || (i.group === WorkTypeGroup.SmInstThick && i.isUsed)
    );
    if (subYousetsu?.length > 0) {
      subWeldingType = subYousetsu?.[0]?.id;
      subWeldingGroupType = subYousetsu?.[0]?.group;
    }

    let yousetsu = weldingDetails?.[0]?.details?.processDetailTypes?.filter(
      (i) => i.group === WorkTypeGroup.SmWelding && i.isUsed
    );
    if (yousetsu?.length > 0) {
      weldingType = yousetsu?.[0]?.id;
      weldingGroupType = yousetsu?.[0]?.group;
    }
  }

  // 表面処理
  const surfaceDetails = parameters?.process?.filter((i) => i.workType === WorkType.SmSurface);
  let surfaceProcessId = 0;
  if (surfaceDetails?.length > 0) {
    surfaceProcessId = surfaceDetails?.[0].id;
  }
  let surface = [];
  surface = processSelect?.filter((i) => i.processId === surfaceProcessId);
  let surfaceDataFromSelect = [];
  if (surface?.length > 0) {
    surfaceDataFromSelect = surface[0]?.multiprocesses?.filter(
      (i) => i.processDetailGroup != 0 && i.processDetailTypeId != 0
    );
  }
  // ***

  if (workType === WorkType.SmProgram) {
    return [{ elements: 0, elementsAuto: 0 }];
  } else if (
    workType === WorkType.SmBlankCombi ||
    workType === WorkType.SmBlankLaser ||
    workType === WorkType.SmBlankPunch
  ) {
    return [
      {
        dimensionData: {
          outerDia: toDecimal(0, 2),
          outerAutoExtract: toDecimal(0, 2),
          innerDia: toDecimal(0, 2),
          innerAutoExtract: toDecimal(0, 2),
          airFeedDistance: toDecimal(0, 2),
          airFeedAutoExtract: toDecimal(0, 2),
          etchingLength: toDecimal(0, 2),
        },
        quantityList: [
          {
            id: 1,
            types: 1,
            typesAutoExtract: '',
            diameter: toDecimal(0, 2),
            diameterAutoExtract: '',
            xsize: toDecimal(0, 2),
            xsizeAutoExtract: '',
            ysize: toDecimal(0, 2),
            ysizeAutoExtract: '',
            perimeter: toDecimal(0, 2),
            perimeterAutoExtract: '',
            count: 1,
            countAutoExtract: '',
          },
        ],
      },
    ];
  } else if (workType === WorkType.SmSecondaryWork) {
    return [
      {
        id: 1,
        types: secondaryWorkProcessDetailsId,
        typesAutoExtract: '',
        diameter: toDecimal(0, 2),
        diameterAutoExtract: '',
        xsize: toDecimal(0, 2),
        xsizeAutoExtract: '',
        ysize: toDecimal(0, 2),
        ysizeAutoExtract: '',
        perimeter: toDecimal(0, 2),
        perimeterAutoExtract: '',
        count: 1,
        countAutoExtract: '',
        blankFlag: false,
        blankFlagAutoExtract: '',
        workTypeGroup: secondaryWorkProcessDetailsGroup,
        workTypeGroupAutoExtract: '',
      },
    ];
  } else if (workType === WorkType.SmBending) {
    return [
      {
        bendingData: { maxBendLength: 0, maxBendAutoExtract: 0 },
        bendingList: [
          {
            id: 1,
            types: 1,
            typesAutoExtract: '',
            bendLength: toDecimal(0, 2),
            bendLengthAutoExtract: '',
            lines: 1,
            linesAutoExtract: '',
            bendCount: 1,
            bendCountAutoExtract: '',
          },
        ],
      },
    ];
  } else if (workType === WorkType.SmDeburringAuto) {
    return [
      {
        id: 1,
        types: '自動',
        area: toDecimal(0, 2),
        areaAutoExtract: toDecimal(0, 2),
        weight: toDecimal(0, 2),
        weightAutoExtract: toDecimal(0, 2),
        count: 1,
        countAutoExtract: '',
      },
    ];
  } else if (workType === WorkType.SmDeburringManual) {
    return [
      { id: 1, types: '手動', target: '外形', length: toDecimal(0, 2), autoExtract: 0, count: 1, countAutoExtract: '' },
      { id: 2, types: '手動', target: '内形', length: toDecimal(0, 2), autoExtract: 0, count: 1, countAutoExtract: '' },
    ];
  } else if (workType === WorkType.SmWelding) {
    return [
      {
        weldingList: [
          {
            id: 1,
            yousetsuType: weldingType,
            yousetsuTypeAutoExtract: '',
            senchou: toDecimal(0, 2),
            senchouAutoExtract: '',
            senchouKaisu: 1,
            senchouKaisuAutoExtract: '',
            senchouYousetsushiage: 0,
            senchouYousetsushiageAutoExtract: '',
            total1: toDecimal(0, 2),

            karitsukeCnt: 0,
            karitsukeCntAutoExtract: '',
            karitsukeKaisu: 1,
            karitsukeKaisuAutoExtract: '',
            karitsukeYousetsushiage: 0,
            karitsukeYousetsushiageAutoExtract: '',
            total2: 0,
          },
        ],
        subWeldingList: [
          {
            id: 1,
            subWeldingType: subWeldingType,
            subWeldingTypeAutoExtract: '',
            daimeter: subWeldingGroupType === WorkTypeGroup.SmInstThick ? toDecimal(1, 2) : toDecimal(0, 2),
            daimeterAutoExtract: '',
            number: 0,
            numberAutoExtract: '',
            count: 1,
            countAutoExtract: '',
            yousetsushiage: 0,
            total: toDecimal(0, 2),
            subWeldingGroupType: subWeldingGroupType,
            subWeldingGroupTypeAutoExtract: '',
          },
        ],
      },
    ];
  } else if (workType === WorkType.SmSurface) {
    // if (surfaceDataFromSelect?.length > 0) {
    //   let list = [];
    //   for (let i = 0; i < surfaceDataFromSelect?.length; i++) {
    //     const newSurface = {
    //       no: 1,
    //       calcType: 1,
    //       workType: 300,
    //       areaCount: 1,
    //       totalArea: toDecimal(0, 2),
    //       totalWeight: toDecimal(0, 2),
    //       weightCount: 1,
    //       areaUnitPrice: toDecimal(0, 2),
    //       areaAutoExtract: 0,
    //       weightUnitPrice: toDecimal(0, 2),
    //       weightAutoExtract: 0,
    //       processDetailTypeNo: 0,
    //       processDetailWorkTypeGroup: 0,
    //     };
    //     list.push(newSurface);
    //   }
    //   return list;
    // } else {
    //   return [];
    // }
    return [
      {
        no: 1,
        calcType: 1,
        workType: 300,
        areaCount: 1,
        areaCountAutoExtract: '',
        totalArea: toDecimal(0, 2),
        totalWeight: toDecimal(0, 2),
        weightCount: 1,
        weightCountAutoExtract: '',
        areaUnitPrice: toDecimal(0, 2),
        areaAutoExtract: 0,
        weightUnitPrice: toDecimal(0, 2),
        weightAutoExtract: 0,
        processDetailTypeNo: 0,
        processDetailWorkTypeGroup: 0,
      },
    ];
  } else if (workType === WorkType.SmShearing || workType === WorkType.SmInspection) {
    return [{ numOfCuts: 1, autoExtract: '' }];
  } else {
    return []; // Default empty details for other deviceId values
  }
};

// 値はInfinityであるかチェック
export const isInfinity = (value) => {
  return value === Number.POSITIVE_INFINITY || value === Number.NEGATIVE_INFINITY;
};
// 全角入力チェック
export const regexExp =
  /[A-ZＡ-Ｚ“”\u3002、\uFF10-\uFF19\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF\uFF21-\uFF3A\uFF41-\uFF5A\uFF01-\uFF5E]/;

// 見積もりのパラメータ情報から材料サイズマスタ情報を得る
export const getMaterialSizeData = (selectedEstimateData) => {
  let materialSizeData = [];
  if (selectedEstimateData) {
    const estimateParameters = selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    materialSizeData = estimateParameters?.materialSizeIq3;
    materialSizeData = materialSizeData
      ?.filter((item) => item.info.isUsed)
      ?.map((item) => {
        return { id: item.id, name: item.name, sizeX: item.info.sizeX, sizeY: item.info.sizeY };
      });
  }
  return materialSizeData;
};

// 材料詳細の新材料サイズの場合、初期値
export const getInitMaterialSizeInfo = (index, item) => {
  let data = {
    no: index + 1,
    materialSizeIq3Id: item.id,
    isUsed: false,
    sizeName: item.name,
    materialQuantity: 0,
    price: 0,
    yield: 0,
    pattern1Rate: 0,
    pattern1Quantity: 0,
    pattern2Rate: 0,
    pattern2Quantity: 0,
    maxYield: 0,
    maxQuantity: 0,
    yRotate: false,
  };
  return data;
};

// 材料詳細の新材料サイズの場合、材料パータンの初期値
export const getInitMaterialPatternsInfo = (materialDetailPatterns, item) => {
  new Array(2).fill(null)?.map((_, pIndex) => {
    let data = {
      patternType: pIndex === 0 ? PatternType.Pattern1 : PatternType.Pattern2,
      totalPrice: 0,
      yield: 0,
      materialQuantity: 0,
      materialSizeIq3Id: item?.id,
      sizeName: item?.name,
      sizeX: item?.info?.sizeX,
      sizeY: item?.info?.sizeY,
      ySetting: true,
      topFit: false,
      rightFit: false,
    };
    materialDetailPatterns.push(data);
  });
  return materialDetailPatterns;
};

// 材料詳細の新Free材料サイズの場合、初期値
export const getInitFreeMaterialSizeInfo = () => {
  let data = {
    no: 0,
    materialSizeIq3Id: 0,
    isUsed: false,
    sizeName: 'Free',
    materialQuantity: 0,
    price: 0,
    yield: 0,
    pattern1Rate: 0,
    pattern1Quantity: 0,
    pattern2Rate: 0,
    pattern2Quantity: 0,
    maxYield: 0,
    maxQuantity: 0,
    yRotate: false,
  };
  return data;
};

// 材料詳細の再計算モード
export const reCalMaterial = {
  allRecordCal: 1, // 板金子部品全部の材料を再計算
  oneRecordCal: 2, // 選択中の板金子部品のみ材料を再計算
  noCal: 3, // 計算しない
};

export const getProcessRecord = (classId, processTypes, mstInfo) => {
  // 選択された、種別と工程より工程マスタレコードIDを得る
  let mstRecordInfo = [...mstInfo]?.filter(
    (item) => item?.class === classId && processTypes?.findIndex((workType) => workType === item?.workType) > -1
  );
  return mstRecordInfo;
};

export const compareUserSettings = (obj1, obj2) => {
  let differences = {};

  // 言語比較
  if (obj1.languages.language !== obj2.languages.language) {
    differences.languages = obj1.languages.language;
  }

  // メッセージ比較
  Object.keys(obj1.massages).forEach((key) => {
    if (obj1.massages[key] !== obj2.massages[key]) {
      if (!differences.messages) {
        differences.messages = {};
      }
      differences.messages[key] = obj1.massages[key];
    }
  });

  //ユーザー情報比較
  Object.keys(obj1.users).forEach((key) => {
    // IQX_WEBEST-374 ユーザー設定に修正内容を保存して画面遷移を行う場合、確認メッセージが表示されている。
    if (key === 'newParentInitialInfo') {
      if (
        obj1?.users?.newParentInitialInfo?.clientId !== obj2?.users?.newParentInitialInfo?.clientId ||
        obj1?.users?.newParentInitialInfo?.coeffDeliveryDatesId !==
          obj2?.users?.newParentInitialInfo?.coeffDeliveryDatesId ||
        obj1?.users?.newParentInitialInfo?.coeffManagementsId !==
          obj2?.users?.newParentInitialInfo?.coeffManagementsId ||
        obj1?.users?.newParentInitialInfo?.coeffOrdersId !== obj2?.users?.newParentInitialInfo?.coeffOrdersId ||
        obj1?.users?.newParentInitialInfo?.coeffPurchasesId !== obj2?.users?.newParentInitialInfo?.coeffPurchasesId ||
        obj1?.users?.newParentInitialInfo?.creater !== obj2?.users?.newParentInitialInfo?.creater ||
        obj1?.users?.newParentInitialInfo?.estimateStatusId !== obj2?.users?.newParentInitialInfo?.estimateStatusId ||
        obj1?.users?.newParentInitialInfo?.modifierId !== obj2?.users?.newParentInitialInfo?.modifierId ||
        obj1?.users?.newParentInitialInfo?.parentProcessPatternId !==
          obj2?.users?.newParentInitialInfo?.parentProcessPatternId
      ) {
        if (!differences.users) {
          differences.users = {};
        }
        differences.users[key] = obj1.users[key];
      }
    } else if (key === 'newSmInitialInfo') {
      // IQX_WEBEST-374 ユーザー設定に修正内容を保存して画面遷移を行う場合、確認メッセージが表示されている。
      if (
        obj1?.users?.newSmInitialInfo?.iq3ProcessPatternId !== obj2?.users?.newSmInitialInfo?.iq3ProcessPatternId ||
        obj1?.users?.newSmInitialInfo?.materialIq3Id !== obj2?.users?.newSmInitialInfo?.materialIq3Id ||
        obj1?.users?.newSmInitialInfo?.materialSurfaceIq3Id !== obj2?.users?.newSmInitialInfo?.materialSurfaceIq3Id
      ) {
        if (!differences.users) {
          differences.users = {};
        }
        differences.users[key] = obj1.users[key];
      }
    } else {
      if (obj1.users[key] !== obj2.users[key]) {
        if (!differences.users) {
          differences.users = {};
        }
        differences.users[key] = obj1.users[key];
      }
    }
  });

  // CURT.パラ更新
  Object.keys(obj1.parametersUpdateFlgInfos).forEach((key) => {
    if (obj1.parametersUpdateFlgInfos[key] !== obj2.parametersUpdateFlgInfos[key]) {
      if (!differences.parametersUpdateFlgInfos) {
        differences.parametersUpdateFlgInfos = {};
      }
      differences.parametersUpdateFlgInfos[key] = obj1.parametersUpdateFlgInfos[key];
    }
  });

  return differences;
};

export const compareCompanySettings = (companyData) => {
  let obj1 = companyData.companySettingData;
  let obj2 = companyData.companySettingBefore;
  let excelList = companyData.updatedExcelList;
  let differences = {};

  if (obj1 === undefined || obj2 === undefined) return differences;

  // 言語比較
  if (obj1.languages) {
    Object.keys(obj1.languages).forEach((key) => {
      if (obj1.languages[key] !== obj2.languages[key]) {
        differences['languages'] = false;
      }
    });
  }

  // 出力フォーマット比較
  let beforeExcelFormats = obj2.excelFormats || [];
  if (beforeExcelFormats?.length !== excelList?.length) {
    differences['excelFormats'] = false;
  } else {
    for (let i = 0; i < beforeExcelFormats?.length; i++) {
      const beforeFormat = beforeExcelFormats[i];
      const dataFormat = excelList[i];

      loop1: for (let key in beforeFormat) {
        if (beforeFormat[key] !== dataFormat[key]) {
          differences['excelFormats'] = false;
          break loop1;
        }
      }
    }
  }

  // IQX_WEBEST-320 設定画面で保存を行った後の操作時に表示される注意ダイアログをやめたいです。
  // 帳票比較
  let beforePrintOutputs = obj2.printOutputs || [];
  if (beforePrintOutputs?.length !== obj1.printOutputs?.length) {
    differences['printOutputs'] = false;
  } else {
    for (let i = 0; i < beforePrintOutputs?.length; i++) {
      const beforePrintOutput = beforePrintOutputs[i];
      const dataPrintOutput = obj1.printOutputs?.[i];

      loop1: for (let key in beforePrintOutput) {
        if (beforePrintOutput[key] !== dataPrintOutput[key]) {
          differences['printOutputs'] = false;
          break loop1;
        }
      }
    }
  }

  //　環境比較
  if (obj1.environments) {
    Object.keys(obj1.environments).forEach((key) => {
      if (obj1.environments[key] !== obj2.environments[key]) {
        differences['environments'] = false;
      }
    });
  }

  // 初期名称比較
  // 親初期名称比較
  let beforeParentInitNames = obj2.initNames?.parentInitNameInfo || [];
  if (beforeParentInitNames?.length !== obj1.initNames?.parentInitNameInfo?.length) {
    differences['initNames'] = false;
  } else {
    for (let i = 0; i < beforeParentInitNames?.length; i++) {
      const beforeParentInitName = beforeParentInitNames[i];
      const dataParentInitName = obj1.initNames?.parentInitNameInfo?.[i];

      loop1: for (let key in beforeParentInitName) {
        if (beforeParentInitName[key] !== dataParentInitName[key]) {
          differences['initNames'] = false;
          break loop1;
        }
      }
    }
  }
  // 子部品初期名称比較
  let beforeChildInitNames = obj2.initNames?.childInitNameInfo || [];
  if (beforeChildInitNames?.length !== obj1.initNames?.childInitNameInfo?.length) {
    differences['initNames'] = false;
  } else {
    for (let i = 0; i < beforeChildInitNames?.length; i++) {
      const beforeChildInitName = beforeChildInitNames[i];
      const dataChildInitName = obj1.initNames?.childInitNameInfo?.[i];

      loop1: for (let key in beforeChildInitName) {
        if (beforeChildInitName[key] !== dataChildInitName[key]) {
          differences['initNames'] = false;
          break loop1;
        }
      }
    }
  }

  return differences;
};

// 変更した材質名称によって表示する材料サイズマスタ情報を得る
export const getDisplayMaterialSizeIq3 = (materialTypeIq3Id, mstMaterialSizeIq3Infos) => {
  let filteredMaterialSizeIq3 = [];
  if (mstMaterialSizeIq3Infos) {
    filteredMaterialSizeIq3 = mstMaterialSizeIq3Infos?.filter(
      (item) =>
        item.info.isUsed &&
        item.name !== 'Free' &&
        item?.materialTypeIq3Id?.findIndex((id) => id === materialTypeIq3Id) > -1
    );
  }
  return filteredMaterialSizeIq3;
};

// 材料の自動サイズを得る
export const getMaterialAutoSize = (iq3Info, yRotate, isXSize = true) => {
  let pSizeX = yRotate ? Number(iq3Info?.partSizeY) : Number(iq3Info?.partSizeX);
  let sizeX = pSizeX + Number(iq3Info?.pierWidthX) + Number(iq3Info?.grabbingX);
  let pSizeY = yRotate ? Number(iq3Info?.partSizeX) : Number(iq3Info?.partSizeY);
  let sizeY = pSizeY + Number(iq3Info?.pierWidthY) + Number(iq3Info?.grabbingY);
  return isXSize ? sizeX : sizeY;
};

// CURT.パラメータ更新の初期値
export const getInitParamUpdateFlgInfos = {
  isMaterialParam: false, // IQX_WEBEST-388 ユーザー設定画面のCURTパラの更新項目の初期値をチェックOFFにする。
  isProcessParam: false, // IQX_WEBEST-388 ユーザー設定画面のCURTパラの更新項目の初期値をチェックOFFにする。
  isPurchaseParam: false, // IQX_WEBEST-388 ユーザー設定画面のCURTパラの更新項目の初期値をチェックOFFにする。
  isOtherParam: false, // IQX_WEBEST-388 ユーザー設定画面のCURTパラの更新項目の初期値をチェックOFFにする。
};

// 全角英数字を半角に変換
export const toHalfWidth = (str) => {
  // 全角英数字を半角に変換
  return str.replace(/[Ａ-Ｚａ-ｚ０-９．]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
};

// 全角のチェック
export const toCheckZenkaku = (value) => {
  if (regexExp.test(value)) {
    // 全角の場合、
    return toHalfWidth(value); // 半角へ変換する
  }
  return value;
};

// 数字全角のチェック
export const isDigitZenkaku = (str) => {
  let ret = str.replace(/[０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
  return !isNaN(Number(ret));
};

// 案件状態のフォントカラー
export const getEstimateStatusFontColor = (lists, id) => {
  let item = lists?.find((item) => item.id === id);
  return item?.info?.fontColor;
};

// 案件状態の親部品リストカラー
export const getEstimateStatusRowColor = (lists, id) => {
  let item = lists?.find((item) => item.id === id);
  return item?.info?.rowColor;
};

// チャージ金額の詳細情報を得る
export const getChargesDetailValues = (id, chargesDetails) => {
  var detailValue = {};
  detailValue = Object.entries(chargesDetails)?.filter(([key, data]) => parseInt(key) === id)?.[0];
  return detailValue;
};

// 段取設定の詳細情報を得る
export const getPrepSettingDetailValues = (id, dantoriKouteiDetails) => {
  var detailValue = {};
  detailValue = Object.entries(dantoriKouteiDetails)?.filter(([key, data]) => parseInt(key) === id)?.[0];
  return detailValue;
};

// IQX_WEBEST-370 親部品‐購入品にロット数価格を使用している購入品情報を追加してもロット数価格情報が表示されていない。
export const getPriceForUpperThVal = (upperThVal, data) => {
  let listCurRowNumber = [];
  let priceInfo = data?.filter((item) => Number(item.upperTh) <= Number(upperThVal));
  if (priceInfo?.length > 0) {
    for (let i = 0; i < priceInfo?.length; i++) {
      listCurRowNumber.push(priceInfo[i].upperTh);
    }
    let maxPriceInfo = Math.max(...listCurRowNumber);
    let priceMaxInfo = priceInfo?.filter((item) => Number(item.upperTh) === Number(maxPriceInfo));
    if (priceMaxInfo?.length > 0) {
      return priceMaxInfo[0].price;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

// 親部品情報に変更があったかチェックする
export const compareParentInfo = (beforeParentTabObj, afterParentTabObj) => {
  let isDiffInfo = false;
  if (beforeParentTabObj.estimateProducts) {
    Object.keys(beforeParentTabObj.estimateProducts).forEach((key, index, obj) => {
      if (key === 'additions') {
        Object.keys(beforeParentTabObj.estimateProducts.additions).forEach((additionKey, addIndex, add) => {
          if (
            beforeParentTabObj?.estimateProducts?.additions[additionKey]?.dataType !==
            afterParentTabObj?.estimateProducts?.additions[additionKey]?.dataType
          ) {
            isDiffInfo = true;
            add.length = addIndex + 1;
            obj.length = index + 1;
          }
        });
      } else {
        if (
          JSON.stringify(beforeParentTabObj?.estimateProducts[key]) !==
          JSON.stringify(afterParentTabObj?.estimateProducts[key])
        ) {
          isDiffInfo = true;
          obj.length = index + 1;
        }
      }
    });
  }
  return isDiffInfo;
};
